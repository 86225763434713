import { gql, useApolloClient } from '@apollo/client';

export const GQL_CREA_BOLLA_E_VOCI = gql`
mutation ddtdacommessa_save($object: ghiaia3_ddtdacommessa_save_prms_insert_input! = {}) {
  record: insert_ghiaia3_ddtdacommessa_save_prms_one(object: $object) {
    cod_bolla
    num_bolla
    data
    treg
  }
}
`
/* Magari stampo
*/

export const GQL_PRINT = gql`
mutation ($bollaKey: BollePrimaryKeyInput!, $stampante: String!, $scontrino: Boolean) {
  printBolla(bollaKey: $bollaKey, stampante: $stampante, scontrino: $scontrino) {
    msg
  }
}
`

function campiCompilati(state, fldList) 
{
    return fldList.reduce(function (obj, el) {obj[el] = state[el] && state[el].recid; return obj}, {})
}
  
function dataSenzaTZ(data){
    return data == null ? null : new Date( // data ha time zone. In postgres no
        Date.UTC(
            data.getFullYear(),
            data.getMonth(),
            data.getDate(),
            data.getHours(),
            data.getMinutes(),
            data.getSeconds()))
}

function creaVociVars(state) {
  const {
    codBolla: {label: cod_bolla},
    data: {recid: data},
    stampante: {recid: stampante},
    stampare,
    stampareScontrino} = state

    return {
      object:
        {
          ...campiCompilati(state, [
            'num_bolla',
            'cod_vettore',
            'conducente',
            'cig',
            'cup',
            'id_dest',
            'targa',
            'vett_ns',
            'cod_commessa',
            'cod_artic',
            'cod_bolla',
            'commessa_trasporti_data_inizio',
            'data_inizio',
            'destinazione_pattern',
            'lordo',
            'num_bolla',
            'num_voce',
            'pesospec',
            'tara',
            'temperatura',
            'unita',
            'num_silos']),
          cod_bolla,
          cod_pers: state.ragSoc.recid,
          data: dataSenzaTZ(data),
          mezzo_vettore: state.cod_vettore.label !== 'NO vettore',
          stampante: stampare ? stampante : null,
          stamparescontrino: Boolean(stampareScontrino).toString()
        }
      }
}

async function saveDdt(client, redux) {
    const {state, dispatch} = redux
    const {
    //  stampante: {recid: stampante},
      stampare,
    //  stampareScontrino
    } = state
    let {
      //codBolla: {label: cod_bolla},
         num_bolla: {recid: num_bolla},
         data: {recid: data}} = state
    //let anno = (dataSenzaTZ(data) || new Date()).getFullYear()
    let creaResponse;
    try {
        dispatch({type: 'start_save', value: 1})
        creaResponse = await client.mutate({
            mutation: GQL_CREA_BOLLA_E_VOCI,
            variables: creaVociVars(state)})
        console.log(creaResponse);
        ({num_bolla, data} = creaResponse.data.record)
        dispatch({type: 'set_fld', fldName: 'msgSave', value: `num ${num_bolla} - ${new Date(data).toLocaleString()}`})
        dispatch({type: 'set_fld', fldName: 'savedDDT', value: creaResponse.data.record})

        // salvando ddt un trigger carica la coda di stampa
        // con un valore cod_bolla, num_bolla, anno, treg
        // Eseguendo query sulla coda si ottiene lo stato della stampa.
        // La query ripete a tempo fino a quando esito_stampa prende un valore non null
        // A quel punto abbassa waitPrintStatus e ferma la query
        // Ora vedi ddtQueryRedux.js
        if (stampare) {
            dispatch({type: 'set_fld', fldName: 'waitPrintStatus', value: true})
        }
        // segue la procedura di stampa scontrino basata su ghiaia3pkg, ora gestita in queue
        // if (stampareScontrino) {
        //     try {
        //         let {data: {printBolla: {msg: msgPrintScontrino}}} = await client.mutate({
        //             mutation: GQL_PRINT,
        //             variables: {
        //                 bollaKey: {anno, codBolla: cod_bolla, numBolla: num_bolla},
        //                 stampante: stampante,
        //                 scontrino: true
        //             }
        //         })
        //         dispatch({type: 'set_fld', fldName: 'msgPrintScontrino', value: msgPrintScontrino})
        //     } catch (err) {
        //         dispatch({type: 'set_fld', fldName: 'msgPrintScontrino', value: "Errore di stampa scontrino"})
        //         console.log("Errore di stampa scontrino", err);
        //     }
        // }
    } catch(err) {
        console.log(err)
    }
}

export default function useMutationRedux(redux) {
    let client = useApolloClient()
    return function () {saveDdt(client, redux)}
}  