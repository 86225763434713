import React, { useState } from 'react'
import {Button} from 'primereact/button'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast'
import { DiaRigaInputText } from '../WIDGETS/InputText'
import { DiaRigaDBAutocomplete } from '../WIDGETS/DBAutocomplete'
import { DialogCrudButton } from '../WIDGETS/DialogCrudButton'
import { DiaRigaCheckbox } from '../WIDGETS/Checkbox'
import { DiaRigaDBDropdown } from '../WIDGETS/DBDropdown'

import { gql } from '@apollo/client'
import { useQuery, useMutation } from '@apollo/client';


const VEICOLILISTgql = gql`
query TabellaTarghe($qry: String, $first: Int = 0, $rows: Int, $order_by: [veicoli_order_by!] = [{targa: asc}]
    ) {
    recordset: veicoli(where: {targa: {_ilike: $qry}}, offset: $first, limit: $rows, order_by: $order_by) {
      recid: targa
      targa
      tara
      mittente
      conducente: anagrafe {
        recid: cod_pers
        label: rag_soc
        rag_soc
      }
      articolo: articoli {
        recid: cod_artic
        label: descrizion
      }
    }
    recordcount: veicoli_aggregate(where: {targa: {_ilike: $qry}}) {
      aggregate {
        count
      }
    }
  }`
  
const MUT_ADD = gql`
mutation saveTarga($targa: String!, $conducente: Int!, $tara: numeric!, $mittente: Boolean!, $codArtic: Int!) {
    insert_veicoli_one(object: {targa: $targa, cod_pers: $conducente, mittente: $mittente, cod_artic: $codArtic, tara: $tara}, on_conflict: {constraint: veicoli_pkey, update_columns: [cod_artic, cod_pers, mittente, tara]}) {
      targa
    }
  }`;

const MUT_DEL = gql`
mutation delTarga($targa: String!) {
    delete_veicoli_by_pk(targa: $targa) {
      targa
    }
  }`;

const INIT_STATE = {
    conducente: {label:''},
    targa: {label:''},
    tara: {label:''},
    mittente: {label: false, recid: false},
    articolo: {label:''},
    dirty:false,
}

const setRecId = (field) => (
    field.hasOwnProperty('label') ? {label: field.label, recid: field.recid} : {label: field, recid: field}
)

function setRecordRecid(record) {
    let new_record = {}
    record && Object.keys(record).forEach(e => {new_record[e] = setRecId(record[e])})
    return new_record
}

export function CrudDialog(props){
    const [state, setState] = useState({...INIT_STATE, ...setRecordRecid(props.initrecord)})
    let growl = props.growl
    
    const datiOK = () => {
        return !!(
            state.conducente.recid 
            && state.targa.recid
            && state.tara.recid
            && state.mittente.recid != null
            && state.articolo.recid
        )
    }

    const getVariables = () => datiOK() ? ({
        conducente: state.conducente.recid, 
        targa: state.targa.recid, 
        tara: state.tara.recid,
        mittente: state.mittente.recid,
        codArtic: state.articolo.recid,
    }) : {}

    const msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
          growl().show({
            severity: 'success', summary: 'Esito',
            detail: 'Corretto',
            life: 3000
          })
        }
        if (error) {
          growl().show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + error.msg, life: 3000
          })
        }
        if (graphQLErrors) {
          growl().show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + saveresponse.message, life: 3000
          })
        }
    }
    
    const onSave = (action) => {
        let dati = getVariables()
        console.log('salva dati', dati);
        action({variables: dati}).then((response) => {
            msgSave(response)
            props.onHide()
            props.refetch()
        }).catch((response) => {
            console.log('on save catch', response);
            msgSave(response)
        })
    }

    const onDelete = (action) => {
        let dati = getVariables()
        console.log('cancella dati', dati);
        action({variables: dati}).then((response) => {
            msgSave(response)
            props.onHide()
            props.refetch()
        }).catch((response) => {
            console.log('on delete catch', response);
            msgSave(response)
        })
    }

    const setAttrib = (attr_name, value) => setState(prev => ({...prev,
        [attr_name]: value, dirty: true}));

    const actionadd = useMutation(MUT_ADD, {variables: getVariables()})[0]
    const actiondel = useMutation(MUT_DEL, {variables: {targa: state.targa.rec_id}})[0]
    return (
        <React.Fragment>
            <Dialog header={props.header}
                visible={props.visible} 
                onHide={props.onHide}
                style={{width: '70vw'}}
                contentStyle={{minHeight:'26em'}}
                modal={true}>
                <div className="p-grid form-group">
                    <DiaRigaDBAutocomplete
                        id= "fld100"
                        label="Conducente"
                        graphql={gql`
                            query ($qry: String!) {
                                recordset: anagrafe(limit: 50, where: {rag_soc: {_ilike: $qry}}) {
                                    recid: cod_pers
                                    label: rag_soc
                                }
                            }`}
                        value={state.conducente}
                        onChange={(e) => setAttrib("conducente", e)}
                        placeholder="anagrafica conducente"
                    />
                    <DiaRigaInputText 
                        id="fld7"
                        label="Targa"
                        value={state.targa}
                        validif={value => value.length > 6}
                        onChange={setAttrib.bind(this,"targa")}/>
                    <DiaRigaInputText 
                        id="fld8"
                        label="Tara"
                        type="number"
                        min="0"
                        value={state.tara}
                        validif={value => value > 0}
                        onChange={setAttrib.bind(this,"tara")}/>
                    <DiaRigaCheckbox
                        showif="true"
                        id="fld3"
                        label="Mittente"
                        value={state.mittente}
                        onChange={setAttrib.bind(this, "mittente")}/>
                    <DiaRigaDBDropdown
                        id= "fld9"
                        label="Articolo servizio"
                        graphql={gql`
                        {
                            recordset: articoli(where: {descrizion: {_ilike: "servizio c %"}}) {
                              recid: cod_artic
                              label: descrizion
                              labelext: cod_artic
                            }
                          }`}
                        value={state.articolo}
                        onChange={setAttrib.bind(this,"articolo")}
                        placeholder="articolo del servizio di consegna"
                    />
                    <DialogCrudButton
                            dirty={state.dirty}
                            newRecord={props.newRecord}
                            onSave={function(e) {onSave(actionadd)}}
                            onDelete={props.newRecord ? null : onDelete.bind(this, actiondel)}
                            onReset={e => setState({...setRecordRecid(props.initrecord), dirty: false})}
                            datiOk={datiOK()}
                    />
                </div>
            </Dialog>
        </React.Fragment>
        )
}

export function Veicoli(props) {
    let growl; // dovra finire in closure per rimanere vivo e accessibile da dialog
    const [state, setState] = useState({
        rows: 20,
        first: 0,
        qry:'%',
        multiSortMeta:[],
        selectedRecord: null,
        displayDialog: false,
        record: {}
    });
    const [newRecord, setNewRecord] = useState(false);

    const onRecordSelect = (e) => {
        let {targa, tara, conducente, mittente, articolo} = e.data
        setNewRecord(false);
        setState(prev => ({...prev,
            displayDialog: true,
            record: {targa: targa, tara: tara, conducente: conducente, mittente: mittente, articolo: articolo}
        }));
    }

    const addNew = () => {
        setNewRecord(true);
        setState(prev=> ({...prev,
            record: {targa: '', tara: '', conducente: '', mittente: false, articolo: ''},
            displayDialog: true
        }));
    }

    const onPage = (refetch, event) => {
        setState(prev=> ({...prev,
            first: event.first}));
        refetch({variables:{first: event.first}})
    }

    const renderBooleanColumn = (rowData, column) => (
        <span>{ rowData['mittente'] ? "": "NON"} di nostra proprietà</span>
    )

    const query_status = useQuery(
        VEICOLILISTgql,
        {
            variables: {
                qry: state.qry, first: state.first, rows: state.rows, 
                order_by: state.multiSortMeta.length > 0 ? [...state.multiSortMeta].map(
                    e => ({[e.field]: ({"-1":'desc', "1":'asc'})[e.order]})) : undefined 
            },
            fetchPolicy: "cache-and-network" 
        });

    const {data, loading, refetch} = query_status;
    
    let paginatorRight = <div>
        <Button label="Nuovo" icon="pi pi-plus" onClick={addNew}/>
    </div>;

    return (
            <div className="card shadow">
                <div className="card-body">
                    <h2 className='card-title'>Veicoli</h2>
                            <DataTable 
                                    lazy={true}
                                    value={data ? data.recordset: []}
                                    emptyMessage="nessun dato"
                                    loading={loading}
                                    paginator={true}
                                    rows={state.rows}
                                    first={state.first}
                                    onPage={(event) => onPage(refetch, event)}
                                    onFilter={({filters}) => setState(prev=>({...prev, first: 0, qry: (filters.targa ? filters.targa.value : '') + '%'}))}
                                    filterDisplay='row'
            
                                    sortMode="multiple"
                                    removableSort={true}
                                    onSort={(e) => {
                                        setState(prev=>({...prev, multiSortMeta: e.multiSortMeta, first: 0}))
                                    }}
                                    multiSortMeta={state.multiSortMeta}

            
                                    totalRecords={data ? data.recordcount.aggregate.count : 0}
                                    paginatorRight={paginatorRight}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "

                                    selectionMode="single"
                                    selection={state.selectedRecord}
                                    onSelectionChange={e => setState(prev=>({...prev, selectedRecord: e.value}))}
                                    onRowSelect={onRecordSelect}>

                                <Column showFilterMenu={false} showClearButton={false} field="targa" header="targa" sortable={true} filter={true} filterPlaceholder="Cerca targa"/>
                                <Column field="tara" header="tara"/>
                                <Column field="conducente.rag_soc" header="conducente" />
                                <Column field="mittente" header="del Mittente" body={renderBooleanColumn}/>
                                <Column field="articolo.label" header="articolo servizio"/>
                            </DataTable>
                            <CrudDialog 
                                key={JSON.stringify(new Date())}
                                growl={() => growl}
                                refetch={refetch}
                                initrecord={state.record}
                                newRecord={newRecord}
                                header={newRecord ? "Nuovo veicolo" : "Modifica veicolo"}
                                visible={state.displayDialog}
                                onHide={() => setState(prev=>({...prev, displayDialog:false, selectedRecord: null, record: null}))} />
                            <Toast 
                                ref={function(el) {growl = el}} 
                                />
                </div>
            </div>
        );
}


