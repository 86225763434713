import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast'
import { DiaRigaInputText } from '../WIDGETS2/InputText'
import { DiaRiga } from '../WIDGETS2/Riga'
import { DialogCrudButton } from '../WIDGETS/DialogCrudButton'

/* In caso di nuova destinazione salva destinazione e id_dest da seriale automatico
In caso di variazione deve fare upsert con il codice destinazione del record selezionato 

Nel caso edit il newRecord === false and initRecord.id_dest != null
Nel caso new record newRecord === false
*/
const ADD_RECORD = gql`
mutation ADD_DESTINAZIONE($object: destinazioni_insert_input!) {
    insert_destinazioni_one(object: $object, on_conflict: {constraint: destinazioni_pkey, update_columns: destinazione}) {
      destinazione
      id_dest
    }
  }
`

const DEL_RECORD = gql`
mutation DEL_DESTINAZIONE($id_dest: Int!) {
    delete_destinazioni_by_pk(id_dest: $id_dest) {
      destinazione
      id_dest
    }
  }
`

DialogSave.propTypes = {
    visible: PropTypes.bool.isRequired,
    newRecord: PropTypes.bool.isRequired, // flag add record
    onHide: PropTypes.func.isRequired,
    initRecord: PropTypes.object
}


const INIT_STATE = {
    id_dest: {label:'', recid: -1},
    destinazione: {label:'', recid: null},
    dirty:false
}
  
function initRecord2InitState(initRecord) {
    let {id_dest, destinazione} = initRecord;
    let ret_val = {
        id_dest: {
            label: id_dest, 
            recid: id_dest}, 
        destinazione: {
            label: destinazione, 
            recid: destinazione}, 
        dirty: false}
    return ret_val
}

export function DialogSave(props) {
    const [record, setRecord] = useState(INIT_STATE);
    useEffect(resetState, [props.initRecord, props.newRecord])
    let growl;

    function resetState() {
        let tmp = {...INIT_STATE}
        if (props.newRecord) {
            setRecord(tmp)
        }
        else if (props.initRecord) 
            props.initRecord && setRecord(initRecord2InitState(props.initRecord));
    }

    let datiOK = () => {
        return !!(
            (props.newRecord || record.id_dest.recid)
            && record.destinazione.recid
        )
    }

    function getVariables() {
        if (datiOK()) {
            if (props.newRecord) {
                return { destinazione: record.destinazione.recid }
            }
            return {
                id_dest: record.id_dest.recid, 
                destinazione: record.destinazione.recid
            }
        }
        return {}
    }

    let msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
            growl.show({
            severity: 'success', summary: 'Esito',
            detail: 'Corretto',
            life: 3000
            })
        }
        if (error) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + error.msg, life: 3000
            })
        }
        if (graphQLErrors) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + saveresponse.message, life: 3000
            })
        }
    }

    function onMutationOK(data) {
        if (data) {
            msgSave({ data: data });
            props.onHide();
            console.log('OK');
        }
    }

    function onMutationError(error) {
        console.log('Errori apollo o eccezione in onMutationOK');
        if (error) {
            msgSave({ error: error });
        }
    }

    function onSave(data, error) {
        let dati = {object: getVariables()};
        console.log('salva dati', dati);
        actionadd({ variables: dati });
    }

    const [actionadd] = useMutation(ADD_RECORD, {onCompleted: onMutationOK, onError: onMutationError, refetchQueries: ['destinazioni']})

    function onDelete(data, error) {
        let dati = getVariables();
        console.log('cancella dati', dati);
        actiondel({ variables: dati });
    }

    const [actiondel] = useMutation(DEL_RECORD, {onCompleted: onMutationOK, onError: onMutationError, refetchQueries: ['destinazioni']})

    function onHide() {
        resetState();
        props.onHide();
    }

    let setAttrib = (attr_name, value) => setRecord(
        (prev_record) => ({...prev_record, [attr_name]: value, dirty: true}))

    return (
        <>
            <Toast ref={el => growl = el} />
            <Dialog header="Destinazione"
                visible={props.visible} 
                onHide={onHide}
                modal={true}>
                <div className="row">
                    <DiaRiga id='id_dest' label="Cod destinazione">{props.newRecord ? "automatico" : record.id_dest.label}</DiaRiga>
                    <DiaRigaInputText 
                            id="fld8"
                            label="Destinazione"
                            value={record.destinazione}
                            validif={value => value.length < 201}
                            required={false}
                            onChange={setAttrib.bind(this,"destinazione")}/>
                </div>
                <DialogCrudButton
                        dirty={record.dirty}
                        newRecord={props.newRecord}
                        onSave={onSave}
                        onDelete={props.newRecord ? null : onDelete}
                        onReset={resetState}
                        datiOk={datiOK()}/>
            </Dialog>
        </>
        )
}

