import React, { useState } from 'react'
import { saveTextToFile } from '../../filedownload'
import { gql, useApolloClient } from '@apollo/client'
import { RigaInputText } from '../WIDGETS2/InputText'
import { Button } from 'primereact/button'

const GQL_GEN = gql`
query BollePerAdhoc($anno: float8, $cod_bolla: bpchar, $num_bolla_min: Int, $num_bolla_max: Int) {
  recordset: bolle_adhoc_commesse(where: {anno: {_eq: $anno}, cod_bolla: {_eq: $cod_bolla}, num_bolla: {_gte: $num_bolla_min, _lte: $num_bolla_max}}, order_by: {num_bolla: asc}) {
    riga
  }
}
`
function RECID(label, recid = null) {
  return ({ label, recid: recid || label });
}

async function creaExportFile(client, state) {
  const {
    anno: {recid: anno},
    cod_bolla: {recid: cod_bolla},
    num_bolla_min: {recid: num_bolla_min},
    num_bolla_max: {recid: num_bolla_max}} = state

  try {
    let response = await client.query(
      {
        query: GQL_GEN,
        variables: {anno, cod_bolla, num_bolla_min, num_bolla_max},
      }
    )
    let testo = response.data.recordset.map(v => v.riga).join('\n');
    if (testo) saveTextToFile(testo, 'bolle_adhoc.txt')
  } catch (err) {
    console.log(err);
  }
}

function useSaveFile() {
  let client = useApolloClient()
  return function (state) {creaExportFile(client, state)}
}

/******* Main form
 ***
 */

export function DdtExportAdHoc(props) {
  const [state, setState] = useState({
    anno: RECID(new Date().getFullYear()),
    cod_bolla: RECID(""),
    num_bolla_min: RECID(1),
    num_bolla_max: RECID("")
  })
  const creaFile = useSaveFile()
  return (
    <div className="card shadow">
        <div className="card-body">
          <h2 className='card-title'>Produzione del file di export per AdHoc Rev</h2>
          <RigaInputText     
            id="anno"
            label="Anno"
            value={state.anno}
            required={true}
            type={"number"}
            onChange={(value) => setState(prev => ({...prev, anno: value}))}/>
          <RigaInputText     
            id="cod_bolla"
            label="Codice Bolla"
            value={state.cod_bolla}
            required={true}
            onChange={(value) => setState(prev => ({...prev, cod_bolla: value}))}/>
          <RigaInputText     
            id="num_bolla_min"
            label="Numero bolla iniziale"
            value={state.num_bolla_min}
            required={true}
            type={"number"}
            onChange={(value) => setState(prev => ({...prev, num_bolla_min: value}))}/>
          <RigaInputText     
            id="num_bolla_max"
            label="Numero bolla finale"
            value={state.num_bolla_max}
            required={true}
            type={"number"}
            onChange={(value) => setState(prev => ({...prev, num_bolla_max: value}))}/>
          <Button label="Salva file di esportazione" onClick={() => creaFile(state)}/>
        </div>
    </div>
)}

