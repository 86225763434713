import {fileDownload} from '../../filedownload';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrincipalsContext from '../../PrincipalsContext';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';
import {Dropdown} from 'primereact/dropdown';

import { gql, useQuery } from '@apollo/client';

import { dataTruncDay_nTZ } from '../../dateutil';



const QUERY_GQL = gql`
query c2009Registro($args: costi2009_registrogroup_args! = {}, $order_by: [costi2009_registro_rettype_order_by!] = {}, $limit: Int, $offset: Int) {
  tabella: costi2009_registrogroup(args: $args, order_by: $order_by, limit: $limit, offset: $offset) {
    up_matricola
    up_descrizione
    cnt_matricola
    cnt_descrizione
    data
    ccosto
    cfonte
    unita
    quantita
  }
  cnt_filtrato: costi2009_registrogroup_aggregate(args: $args) {
    aggregate {
      count
    }
  }
}
`

const calendarOptions = {locale: "it", showIcon: true, showOnFocus: false};

/* Come visualizzo la data e ora */
function DataTypeTemplate(rowData, column) {
  let datatime = new Date(rowData[column.field])
  let data = Intl.DateTimeFormat(navigator.languages,{dateStyle:"short", timeStyle:"short"}).format(datatime)
  return <><span>{rowData[column.field] ? data : '---'}</span></>
}

/* Calendario filtro */

function RenderDateFilter(setFilterSet, filterset, setPaginator) {
  const opzioni = calendarOptions
  const setOggi = (field, value) => {
    setFilterSet({...filterset, [field]:{value: value}})
    setPaginator(prev => ({...prev, first: 0}))
  }

  return (<>
      <Calendar placeholder="Data Iniziale" value={filterset.data_min.value} onChange={({value}) =>{setOggi("data_min", value)}} {...opzioni} /><br />
      <Calendar placeholder="Data Finale" value={filterset.data_max.value} onChange={({value}) =>{setOggi("data_max", value)}} {...opzioni} />
      </>
  );
}



const menuitems = [
  {
    label: "Fogli Excel",
    items: [
      {
        label: "Registro",
      },
      {
        label: "Costi chilometrici"
      },
      {
        label: "Costi orari"
      }
    ]

  }
]

function TableHeader(groupBy, setGroupBy, setPaginator, variables, cntSelezione, navigate, addIsAllowed) {
  const fieldsSelectItems = [
    {label: 'Unit Prod', value: 'groupby_up_matricola'},
    {label: 'Contatore', value: 'groupby_cnt_matricola'},
    {label: 'Cod Prelievo', value: 'groupby_cfonte'},
    {label: 'Cod Costo', value: 'groupby_ccosto'},
    {label: 'Mese', value: 'groupby_mese'},
  ];

  // {"first":0,"rows":20,"dataInizio":"20230922","dataFinale":"20230923"}
  const xlsvars = {
    upMatricola: variables?.args.flt_up_matricola,
    cntMatricola: variables?.args.flt_cnt_matricola,
    dataInizio: variables?.args.flt_data_min,
    dataFinale: variables?.args.flt_data_max,
    ccosto: variables?.args.flt_ccosto,
    cfonte: variables?.args.flt_cfonte,
    unita: variables?.args.flt_unita,
    sortingBy: variables?.sortingBy,
  }


  return (
    <div className="row text-center">
      <div className="col">
        <MultiSelect filter
          fixedPlaceholder={false}
          placeholder="Seleziona qui il raggruppamento"
          value={groupBy} options={fieldsSelectItems}
          onChange={({value}) => {
            setGroupBy(value)
            setPaginator(prev => ({...prev, first: 0})) 
          }} 
        />
      </div>
      <div className="col">
        <h2 className="row">
            Registro dei contatori dei Rifornimenti
        </h2>
        {addIsAllowed && 
        <div className="row">
          <div className="col">
            <button className="btn btn-primary" onClick={() => navigate('./add')}>+ Nuova registrazione</button>
          </div>
        </div>}
      </div>
      <div className="col">
        <Dropdown model={menuitems}
          placeholder="scarica Fogli Excel"
          disabled={cntSelezione < 1}
          options={
            [
              {label: 'Registro', value: "/c2009/xls?q="},
              {label: 'Costi Chilometrici', value: "/c2009/chilometricixls?q="},
              {label: 'Costi Orari', value: "/c2009/costiorarixls?q="},
            ]
          }
          onChange={(e) => fileDownload(e.value + JSON.stringify(xlsvars))}
        />
      </div>
  </div>
  )
}


function UpMatricolaTemplate(rowData, column) {
  return <><span>{rowData.up_matricola}</span> <span style={{fontSize:'x-small'}}>{rowData.up_descrizione}</span></>
}

function CntMatricolaTemplate(rowData, column) {
  return <><span>{rowData.cnt_matricola}</span> <span style={{fontSize:'x-small'}}>{rowData.cnt_descrizione}</span></>
}

function QuantitaTemplate(rowData, column) {
  return <span className='justify-content-end d-flex'> {new Intl.NumberFormat(navigator.languages, {minimumFractionDigits: 2}).format(rowData[column.field])}</span>
}

export function C2009Registro() {
  const {allowedRoles} = useContext(PrincipalsContext)
  const rolesInAllowed = (roles_array) => roles_array.some((e) => allowedRoles.includes(e))
  const addIsAllowed = rolesInAllowed(['ddt', 'dir', 'amminist'])

  const navigate = useNavigate();
  const [ paginator, setPaginator ] = useState({ rows: 20, first: 0 })
  const [ filterset, setFilterSet ] = useState({
      data_min:{value: new Date()}, 
      data_max:{value:null},
      ccosto: {},
      cfonte: {},
      cnt_matricola: {},
      up_matricola: {},
      unita: {}
    } )
  const [ multisort, setMultisort ] = useState({sortOrder:0, sortField: "", multiSortMeta: []})
  const [ groupBy, setGroupBy ] = useState([])

  let filters_args = Object.fromEntries(Object.keys(filterset).map(k => ['flt_' + k, filterset[k].value || null]).filter(v => v ? true: false))
  if (filters_args?.flt_data_max) {
    filters_args.flt_data_max = dataTruncDay_nTZ(filters_args?.flt_data_max, true)
  }
  if (filters_args?.flt_data_min) {
    filters_args.flt_data_min = dataTruncDay_nTZ(filters_args?.flt_data_min, false)
  }
  let group_by_args = Object.fromEntries(groupBy.map(k => [k, true]))
  if (groupBy.length < 1) 
    group_by_args = {groupby_nogroupby: true}
  let order_by_args = (multisort.multiSortMeta.length === 0) ? [{data: 'asc'}] : multisort.multiSortMeta.map(k => ({[k.field]: k.order === 1 ? 'asc' : 'desc'}))

  let variables = {
    offset: paginator.first,
    limit: paginator.rows,
    args: {...filters_args, ...group_by_args},
    order_by: order_by_args
  }
  if (multisort.multiSortMeta.length) 
    variables.sortingBy = [...multisort.multiSortMeta]

  const { loading, data } = useQuery(
    QUERY_GQL,
    { variables });
  
  let tabella = data?.tabella || []
  let cntSelezione = data?.cnt_filtrato?.aggregate?.count || 0
  let admitSortable = true //groupBy.length < 1
  
  return (
    <DataTable
      tableStyle={{minHeight: "35em"}}
      header={TableHeader(groupBy, setGroupBy, setPaginator, variables, cntSelezione, navigate, addIsAllowed)}
      size="small"
      stripedRows
      showGridlines 

      value={tabella} lazy={true}
      emptyMessage="nessun dato"
      loading={loading}
      onFilter={(e) => {
        setFilterSet(e.filters)
        setPaginator({...paginator, first: 0})
      }}
      filters={filterset}
      filterDisplay='row'

      sortMode="multiple"
      removableSort={true}
      onSort={(e) => {
        setMultisort(e)
        setPaginator({...paginator, first: 0})
      }}
      multiSortMeta={multisort.multiSortMeta}

      paginator={true}
      rows={paginator.rows > 10 ? paginator.rows : 10}
      first={paginator.first}
      totalRecords={cntSelezione}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={[10, 20, 30]}
      onPage={(event) => {
        setPaginator(event)
      }}
      >
        <Column showFilterMenu={false} showClearButton={false} field="up_matricola" header="Unit Prod Matricola" filterPlaceholder="Unit Prod Matricola" filter={true} sortable={admitSortable} body={UpMatricolaTemplate}/>
        <Column showFilterMenu={false} showClearButton={false} field="cnt_matricola" header="Contatore Matricola" filterPlaceholder="Contatore Matricola" filter={true} sortable={admitSortable} body={CntMatricolaTemplate}/>
        <Column showFilterMenu={false} showClearButton={false} field="data" header="data" filterElement={RenderDateFilter(setFilterSet, filterset, setPaginator)} filter={true} sortable={admitSortable} body={DataTypeTemplate}/>
        <Column showFilterMenu={false} showClearButton={false} field="ccosto" header="Cod Centro Costo" filterPlaceholder="Cod Centro Costo" filter={true} sortable={admitSortable}/>
        <Column showFilterMenu={false} showClearButton={false} field="cfonte" header="Cod Centro Prelievo" filterPlaceholder="Cod Centro Prelievo" filter={true} sortable={admitSortable}/>
        <Column showFilterMenu={false} showClearButton={false} field="unita" header="Unita" filterPlaceholder="Unita" filter={true} sortable={admitSortable}/>
        <Column showFilterMenu={false} showClearButton={false} field="quantita" header="Quantita" sortable={admitSortable} body={QuantitaTemplate} />
    </DataTable>
  )
}
