import React, { useRef } from 'react'
import PlcInfo from './plcinfo'
import { PlcCmdRemoto, PlcStatoRemoto, PlcLivelloDiscreto } from './plc'
import {Toast} from 'primereact/toast'

import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { Query, Mutation } from '@apollo/client/react/components'

const VoidFunc = () => <div></div>
const Modal = VoidFunc


const statoPistaLavata = gql`
{
  zonastato(zona: "PistaLavata") {
    device
    stato
  }
}
`

const zonaSetCmdRemoto = gql`
mutation ($cmd: ZonaCmdInput!) {
  zonaSetCmdRemoto(cmd: $cmd) {
    esito
  }
}
`

const InAttesa = (props) =>  (
  <div className="card">
    <div className="p-12">
      <div className="task-box task-box-1">
          <div className="task-box-header">
            <i className="material-icons">announcement</i>
          </div>
          <div className="task-box-content">
            <h3>Stato del sistema</h3>
            <p>In attesa di dati</p>
          </div>
          <div className="task-box-footer">
            <span className="task-status">
              {props.loading ? "WAITING" : "ERROR"}
            </span>
          </div>
      </div>
    </div>
  </div>)

function ViewPistaLavata(props) {
  const growl = useRef(null);
  function  setStato(device, cmd, seconds) {
      let variables = {
        cmd: {
          zona: "PistaLavata",
          device: device,
          seconds: seconds || 0,
          cmd: cmd}}
      props.setCmdRemotoAction({variables: variables}).then(
          (response) => {
            growl.current.show(
              {severity: 'success', summary: 'Comando',
              detail: 'Comando eseguito', life: 3000});
        }).catch(
          (response) => {
            console.log('setCmdRemotoAction FAIL', device, response)
            growl.current.show(
              {severity: 'error', summary: 'Errore',
              detail: response.message, life: 5000});
          })
    }

  let proc = {}
  let {error} = props;
  if (props.data && props.data.zonastato)
    props.data.zonastato.forEach((v) => {proc[v.device] = v.stato});
      return (
        <>
        <Toast ref={growl} />
        <div hidden={props.data && props.data.zonastato}>
          <InAttesa {...props} />
        </div>
        <div hidden={!(props.data && props.data.zonastato)} className="p-grid dashboard">
          <PlcInfo {...proc} errore={error}/>
          <PlcCmdRemoto
            orologio={proc.Orologio}
            nome="Ciclo di lavaggio automatico"
            stato={proc.Q_CicloAttivo}
            allarme={false}
            oraFineCmd={proc.StartCicloCMDRemotoFine}
            tipoCmd={proc.StartCicloCMDRemoto}
            onSetStato={setStato.bind(this, 'StartCicloCMDRemoto')}
          >
          </PlcCmdRemoto>
          <PlcCmdRemoto
            orologio={proc.Orologio}
            nome="Pompa"
            stato={proc.I_PompaLavaggio}
            allarme={proc.I_PompaLavaggioProtezione}
            oraFineCmd={proc.PompaLavaggioCMDRemotoFine}
            tipoCmd={proc.PompaLavaggioCMDRemoto}
            onSetStato={setStato.bind(this, 'PompaLavaggioCMDRemoto')}
          >
            <PlcLivelloDiscreto nome="vasca accumulo" min={proc.I_LivelloMin} max={proc.LivelloAlto}/>
          </PlcCmdRemoto>
          <PlcCmdRemoto
            orologio={proc.Orologio}
            nome="Valvola 1"
            stato={proc.Q_ValvZona1}
            allarme={false}
            oraFineCmd={proc.Valvola1CMDRemotoFine}
            tipoCmd={proc.Valvola1CMDRemoto}
            onSetStato={setStato.bind(this, 'Valvola1CMDRemoto')}
          >
          </PlcCmdRemoto>
          <PlcCmdRemoto
            orologio={proc.Orologio}
            nome="Valvola 2"
            stato={proc.Q_ValvZona2}
            allarme={false}
            oraFineCmd={proc.Valvola2CMDRemotoFine}
            tipoCmd={proc.Valvola2CMDRemoto}
            onSetStato={setStato.bind(this, 'Valvola2CMDRemoto')}
          >
          </PlcCmdRemoto>
          <PlcStatoRemoto
            nome="Valvola Scarico 1"
            stato={proc.Q_ValvScaricoZona1}>
          </PlcStatoRemoto>
          <PlcStatoRemoto
            nome="Valvola Scarico 2"
            stato={proc.Q_ValvScaricoZona2}>
          </PlcStatoRemoto>
          <PlcStatoRemoto
            nome="Fotocellula"
            stato={proc.I_FotoBarriera}>
          </PlcStatoRemoto>
          <Modal show={(proc.Orologio < 1) || (proc.error !== undefined)}>
          </Modal>
      </div></>)
}

function  PistaLavataWithoutApolloOrigin(props) {
  return  (
    <Mutation mutation={zonaSetCmdRemoto}>
      {(action, {called, loading, data, error}) =>
        <Query query={statoPistaLavata} pollInterval={1000}>
          {(query_result) => <ViewPistaLavata setCmdRemotoAction= {action} {...query_result}/>}
        </Query>}
    </Mutation>
  )}

export const PistaLavata = withApollo(PistaLavataWithoutApolloOrigin)
