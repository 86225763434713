/******* InputText con validazione che trasmette label a recid
 ****
 */

 /*
    Riceve {recid, label}.Restituisce la coppia {recid, label}

 */

import PropTypes from 'prop-types';
import React, {useRef, useEffect} from 'react';
import { InputText } from 'primereact/inputtext';
import { Riga, DiaRiga } from './Riga'


// Restituisce l'oggetto {label, recid} da una label che può essere float con virgola o punto
export function inputTextFloatRecid(label) {
    return ({
        label: label, 
        recid: parseFloat(
                typeof(label) === "string" ? label.replace(",",".") : label
            )
    })

}


/* ritorna il valore di props.validif() se presente. Altrimenti 
 * esegue alcune validazioni secondo indicazioni in props
 * required è sempre vero se non dichiarato esplicitamente falso
*/
function validate(props) {
    const {value, required, validif, type, validOnZero, validOnVoidString} = props

    if (validif && typeof(value.label) === "string") 
        return validif(value.label)

    if (required === false)
        return true

    if (value.label == null)    
        return false
    
    if (validOnVoidString) return (
        typeof(value.label) === "string"
    )

    if (validOnZero) return (
        !isNaN(inputTextFloatRecid(value.label).recid)
    )

    if (type === "number") 
        return inputTextFloatRecid(value.label).recid

    return !!value.label
}

function localOnChange(props, e) {
    let {value: label} = e.target
    if (validate({...props, value: {label}})) {
        props.onChange({
            recid: props.type === "number" ? inputTextFloatRecid(label).recid : label,
            label: label})
    } else {
        props.onChange({label: label, recid: null})
    }

}


// vedi validate() per le regole di validazione
export function WidgetInputText(props) {
    let locprops = {...props}
    delete locprops.validif // non passa ai child un ident. comune a molte lib
    delete locprops.validOnVoidString
    delete locprops.validOnZero
    const inputRef = useRef(null);

    const handleWheel = (e) => e.stopPropagation()
    useEffect(() => {
        const inputElem = inputRef.current;
        inputElem?.addEventListener('wheel', handleWheel, {passive:false} );

        return () => inputElem?.removeEventListener('wheel', handleWheel);
    }, []);

    return (
    <InputText 
        ref={inputRef}
        className={validate(props) ? "" : "p-error"}
        {...locprops}
        onChange={e => localOnChange(props, e)}
        value={props.value.hasOwnProperty("label") ? props.value.label : props.value}
        />)
}

WidgetInputText.propTypes = {
    value: PropTypes.shape({label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), recid: PropTypes.any}).isRequired,
    onChange: PropTypes.func.isRequired,
    validOnZero: PropTypes.bool,
    validOnVoidString: PropTypes.bool,
    validif: PropTypes.func
}


export const DiaRigaInputText = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <WidgetInputText  {...props} />
    </DiaRiga>)

export const RigaInputText = (props) => (
    <Riga id={props.id} label={props.label} showif={props.showif}>
        <WidgetInputText  {...props} />
    </Riga>)

