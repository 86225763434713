/******* InputText con validazione che trasmette label a recid
 ****
 */

 /*
    Riceve un valore scalare.Restituisce la coppia {recid, label}
    Riceve l'object {label}. Restituisce {recid, label}

 */

import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Riga, DiaRiga } from './Riga'

class MyInputText extends Component {
    onChange = (e) => {
        let {value} = e.target
        if (this.props.validif && !this.props.validif(value)) {
            this.props.onChange({recid: '', label: value})
        } else {
            this.props.onChange({recid: value, label: value})
        }
    }

    render() {
        let props = {...this.props}
        delete props.validif // non passa ai child un ident. comune a molte lib
        return (
        <InputText 
            className={this.props.validif && !this.props.value.recid ? "p-error": ""}
            {...props}
            onChange={this.onChange}
            value={this.props.value.hasOwnProperty("label") ? this.props.value.label : this.props.value}
            />)

    }
}

export const DiaRigaInputText = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <MyInputText  {...props} />
    </DiaRiga>)

export const RigaInputText = (props) => (
    <Riga id={props.id} label={props.label} showif={props.showif}>
        <MyInputText  {...props} />
    </Riga>)
