import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { DiaRiga } from '../WIDGETS/Riga'
import { DiaRigaDBAutocomplete } from '../WIDGETS/DBAutocomplete'
import { DiaRigaDropdown } from '../WIDGETS/Dropdown'
import { DiaRigaInputText } from '../WIDGETS/InputText'
import { DiaRigaInputData } from '../WIDGETS2/InputData'
import { Toast } from 'primereact/toast'

import { gql } from '@apollo/client'
import { Query, Mutation } from '@apollo/client/react/components'


const QRY_EROGATORE = gql`{
    lista: erogatoreXFonte {
        controllo
        idPompa
        cntMatricola
        cfonte
    }
}
`

const MUT_ADD = gql`mutation (
    $controllo: String!, 
    $idPompa: Int!, 
    $ccosto: String!, 
    $upMatricola: String!, 
    $cntMatricolaContatoreAssociato: String!, 
    $oraStart: Int!, 
    $oraStop: Int!, 
    $dataInizio: String!, 
    $dataFine: String!, 
    $unitaMax: Int!) {
    erogAddAbilitazione(
        controllo: $controllo, 
        idPompa: $idPompa, 
        ccosto: $ccosto, 
        upMatricola: $upMatricola, 
        cntMatricolaContatoreAssociato: $cntMatricolaContatoreAssociato, 
        oraStart: $oraStart, 
        oraStop: $oraStop, 
        dataInizio: $dataInizio, 
        dataFine: $dataFine, 
        unitaMax: $unitaMax) {
        codAbilitazione
    }
}
`

const INIT_STATE = {
    erogatore: {},
    ccosto: '',
    up_matricola: '',
    cnt_matricola_contatore_associato: '',
    ora_start: {label: '8', recid: '8'},
    ora_stop: {label: '18', recid: '18'},
    data_inizio: {label:'', recid:''},
    data_fine: {label:'', recid:''},
    unita_max: {label:'0', recid:''},
}

export function AddAbilitazioneDialog(props) {
    const [state, setState] = useState({...INIT_STATE})
    let growl;
    const resetState = () => {
        setState({...INIT_STATE})
    }

    const datiOK = () => {
        let s = state;
        return (
            s.erogatore.recid 
            && s.erogatore.recid.controllo
            && s.ccosto.recid
            && s.ora_start.recid
            && s.ora_stop.recid
            && (1 * s.ora_start.recid < s.ora_stop.recid)
            && s.data_inizio.recid
            && s.data_fine.recid
            && (new Date(s.data_inizio.recid) <= new Date(s.data_fine.recid))
            && s.unita_max.recid > 0
        )
    }

    const getVariables = () => datiOK() ? ({
        controllo: state.erogatore.recid.controllo, 
        idPompa: state.erogatore.recid.idPompa, 
        ccosto: state.ccosto.recid, 
        upMatricola: state.up_matricola.recid, 
        cntMatricolaContatoreAssociato: state.cnt_matricola_contatore_associato.recid, 
        oraStart: state.ora_start.recid, 
        oraStop: state.ora_stop.recid, 
        dataInizio: state.data_inizio.recid, 
        dataFine: state.data_fine.recid, 
        unitaMax: state.unita_max.recid
    }) : {}

    const msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
            growl.show({
                severity: 'success', summary: 'Nuova abilitazione',
                detail: 'Abilitazione ' + data.erogAddAbilitazione.codAbilitazione,
                life: 3000
            })
        }
        if (error) {
            growl.show({
                severity: 'error', summary: 'Nuova abilitazione',
                detail: 'Comando fallito ' + error.msg, life: 3000
            })
        }
        if (graphQLErrors) {
            growl.show({
                severity: 'error', summary: 'Nuova abilitazione',
                detail: 'Comando fallito ' + saveresponse.message, life: 3000
            })
        }
    }
    
    const onSave = (action, _) => {
        let dati = getVariables()
        action({variables: dati}).then((response) => {
            msgSave(response)
            resetState()
            props.refetch()
            props.onHide()
        }).catch((response) => {
            msgSave(response)
        })
    }

    console.table(state)
    return (
        <React.Fragment>
            <Toast ref={(el) => growl = el} />
            <Dialog header="Crea nuova abilitazione"
                visible={props.visible} 
                onHide={props.onHide}
                style={{width: '30vw'}}
                contentStyle={{minHeight:'20em'}}
                modal={true}>
                <div className="p-grid form-group">
                <Query query={QRY_EROGATORE}>
                        {({data, loading}) => (
                            <DiaRigaDropdown
                                label="Erogatore"
                                id="selecterogatore"
                                value={state.erogatore}
                                options={
                                    (data && data.lista) ? 
                                        data.lista.map((v,i) => (
                                            {label: `${v.cfonte} n. ${v.idPompa}`, recid: v}))
                                        : [{label: 'attendi', recid: ''}]}
                                onChange={(e) => setState({...state, erogatore: e.value})}
                                placeholder="seleziona pompa"
                                />
                        )}
                    </Query>
                    <DiaRigaDropdown 
                            id="inputccosto"
                            label="Cod. centro di costo"
                            value={state.ccosto}
                            options={[
                                    {label: "MM Mont. Maggiore ", recid: "MM"},
                                    {label: "MV Marano Vic. ", recid: "MV"},
                                    {label: "MB Bitumato Maggiore ", recid: "MB"},
                                    {label: "TR Trasporti ", recid: "TR"},
                                    {label: "VE Vestananova ", recid: "VE"},
                                    {label: "M Preara ", recid: "M"}]}
                            onChange={({value}) => setState({...state, ccosto: value})}
                            placeholder="seleziona codice di costo"
                            />

                    <DiaRigaDBAutocomplete
                        id= "fld100"
                        label="Unita produttiva"
                        graphql={gql`
                            query ($qry: String!) {
                                recordset: unitProdList (upMatricola: $qry) {
                                    recid: upMatricola
                                    label: upMatricola
                                    labelext: upDescrizione
                                    contatori {
                                        label: cntMatricola
                                        recid: cntMatricola
                                        labelext: cntDescrizione
                                    }
                                }
                            }`}
                        value={state.up_matricola}
                        onChange={(e) => setState({...state, up_matricola: e})}
                        placeholder="matricola unità produttiva"
                    />
                    <DiaRigaDropdown
                        id= "fld101"
                        label="Contatore associato"
                        value={state.cnt_matricola_contatore_associato}
                        options={state.up_matricola.contatori ? state.up_matricola.contatori.filter((v) => v.recid !== "gasolio").sort((a, b) => a.label === b.label ? 0 : a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1) : []}
                        onChange={(e) => setState({...state, cnt_matricola_contatore_associato: e.value})}
                        placeholder="contatore associato (ore, chilometri etc)"
                    />
                    <DiaRigaInputText 
                        id="inputorastart"
                        label="Ora di avvio"
                        type="number"
                        min="0"
                        max="23"
                        value={state.ora_start}
                        validif={value => value >= 0 && value < 24}
                        onChange={(e) => setState({...state, ora_start: e})}/>
                    <DiaRigaInputText
                        id="inputorastop"
                        label="Ora di fine"
                        type="number"
                        min="1"
                        max="24"
                        value={state.ora_stop}
                        validif={value => value > 0 && value <= 24}
                        onChange={e => setState({...state, ora_stop: e})}/>
                    <DiaRigaInputData
                        id="fld5"
                        label="Data di inizio validita"
                        value={state.data_inizio}
                        onChange={(e) => setState({...state, data_inizio: e})}/>
                    <DiaRigaInputData
                        id="fld6"
                        label="Data di fine validita"
                        value={state.data_fine}
                        onChange={(e) => setState({...state, data_fine: e})}/>
                    <DiaRigaInputText 
                        id="fld7"
                        label="Max quantità erogabile"
                        type="number"
                        min="0"
                        value={state.unita_max}
                        validif={value => value > 0}
                        onChange={(e) => setState({...state, unita_max: e})}/>
                    <Mutation mutation={MUT_ADD} >
                        {(action, {called, loading, data, error}) => !loading ? (
                        <DiaRiga id="btn01" label="">
                            <Button
                                label={datiOK() ? "Salva" : "annulla"}
                                icon={datiOK() ? "pi pi-md-check" : "pi pi-md-cancel"}
                                className={datiOK() ? "p-button-success": "p-button-warning"}
                                onClick={datiOK() ? onSave.bind(this, action): resetState} />
                        </DiaRiga>) : null}
                    </Mutation>
                </div>
            </Dialog>
        </React.Fragment>
    )
    
}