export const AUTH_AUTHTOKEN_KEY = "auth_authtoken";
export const AUTH_REFRESH_KEY = "auth_refresh";
const AUTH_IDTOKEN_SUB = "AUTH_IDTOKEN_SUB";
export const AUTH_IDTOKEN_USER_ID = "AUTH_IDTOKEN_USER_ID";
export const AUTH_IDTOKEN = "AUTH_IDTOKEN"

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function send_GOOGLE_READY() {
  window.dispatchEvent(new Event('GOOGLE_READY'))
};

function send_NOLOGIN() {
  window.dispatchEvent(new Event('AUTH_NOLOGIN'))
};

// riceve il response della identificazione Google Sign-in
function send_IDTOKEN(response) {
  let idtoken = response.credential
  sessionStorage.setItem(AUTH_IDTOKEN, idtoken)
  window.dispatchEvent(
    new CustomEvent('AUTH_IDTOKEN', {
      detail: {idtoken}
    }))
  authFromIdtoken(idtoken)
};

function send_AUTHTOKEN(auth_data) {
  let auth_token = auth_data.auth;
  window.dispatchEvent(
    new CustomEvent('AUTH_AUTHTOKEN', {
      detail: {auth_token}
    }))
}

export function setNoLogin() {
  sessionStorage.removeItem(AUTH_AUTHTOKEN_KEY)
  sessionStorage.removeItem(AUTH_REFRESH_KEY)
  sessionStorage.removeItem(AUTH_IDTOKEN_SUB)
  sessionStorage.removeItem(AUTH_IDTOKEN)
  sessionStorage.removeItem(AUTH_IDTOKEN_USER_ID)
  if (window.auth_time_out_id) {
    clearTimeout(window.auth_time_out_id)
    window.auth_time_out_id = null
  }
  send_NOLOGIN()
}

// avvia il fetch di /glogin e registra auth_token e refresh_token
  // on success segnala AUTH_AUTHTOKEN e avvia timer refresh
  // on error AUTH_NOLOGIN e azzera window.idtoken etc
  // 

async function exec_request(idtoken) {
  // Se idtoken è null tenta il refresh. Altrimenti /gauth
  let auth_data = null;
  if (idtoken !== null) {
    let idtoken_payload = parseJwt(idtoken);
    console.log('idtoken', idtoken_payload);
    sessionStorage.setItem(AUTH_IDTOKEN_SUB, idtoken_payload.sub);
  }
  const bearer = 'Bearer ' + (idtoken ? 
    idtoken : sessionStorage.getItem(AUTH_REFRESH_KEY))
  try {
    const response = await fetch(
      idtoken ? '/gauth' : '/refresh',
      {
        headers:{
          'authorization': bearer
        },
        credential: 'same-origin'
      });
    if (response.ok) {
      try {
        auth_data = await response.json();
        let auth_data_payload = parseJwt(auth_data.auth);
        console.log('auth hasura payload', auth_data_payload);
        sessionStorage.setItem(AUTH_AUTHTOKEN_KEY, auth_data.auth)
        sessionStorage.setItem(AUTH_REFRESH_KEY, auth_data.refresh)
        sessionStorage.setItem(AUTH_IDTOKEN_USER_ID, auth_data_payload.userid)
        send_AUTHTOKEN(auth_data)
      } catch (error) {
        console.log(error)
        setNoLogin()      }
    } else {
      console.log(response.statusText)
      setNoLogin()
    }
  } catch (error){
    console.log('network error', error)
    setNoLogin()
  }
  if (sessionStorage.getItem(AUTH_REFRESH_KEY)) {
    window.auth_time_out_id = setTimeout(refreshGhiaiaAuth, 1200000);
  }
  return auth_data
}

async function authFromIdtoken(idtoken) {
  return await exec_request(idtoken)
};


async function refreshGhiaiaAuth() {
  return await exec_request(null)
};

function startGapi() {
  const script_element_id = 'mv-gapi-load'

  function loadGapi(cb) {
    console.log('loading gapi');
    const element = document.getElementsByTagName('script')[0]
    const fjs = element
    let gapi_jscript = element
    gapi_jscript = document.createElement('script')
    gapi_jscript.id = script_element_id
    gapi_jscript.src = 'https://accounts.google.com/gsi/client'
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(gapi_jscript, fjs)
    } else {
      document.head.appendChild(gapi_jscript)
    }
    gapi_jscript.onload = cb
  }

  if (!document.getElementById(script_element_id))
    loadGapi(() => {
        console.log('google loaded');
          window.dispatchEvent(new Event('google-loaded'))
        }
      )
}

function init_google() {
  window.idtoken = null;
  window.google.accounts.id.initialize({
    client_id: "220366930667-3coo9odlg3pmv1h3fm4hfthhodec7tgt.apps.googleusercontent.com",
    callback: (response) => send_IDTOKEN(response)
  });
  console.log('google button inizializzato');
  send_GOOGLE_READY();
}

export function startGoogleSignin (){
  window.addEventListener(
    'google-loaded',
    init_google,
    {once: true});
  startGapi();
}

export function renderGoogleButton(div_id) {
  console.log('render google button');
  let element = document.getElementById(div_id)
  window.google.accounts.id.renderButton(
    element,
    { theme: "outline", size: "large"});
  if (sessionStorage.getItem(AUTH_REFRESH_KEY)) {
    refreshGhiaiaAuth();
  }
};

export function logoutGoogle() {
  window.google.accounts.id.revoke(
    sessionStorage.getItem(AUTH_IDTOKEN_SUB),
    done => console.log('logout google', done)
  )
  setNoLogin()
}