import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast'

import { DiaRiga } from '../WIDGETS2/Riga'
import { DiaRigaInputText, inputTextFloatRecid } from '../WIDGETS2/InputText'
import { DiaRigaInputData, inputDataRecid } from '../WIDGETS2/InputData'
import { DialogCrudButton } from '../WIDGETS2/DialogCrudButton'

const ADD_RECORD = gql`
mutation ADD_COMMESSA_TRASPORTI($cod_commessa: String, $costo_unit: numeric, $data_fine: timestamp, $cod_pers: Int, $data_inizio: timestamp, $descrizion: String) {
    insert_commesse_trasporti_one(object: {cod_commessa: $cod_commessa, costo_unit: $costo_unit, data_fine: $data_fine, data_inizio: $data_inizio, cod_pers: $cod_pers, destinazione_pattern: $descrizion}, on_conflict: {constraint: commesse_trasporti_pkey, update_columns: [data_fine, costo_unit]}) {
      cod_pers
      cod_commessa
    }
  }
`
 
const DEL_RECORD = gql`
mutation DEL_COMMESSA_TRASPORTI($cod_commessa: String!, $cod_pers: Int!, $data_inizio: timestamp!, $descrizion: String!) {
    delete_commesse_trasporti_by_pk(destinazione_pattern: $descrizion, cod_commessa: $cod_commessa, cod_pers: $cod_pers, data_inizio: $data_inizio) {
      destinazione_pattern
      cod_commessa
      data_inizio
      cod_pers
    }
  }
`

DialogSaveCommessaTrasporto.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    newRecord: PropTypes.bool.isRequired, // flag add record
    presetForm: PropTypes.bool,
    initRecord: PropTypes.object
}


const INIT_STATE = () => ({
    descrizion: {label: '', recid: null},
    data_inizio: {label: '_', recid: null},
    data_fine: {label: '_', recid: null},
    costo_unit: {label: "", recid: null},
    dirty:false,
  })


// impone ai dati provenienti da registro (rowselected) la "forma normale"
function initRecord2InitState(initRecord) {
    let {descrizion, cod_commessa, costo_unit, data_fine, data_inizio, cod_pers, rag_soc} = initRecord;
    let ret_val = {
        cod_pers,
        rag_soc,
        cod_commessa,
        descrizion: {label: descrizion, recid: descrizion},
        data_inizio: inputDataRecid(data_inizio),
        data_fine: inputDataRecid(data_fine),
        costo_unit: inputTextFloatRecid(costo_unit),
        dirty: false}
    return ret_val
}

export function DialogSaveCommessaTrasporto(props) {
    const [record, setRecord] = useState(INIT_STATE());
    let growl;
    useEffect(resetState, [props.presetForm, props.initRecord, props.newRecord])

    function resetState() {
        let tmp = INIT_STATE();
        if (props.newRecord && !props.presetForm) {
            tmp.cod_pers = props.initRecord.cod_pers;
            tmp.rag_soc = props.initRecord.rag_soc;
            tmp.cod_commessa = props.initRecord.cod_commessa;
            setRecord(tmp)
        }
        else if (props.newRecord && props.presetForm) {
            tmp = initRecord2InitState(props.initRecord)
            tmp.descrizion = {label: '', recid: null}
            tmp.costo_unit = inputTextFloatRecid('')
            setRecord(tmp);
        }
        else if (props.initRecord && props.initRecord.data_inizio) {
            tmp = {...props.initRecord}
            setRecord(initRecord2InitState(tmp));
        }
    }

    let datiOK = () => {
        return !!(
            record.descrizion.recid
            && record.cod_commessa
            && record.data_fine.recid
            && record.data_fine.recid
            && parseFloat(record.costo_unit.recid) >= 0
            && record.cod_pers
        )
    }

    let getVariables = () => datiOK() ? ({
        cod_pers: record.cod_pers, 
        cod_commessa: record.cod_commessa,
        data_inizio: record.data_inizio.recid,
        descrizion: record.descrizion.recid,
        data_fine: record.data_fine.recid,
        costo_unit: record.costo_unit.recid
    }) : {}

    let msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
            growl.show({
            severity: 'success', summary: 'Esito',
            detail: 'Corretto',
            life: 3000
            })
        }
        if (error) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito per errore dati:' + error, life: 6000
            })
        }
        if (graphQLErrors) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + saveresponse.message, life: 3000
            })
        }
    }

    let onMutationOK = (data) => {
        if (data) {
        msgSave({data: data})
        props.onHide()
        props.refetch()
        console.log('OK salvato')
        }
    }

    let onMutationError = (error) => {
        console.log('Errori apollo o eccezione in onMutationOK', error);
        if (error) {
        msgSave({error: error})
        }
    }

    let onSave = (data, error) => {
        let dati = getVariables()
        console.log('salva dati', dati);
        actionadd({variables: dati})
    }

    const actionadd = useMutation(ADD_RECORD, {onCompleted: onMutationOK, onError: onMutationError})[0]

    let onDelete = (data, error) => {
        let {costo_unit, data_fine, ...dati} = getVariables()
        console.log('cancella dati', dati);
        actiondel({variables: dati})
    }

    const actiondel = useMutation(DEL_RECORD, {onCompleted: onMutationOK, onError: onMutationError})[0]

    let onHide = () => {
        resetState()
        props.onHide()
    }

    let setAttrib = (attr_name, value) => setRecord(
        (prev_record) => ({...prev_record, [attr_name]: value, dirty: true}))
    return (
        <>
            <Toast ref={el => growl = el} />
            <Dialog header="Voce trasporto di commessa"
                visible={props.visible} 
                onHide={onHide}
                style={{width: '70vw'}}
                contentStyle={{minHeight:'30em'}}
                modal={true}>
                    <DiaRiga id='rag_soc' label="Cliente">{record.rag_soc}</DiaRiga>
                    <DiaRiga id='cod_commessa' label="Cod Commessa">{record.cod_commessa}</DiaRiga>
                    {props.newRecord 
                        ? <DiaRigaInputText
                            id= "fld100"
                            label="Destinazione"
                            value={record.descrizion}
                            required
                            onChange={setAttrib.bind(this,"descrizion")}
                            placeholder="destinazione"/>
                        : <DiaRiga id='descrizion' label="Destinazione">{record.descrizion.label}</DiaRiga>}
                    {props.newRecord 
                        ?  <DiaRigaInputData 
                            label="Data inizio"
                            value={record.data_inizio}
                            onChange={setAttrib.bind(this, "data_inizio")} />
                        : <DiaRiga id="fld101datainizio" label="Data inizio">{record.data_inizio.label}</DiaRiga>}
                    <DiaRigaInputData 
                            label="Data fine"
                            value={record.data_fine}
                            onChange={setAttrib.bind(this, "data_fine")} />
                    <DiaRigaInputText
                            label="Costo unitario"
                            value={record.costo_unit}
                            required
                            type="number"
                            onChange={v => setAttrib("costo_unit", inputTextFloatRecid(v.label))}
                            validif={v => (parseFloat(v) >= 0)}
                            /> 
                    <DialogCrudButton
                            dirty={record.dirty}
                            newRecord={props.newRecord}
                            onSave={onSave}
                            onDelete={props.newRecord ? null : onDelete}
                            onReset={resetState}
                            datiOk={datiOK()}/>
            </Dialog>
        </>
        )
}
