//****** Riga per form in dialog e in component
//***
//*

import React from 'react';

export const DiaRiga = (props) => (
  (props.showif === undefined) || (props.showif) ? (
    <div className="row mt-3 mb-3">
      <h5 className="d-flex align-items-end col">
        <span className='' htmlFor={props.id}>{props.label}</span>
      </h5>
      <div className='col'>
        {props.children}
      </div>
    </div>) : null
)

export const Riga = (props) => (
  (props.showif === undefined) || (props.showif) ? (
    <div className="row" style={{marginBottom:'20px', marginTop:'20px'}}>
      <h5 className="d-flex align-items-end col-xxl-1 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4">
        <span className='' htmlFor={props.id}>{props.label}</span>
      </h5>
      <div className='col'>
        {props.children}
      </div>
    </div>) : null
)
  
  