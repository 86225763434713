/******* CheckBox con validazione che trasmette label a recid
 ****
 */

 /*
    Riceve un valore scalare.Restituisce la coppia {recid, label}
    Riceve l'object {label}. Restituisce {recid, label}

 */

import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Riga, DiaRiga } from './Riga'

class MyCheckbox extends Component {
    onChange = (e) => {
        let value = e.checked
        if (this.props.validif && !this.props.validif(value)) {
            this.props.onChange({recid: '', label: value})
        } else {
            this.props.onChange({recid: value, label: value})
        }
    }

    render() {
        let props = {
            ...this.props, 
            showif: this.props.showif.toString()}
        delete props.validif // non passa ai child un ident. comune a molte lib
        return (
        <Checkbox
            className={this.props.validif && !this.props.value.recid ? "p-error": ""}
            {...props}
            onChange={this.onChange}
            value={true}
            checked={this.props.value.hasOwnProperty("label") ? this.props.value.label : this.props.value}
            />)

    }
}

export const DiaRigaCheckbox = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <MyCheckbox  {...props} />
    </DiaRiga>)

export const RigaCheckbox = (props) => (
    <Riga id={props.id} label={props.label} showif={props.showif}>
        <MyCheckbox  {...props} />
    </Riga>)
