import React from 'react';
import { Outlet } from "react-router-dom";
import { ExeDialog } from './ExeDialog';
import { UpMatricola, CntMatricola } from './AddVoceRegUpCnt';
import { Ccosto, Cfonte } from './AddVoceCentri';
import { Data } from './AddVoceData';
import { Quantita } from './AddVoceQuantita';
import { AddVoceSave} from './AddVoceSave'; 
/*
Seleziono il contatore, poi il cconto e il cfonte, infine data ora e quantita
assistendo l'utente alla scelta corretta 
*/


export const route = 
    {
        path:'/c2009registro/add',
        element: <C2009RegistroAdd/>,
        children: [
            {
                path: '',
                element: <ExeDialog reactComponentList={[UpMatricola, CntMatricola, Ccosto, Cfonte, Data, Quantita, AddVoceSave ]} />,
            },
        ]
    };
    
function C2009RegistroAdd() {
    return (
    <div className="card shadow">
        <Outlet />
    </div>
    )
}
