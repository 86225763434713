import React from 'react';

function ArtNewRel() {
    return (
        <div className="card shadow">
            <img className='card-img-top' src="assets/layout/images/dashboard/user.jpg" alt="nuova versione 5.0" />
            <div className="card-body">
                <h3 className='card-title'>Nuova versione della applicazione</h3>
                <span className="badge bg-danger">article</span>
                <p>
                    In vista di nuove prestazione ho aggiornato tutti i componenti ad una nuova versione
                    più facile da mantenere e potenziare. 
                </p>
                <p>
                    Prego segnalare marco@ghiaia.net eventuali anomalie o suggerimenti
                </p>
            </div>
        </div>
    )
}

function ArtCovid() {
    return (
        <div className="card  shadow">
            <img className='card-img-top' src="assets/layout/images/dashboard/regoleantivirus.jpg" alt="covid rules" />
            <div className="card-body">
                <h3 className='card-title'>Sicurezza intrinseca nella struttura aziendale ma i rischi residui richiedono comportamenti specifici</h3>
                <span className="badge bg-danger">article</span>
                <p>
                    La nuova situazione epidemiologica vede una minore gravità del contagio ma anche
                    una elevata frequenza di casi che possono portare ad un aumento di varianti
                    e dunque ad un possibile ritorno a condizioni di emergenza.<br/>
                    Rimane dunque necessario adottare alcuni comportamenti che in definitiva
                    comportano anche una riduzione dell'impatto delle influenze "classiche".
                </p>
                <p>
                    Si raccomanda a tutti la massima attenzione e il rispetto delle disposizioni 
                    emesse dagli enti sanitari e dall'azienda.
                </p>
                <p>
                    Ecco comunque un elenco sintetico delle disposizioni più comuni
                </p>
                <ol>
                    <li>
                        Lavati spesso le mani con acqua e sapone o usa un gel a base alcolica. Pulisci le superfici con disinfettanti a base di cloro o alcol
                    </li>
                    <li>
                        Non toccarti occhi, naso e bocca con le mani.
                    </li>
                    <li>
                        Evita contatti ravvicinati mantenendo la distanza di almeno un metro.
                    </li>
                    <li>
                        Evita le strette di mano e gli abbracci fino a quando questa emergenza sarà finita.
                    </li>
                    <li>
                        Copri bocca e naso con fazzoletti monouso quando starnutisci o tossisci. Altrimenti usa la piega del gomito.
                    </li>
                    <li>
                        Evita luoghi affollati.
                    </li>
                    <li>
                        Se hai sintomi simili all’influenza resta a casa, non recarti al pronto soccorso o presso gli Studi medici, ma contatta Il medico di medicina generale, i pediatri di libera scelta, la guardia medica o i numeri regionali.
                    </li>
                </ol>
            </div>
        </div>
    )
}

export function Dashboard() {

    return ( 
        <div className="row row-cols-2 g-4">
            <div className="col">
                <ArtNewRel />
            </div>
            <div className="col">
                <ArtCovid />
            </div>
        </div>)
}
