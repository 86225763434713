import PropTypes from 'prop-types';
import React from 'react'
import { Button } from 'primereact/button'


// Prende boolean: datiOk (i dati sono validi salvabili), dirty (l'utente ha modificato), newRecord (operazione di creazione nuovo record)
// Tre pulsanti
// Save: sempre presente. Attivo se dati OK
// Ripristina: presente se !newRecord. Attivo se dati originari modificati
// elimina: presente se !newRecord e onDelete

// Prende i callback:
//  onSave, onReset, onDelete


export default function DialogCrudButton(props) {
    let btn_delete = !props.newRecord && !!props.onDelete
    let btn_reset = !props.newRecord
    let num_button = 1 + (btn_delete ? 1: 0) + (btn_reset ? 1: 0)
    let colw = ["col-12", "col-xl-6 col-12", "col-xl-4 col-12"][num_button -1]
    return (
            <div className="row mt-3">
                <div className={colw}>
                    <Button
                        style={{width:"100%"}}
                        label="Salva"
                        icon={props.datiOk ? "pi pi-check" : "pi pi-md-cancel"}
                        disabled={!props.datiOk}
                        className={props.datiOk ? "p-button-success": "p-button-disabled"}
                        onClick={props.onSave}
                    />
                </div>
                {btn_reset ? (
                <div className={colw}>
                    <Button
                        style={{width:"100%"}}
                        label="Ripristina"
                        disabled={!props.dirty}
                        icon="pi pi-history"
                        className={props.dirty ? "p-button-danger": "p-button-disabled"}
                        onClick={props.onReset}
                    />
                </div>) : null}
                {btn_delete ? (
                <div className={colw}>
                    <Button
                        style={{width:"100%"}}
                        label="Elimina"
                        icon="pi pi-times"
                        className="p-button-warning"
                        onClick={props.onDelete}
                    />
                </div>) : null}
        </div>)
    }

DialogCrudButton.propTypes = {
    dirty: PropTypes.bool.isRequired,
    datiOk: PropTypes.bool.isRequired,
    newRecord: PropTypes.bool.isRequired, // flag add record
    onSave: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onDelete: PropTypes.func
}

export {DialogCrudButton}