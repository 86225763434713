import React, { useState, useContext } from 'react';
import PrincipalsContext from '../PrincipalsContext';
import {InputText} from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import {ContextMenu} from 'primereact/contextmenu';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';

import { gql, useLazyQuery, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {OreAuxDialog, saveAuxval} from './DDTOggi_voceaux';

import { dataUTC_nTZ } from '../dateutil';


const GQL_DDTOGGI = gql`
query oggi_bolle_voci($data_inizio: timestamp, $data_fine: timestamp) {
  recordset: bolle_voci(where: {bolle: {_and: [{data: {_gte: $data_inizio}}, {data: {_lt: $data_fine}}]}}, order_by: [{cod_bolla: asc}, {anno: asc}, {num_bolla: asc}, {num_voce: asc}]) {
    id
    anno
    cod_bolla
    num_bolla
    num_voce
    tara
    unita
    lordo
    articolo {
      descrizion
      gruppo
    }
    bolle {
      cod_pers
      anagrafe {
        rag_soc
      }
      anagconducente {
        rag_soc
      }
      data
      targa
      veicoli {
        cod_artic
      }
      destinazioni {
        destinazione
      }
      bolle_commesse_trasporti {
        commesse_trasporti {
          data_fine
        }
      }
      anagvettore {
        rag_soc
      }
    }
    bolle_commesse_prodotti {
      cod_commessa
      commesse_prodotti {
        data_fine
      }
    }
  }
}
`

const ddtOggiGSheets = gql`
query bolleoggigsheets($dataInizio: Date) {
  bolleoggigsheets(dataInizio: $dataInizio)
}
`

const DdtDelMutation = gql`
mutation ddtOggiDelDDT($anno: Int!, $cod_bolla: bpchar!, $num_bolla: Int!) {
  delete_bolle_by_pk(anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla) {
    cod_bolla
    num_bolla
  }
}`

export const GQL_PRINT = gql`
mutation stampaddt ($anno: Int, $cod_bolla: bpchar, $num_bolla: Int, $stampante: String, $stamparescontrino: String) {
  insert_ddt_print_queue_one(object: {anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla, stampante: $stampante, stamparescontrino: $stamparescontrino}) {
    treg
  }
}
`
export const GQL_PRINT_STATUS = gql`
  query PrintStatus($cod_bolla: bpchar, $num_bolla: Int, $treg: timestamptz) {
    ddt_print_queue(where: {cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}, treg: {_eq: $treg}}) {
      esito_stampa
    }
  }`

// la seguente vuole
// cod_bolla, num_bolla,
// visible, onHide (per permettere al chiamante di chiudere la dialog)
// onPrint (il chiamante esegue e prosegue le operazioni di monitoraggio stampa)

function PrintDialog(props) {
  const {cod_bolla, num_bolla} = props;
  const {elencoStampanti, elencoCodBollaStampante} = props;
  const options_elencoStampanti = elencoStampanti.map(e => ({label: e, value: e}))
  const stampante_cod_bolla = elencoCodBollaStampante[cod_bolla]
  
  const [state, setState] = useState({
    scontrino: false,
    stampante: elencoCodBollaStampante[cod_bolla]
  })

  return (
    <Dialog 
        header={"Stampa del DDT " + cod_bolla + " " + num_bolla}
        visible={props.visible} onHide={props.onHide}
        modal
        style={{width: '40vw'}}
        contentStyle={{minHeight: '10em'}}
        footer={(
          <div>
            <Button 
              label="STAMPA" icon="pi pi-print" 
              onClick={(e) => props.onPrint(state.stampante || stampante_cod_bolla, state.scontrino)}/>
          </div>
        )}>
      <div className='container'>
        <div className="row  mb-5">
          <div className="col-12 col-md-4 d-flex align-items-center">
              <label>Stampante:</label>
          </div>
          <div className="col-12 col-md-8">
              <Dropdown 
                style={{width: "100%"}}
                value={state.stampante || stampante_cod_bolla}
                options={options_elencoStampanti} 
                onChange={e => setState(prev => ({...prev, stampante: e.value}))} 
                scrollHeight="5em"/>
          </div>
        </div>
        <div className="row">
          <div className="col-1 col-md-1 d-flex align-items-center">
            <Checkbox 
              value={true} 
              inputId="cbstampareScontrino" 
              onChange={e => setState(prev => ({...prev, scontrino: e.checked}))} 
              checked={state.scontrino} />
          </div>
          <div className="col d-flex align-items-center">
            <label 
              htmlFor="cbstampareScontrino">Stampare scontrino</label>
          </div>
        </div>
        </div>
    </Dialog>
  )
}

function CreateGSpreadsheet({ dataInizio, disabled }) {
  const [start, setStart] = useState(true);
  const [create_spreadsheet, { loading, error, data }] = useLazyQuery(ddtOggiGSheets);
  let label = "Crea foglio Google";
  
  if (loading) 
    label = `Sto creando...  ${ Intl.DateTimeFormat(navigator.languages,{dateStyle:"short"}).format(dataInizio)}`
  if (error) 
    label = `Riprova (errore ${error.message})`
  if (start && data && data.bolleoggigsheets) {
    setStart(false)
    window.open(data.bolleoggigsheets)
  }
  return (
      <Button 
        label={label}
        disabled={disabled}
        tooltip="crea nella cartella DDTrapportini"
        onClick={() => {
          setStart(true);
          create_spreadsheet({variables: { dataInizio: dataUTC_nTZ(dataInizio).toISOString().slice(0,10) }})
      }} />
  )
}

function OreTrasportoDialog(props) {
  const [state, setState] = useState({ore: ''})
  return (
    <Dialog 
        header={"Aggiungi ore trasporto a " + props.cod_bolla + " " + props.num_bolla}
        visible={props.visible} onHide={props.onHide}
        modal
        footer={(
          <div className='row justify-content-center'>
            <Button 
              label="Aggiungi o aggiorna le ore" icon="pi pi-md-update" 
              onClick={(e) => props.onSave(state.ore)}/>
          </div>
        )}>
      <div className="row" >
        <h5 className="d-flex align-items-end col-2">
          <label className='' htmlFor="itOre">Ore:</label>
        </h5>
        <div className='col'>
          <InputText id="itOre" value={state.ore} onChange={e => setState({ore: e.target.value})} type="number"/>
        </div>
      </div>
    </Dialog>
  )
}

function Tabella(props) {

  const headerTemplate = (data) => <React.Fragment><strong>Elenco per il codice {data.cod_bolla}</strong></React.Fragment>
  const footerTemplate = (data) => <React.Fragment><td colSpan="12" style={{fontSize:'smaller', textAlign: 'center'}}>fine codice {data.cod_bolla}</td></React.Fragment>
  let cm;

  const gruppo = (rowData, column) => {
    let classi = {bitum: 'red', basal: 'blue', fresa: 'orange'}
    let gruppo = rowData.articolo.gruppo
      if (gruppo in classi)
        return <span style={{color: classi[gruppo]}}>{gruppo}</span>
      return <span>{gruppo}</span>
    }

  return (
    <React.Fragment>
      <ContextMenu model={props.contextMenu} ref={el => cm = el}/>
      <DataTable value={props.data ? props.data : []}
          emptyMessage="Nessun dato"
          responsive="true"

          style={{fontSize:'small'}}

          onSelectionChange={props.onContextMenuSelect}
          selectionMode="single"
          selection={props.selection}

          contextMenu={cm}
          onContextMenu={e => cm.show(e.originalEvent)}
          contextMenuSelection={props.selection}
          onContextMenuSelectionChange={props.onContextMenuSelect}

          rowGroupMode="subheader"
          groupRowsBy="cod_bolla"
          rowGroupHeaderTemplate={headerTemplate}
          rowGroupFooterTemplate={footerTemplate}>
        <Column field="num_bolla" header="Num." />
        <Column field="data" header="Ora" body={(rowData, column) => (<span>{Intl.DateTimeFormat(navigator.languages, {timeStyle: "short"}).format(new Date(rowData.bolle.data))}</span>)}/>
        <Column field="bolle.anagconducente.rag_soc" header="Conducente" style={{fontSize:'smaller'}}/>
        <Column field="bolle.targa" header="Targa" />
        <Column field="bolle.anagvettore.rag_soc" header="Vettore" style={{fontSize:'xx-small'}} body={(rowData, column) => (<span>{rowData.bolle.anagvettore.rag_soc.slice(0,30)}</span>)}/>
        <Column field="bolle.anagrafe.rag_soc" header="Destinatario" />
        <Column field="bolle.destinazioni.destinazione" header="Destinazione" />
        <Column field="unita" header="Unita" />
        <Column field="lordo" header="Lordo" />
        <Column field="articolo.descrizion" header="Articolo" style={{fontSize:'smaller'}}/>
        <Column field="netto" header="Netto" style={{fontSize:'smaller'}} body={(rowData, column) => (<span>{(rowData.lordo - rowData.tara).toFixed(2)}</span>)}/>
        <Column field="articolo.gruppo" header="Gruppo merc." style={{fontSize:'smaller'}} body={gruppo}/>
        <Column field="bolle_commesse_prodotti.cod_commessa" header="Commessa" style={{fontSize:'smaller'}}/>
      </DataTable>
    </React.Fragment>
    )
}


function msgStartPrint(response) {
  if (!response) return null;
  let {data, error, graphQLErrors, message} = response;
  if (data) {
    return ({
      life: 15000,
      detail: `stampa avviata il ${data.insert_ddt_print_queue_one.treg}`, 
      severity:'info'})
  }
  if (error) {
    return ({
      sticky: true,
      detail: 'errore di avvio stampa: ' + error.msg, 
      severity:'error'})
  }
  if (graphQLErrors) {
    return ({
      sticky: true,
      detail: 'errore generale: ' + message, 
      severity:'error'})
  }
}


function printDDT(printDDTGrowl, state, setState, actionprint, stampante, scontrino) {
    let {anno, cod_bolla, num_bolla} = state.selectedDDT;
    printDDTGrowl.show({life:5000, detail: `comando stampa ricevuto ${cod_bolla} ${num_bolla}` , severity:'warn'})
    actionprint({
      variables:{anno, cod_bolla, num_bolla, stampante, stamparescontrino: Boolean(scontrino).toString()}
      }).then((response) => {
        printDDTGrowl.show(msgStartPrint(response))
        setState(prev => ({...prev, print_queue_treg: response.data.insert_ddt_print_queue_one.treg, waitPrintStatus: true, printDialog: false}))
      }).catch(
        (response) => printDDTGrowl.show(msgStartPrint(response))
      )
}

function msgSaveOreTrasporto(response) {
  if (!response) return null;
  let {data, error, graphQLErrors, message} = response;
  if (typeof(response) === 'string') {
    return ({
      sticky: true,
      detail: 'errore: ' + response, 
      severity:'error'})

  }
  if (data) {
    return ({
      life: 15000,
      detail: 'ore salvate', 
      severity:'success'})
  }
  if (error) {
    return ({
      sticky: true,
      detail: 'errore di salvataggio: ' + error.msg, 
      severity:'error'})
  }
  if (graphQLErrors) {
    return ({
      sticky: true,
      detail: 'errore generale: ' + message, 
      severity:'error'})
  }
}

function showOreSalvateGrowl(printDDTGrowl, response) {
  const growl_opts = msgSaveOreTrasporto(response)
  printDDTGrowl.show(growl_opts)
}


const GQL_DATI_PER_PRODURRE_ORE_TRASPORTO = gql`
      query dati_per_produrre_ore_trasporto($anno: Int!, $cod_bolla: bpchar!, $num_bolla: Int!, $data: timestamp!, $cod_pers: Int!, $cod_artic_servizio: Int!, $classe: String = "prodotto") {
        voci_servizio: bolle_voci(where: {anno: {_eq: $anno}, cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}, articolo: {descrizion: {_like: "servizio c %"}}}) {
          num_voce
          cod_artic
        }
        bolle_voci_aggregate(where: {anno: {_eq: $anno}, cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}}) {
          aggregate {
            max {
              num_voce
            }
          }
        }
        bolla: bolle_by_pk(anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla) {
          cod_pers
          veicoli {
            cod_artic
          }
          bolle_vocis(where: {bolle_commesse_prodotti: {cod_commessa: {_gt: ""}}}) {
            bolle_commesse_prodotti {
              cod_commessa
            }
          }
        }
        commessa_prodotto_per_servizio: commesse_prodotti_trasporti_valide_data(where: {cod_pers: {_eq: $cod_pers}, data_fine: {_gte: $data}, classe: {_like: $classe}, cod_artic: {_eq: $cod_artic_servizio}}, order_by: [{cod_commessa: asc}, {classe: asc}, {descrizion: asc}], args: {data: $data}) {
          cod_commessa
          data_inizio
          cod_artic
          descrizion
          note
          unita
          classe
        }
      }
            
      `;
async function nuovaSaveOre(client, printDDTGrowl, state, ore, bolleoggi_refetch) {
  let {anno, cod_bolla, num_bolla, bolle: bolla_selezionata} = state.selectedDDT;
  let ddt_key = { anno, cod_bolla, num_bolla, ore}
  let {cod_pers, data, veicoli: {cod_artic: cod_artic_servizio}} = bolla_selezionata
  try {
    let dati = (await client.query({
      query: GQL_DATI_PER_PRODURRE_ORE_TRASPORTO,
      variables: {...ddt_key, cod_pers, data, cod_artic_servizio}}
    ));
    const {voci_servizio, bolle_voci_aggregate} = dati.data
    const commessa_prodotto_per_servizio = dati.data.commessa_prodotto_per_servizio[0]
    if (commessa_prodotto_per_servizio == null)
      throw String(`Manca una commessa per il servizio di consegna per il codice articolo ${cod_artic_servizio}`)
    const {data_inizio, cod_commessa} = commessa_prodotto_per_servizio

    const num_voce = voci_servizio[0]?.num_voce ?? bolle_voci_aggregate.aggregate.max.num_voce +1
    if ((Number(ore) !== 0) || (voci_servizio.length > 0)){
      let update_or_delete = ore > 0 ?
        gql`
        mutation voce_trasporti_upsert($anno: Int!, $cod_artic_servizio: Int!, $cod_bolla: bpchar!, $lordo: numeric!, $num_bolla: Int!, $num_voce: smallint!, $cod_commessa: String!, $cod_pers: Int!, $data_inizio: timestamp!) {
          insert_bolle_voci_one(object: {anno: $anno, cod_bolla: $cod_bolla, cod_artic: $cod_artic_servizio, lordo: $lordo, num_bolla: $num_bolla, num_voce: $num_voce, tara: 0, unita: "h"}, on_conflict: {constraint: bolle_voci_pkey, update_columns: [cod_artic, lordo]}) {
            id
            unita
            lordo
            cod_artic
            articolo {
              descrizion
              gruppo
            }
          }
          insert_bolle_commesse_prodotti_one(object: {anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla, num_voce: $num_voce, cod_pers: $cod_pers, cod_commessa: $cod_commessa, cod_artic: $cod_artic_servizio, data_inizio: $data_inizio}, on_conflict: {constraint: bolle_voce, update_columns: [cod_commessa, cod_artic, data_inizio]}) {
            num_voce
          }
        }`
      : gql`
        mutation voce_trasporti_del($anno: Int!,  $cod_bolla: bpchar!, $num_bolla: Int!, $num_voce: smallint!) {
          delete_bolle_voci_by_pk(anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla, num_voce: $num_voce) {
            id
            num_voce
          }
        }
      `
      let response = await client.mutate({
        mutation: update_or_delete,
        variables: {...ddt_key, num_voce, lordo: ore, cod_artic_servizio, cod_pers, cod_commessa, data_inizio}
      })

      await client.resetStore()
      await bolleoggi_refetch()
      showOreSalvateGrowl(printDDTGrowl, response)
    }
  } catch(err) {
    console.log(err);
    showOreSalvateGrowl(printDDTGrowl, err)
  }
}

function msgDelDdt (response) {
  if (!response) return null;
  let {data, error, graphQLErrors, message} = response;
  if (data) {
    return ({
      life: 15000,
      detail: 'risposta cancellazione: ' + data.delBolla.msg, 
      severity:'success'})
  }
  if (error) {
    return ({
      sticky: true,
      detail: 'errore di cancellazione: ' + error.msg, 
      severity:'error'})
  }
  if (graphQLErrors) {
    return ({
      sticky: true,
      detail: 'errore generale: ' + message, 
      severity:'error'})
  }
}

function showDelDdtGrowl(printDDTGrowl, response) {
  const growl_opts = msgDelDdt(response)
  printDDTGrowl.show(growl_opts)
}

function onDelDdt(printDDTGrowl, client, state) {
    let {anno, cod_bolla, num_bolla} = state.selectedDDT;
    client.mutate(
      {
        mutation: DdtDelMutation,
        refetchQueries:['oggi_bolle_voci'],
        variables: {anno, cod_bolla, num_bolla}
      }).then(
        showDelDdtGrowl.bind(null, printDDTGrowl)
      ).catch(
        showDelDdtGrowl.bind(null, printDDTGrowl)
      )
  }

function onCreaDdtSimile(state, navigate) {
  let {anno, cod_bolla, num_bolla} = state.selectedDDT;
  navigate(`/ddt/${cod_bolla}`, {state: {editBollaKey: {anno, num_bolla}}})
}

  
export function DdtOggi(props) {
  const {allowedRoles} = useContext(PrincipalsContext)
  const navigate = useNavigate()
  const client = useApolloClient()
  let printDDTGrowl;
  const [state, setState] = useState({
    oggi: new Date(), 
    oggival: new Date(),
    selectedDDT: null,
    printDialog: false,
    waitPrintStatus: false,
    print_queue_treg: null,
    print_queue_tentativi: 0,
    elencoStampanti: [],
    elencoCodBollaStampante:{}
  })

  function esito_stampa_onCompleted(data) {
    let esito_stampa = data.ddt_print_queue[0]?.esito_stampa;
    if (esito_stampa) {
      printDDTGrowl.show({life:15000, detail: esito_stampa, severity:'success'})
      setState(prev => ({...prev, print_queue_tentativi: 0, waitPrintStatus: false}))
    }
    else {
      if (state.print_queue_tentativi > 10) {
        printDDTGrowl.show({sticky: true, detail: 'mancata segnalazione esito stampa', severity:'error'})
        setState(prev => ({...prev, waitPrintStatus: false, print_queue_tentativi: 0}))
      }
      else
        setState(prev => ({...prev, print_queue_tentativi: prev.print_queue_tentativi +1}))
    }
  }

  function esito_stampa_onError(error) {
    printDDTGrowl.show({sticky: true, detail: 'errore di stampa' + error.message, severity:'error'})
    setState(prev => ({...prev, waitPrintStatus: false}))
  }

  useQuery(gql`
  query datiCodBolla {
    stampantiList: ghiaia3_cod_bolla_metadati {
      cod_bolla
      stampante: ddt(path: "stampante")
      stampare: ddt(path: "stampare")
    }
  }`,
  {
    notifyOnNetworkStatusChange: true, // apollo-client bug #5531 ma rivedi se aggiorni release perche andra peggio
    onCompleted: (data) => {
      let dati = data?.stampantiList ? data.stampantiList : []
      let elencoStampanti = [...new Set(dati.filter(e => e.stampare).map(e => e.stampante))]
      let elencoCodBollaStampante = Object.assign({}, ...dati.map((e) => ({[e.cod_bolla]: e.stampante})))
      setState(prev => ({...prev, elencoStampanti, elencoCodBollaStampante}))},
    onError: (error) => console.error(error)
  })
    

  useQuery(gql`
  query PrintStatus($cod_bolla: bpchar, $num_bolla: Int, $treg: timestamptz) {
    ddt_print_queue(where: {cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}, treg: {_eq: $treg}}) {
      esito_stampa
    }
  }`,
  {
    skip: !state.waitPrintStatus,
    variables: {...state.selectedDDT, treg: state.print_queue_treg} ,
    onCompleted: esito_stampa_onCompleted,
    onError: esito_stampa_onError,
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true // apollo-client bug #5531
  })

  let commessa_attiva = (new Date(state?.selectedDDT?.bolle_commesse_prodotti?.commesse_prodotti?.data_fine) >= new Date())
    && (! state?.selectedDDT?.bolle.bolle_commesse_trasporti?.commesse_trasporti?.data_fine || (new Date(state?.selectedDDT?.bolle.bolle_commesse_trasporti?.commesse_trasporti?.data_fine) >= new Date()));
  let utente_dir = (allowedRoles.includes('amminist') || allowedRoles.includes('dir'))
  let contextMenu = [{
      label: 'Stampa', 
      icon: 'pi-md-print', command: () => setState(prev => ({...prev, printDialog: true}))
    },
    {
      label: commessa_attiva ? 'Crea Bolla simile' : 'Bolla simile: commessa scaduta', 
      icon: 'pi-md-create', command: () => onCreaDdtSimile(state, navigate),
      disabled: ! commessa_attiva
    },
    {
      label: '+ Ore Trasporto', 
      icon: 'pi-md-update', command: () => setState(prev => ({...prev, oreTrasportoDialog: true}))
    },
    {
      label: '+ Servizi ausiliari', 
      icon: 'pi-md-edit-location', command: () => setState(prev => ({...prev, oreAuxDialog: true}))
    },
    ...utente_dir ? [{
      label: 'Elimina',
      icon: 'pi-md-delete', command: () => onDelDdt(printDDTGrowl, client, state)
    }] : []
  ];

  const data_inizio = state.oggi;
  let data_fine = new Date(data_inizio);
  data_fine.setDate(data_fine.getDate() + 1);
  data_fine = dataUTC_nTZ(data_fine).toISOString().slice(0,10)
  const { data: qdata, refetch: bolleoggi_refetch } = useQuery(GQL_DDTOGGI, {
    fetchPolicy: "network-only",
    variables: {data_inizio: dataUTC_nTZ(data_inizio).toISOString().slice(0,10), data_fine}
  })

  const actionDdtPrint = useMutation(GQL_PRINT)[0]
  return (
    <div className='card shadow'>
        <div className='card-body ddtoggihead'>
          <h2 style={{marginBottom:"1em"}}>Elenco Bolle del {Intl.DateTimeFormat({dateStyle:"short"}).format(state.oggi)}</h2>
          <div className="card-title">
            <div className="no-print row">
              <h5 className="no-print col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-2"><label htmlFor="oggi">Elenco per la data:</label></h5>
              <Calendar showIcon 
                className="no-print col-lg-4 col-md-3 col-sm-4 col-4"
                id="oggi"
                value={state.oggival}
                onChange={(e) => {
                    if (e?.value?.getDate) {
                      setState(prev => ({...prev, oggi: e.value}));
                    }
                    setState(prev => ({...prev, oggival: e.value}))
                }}
              
                //locale={calendarlocale}
                dateFormat="dd/mm/yy"
                placeholder="Data dell'elenco"
                />
              <div className='col'></div>
              <div className="no-print col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                <CreateGSpreadsheet dataInizio={state.oggi} disabled={!(qdata && qdata.recordset[0])}/> 
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>       
          <div className="col-12" id="bolleoggi">
            <Tabella 
              data={qdata ? qdata.recordset: []} 
              onContextMenuSelect={e => setState(prev => ({...prev, selectedDDT: e.value}))}
              selection={state.selectedDDT}
              contextMenu={contextMenu}
            />
          </div>
        </div>

        <PrintDialog 
          key={ `prdia1${state.selectedDDT?.cod_bolla}`}
          visible={state.printDialog} 
          ddtData={state.selectedDDT} 
          elencoStampanti={state.elencoStampanti}
          elencoCodBollaStampante={state.elencoCodBollaStampante}
          onHide={() => setState(prev => ({...prev, printDialog: false}))}
          onPrint={(stampante, scontrino) => printDDT(printDDTGrowl, state, setState, actionDdtPrint, stampante, scontrino)}
          {...state.selectedDDT}/>

        <OreTrasportoDialog 
          key={ `oretraspdia1${state.selectedDDT?.cod_bolla}${state.selectedDDT?.num_bolla}`}
          visible={state.oreTrasportoDialog} 
          ddtData={state.selectedDDT} 
          onHide={() => setState(prev => ({...prev, oreTrasportoDialog: false}))}
          onSave={(ore) => {nuovaSaveOre(client, printDDTGrowl, state, ore, bolleoggi_refetch); setState(prev => ({...prev, oreTrasportoDialog: false}))}}
          {...state.selectedDDT}/>

        <OreAuxDialog 
          key={ `oreauxdia1${state.selectedDDT?.cod_bolla}${state.selectedDDT?.num_bolla}`}
          visible={state.oreAuxDialog} 
          ddtData={state.selectedDDT}
          onHide={() => setState(prev => ({...prev, oreAuxDialog: false}))}
          onSave={(auxval) => {saveAuxval(client, printDDTGrowl, state, auxval, bolleoggi_refetch); setState(prev => ({...prev, oreAuxDialog: false}))}}
          {...state.selectedDDT}/>

        <Toast 
          ref={function(el) {printDDTGrowl = el}} 
          onRemove={() => setState(prev => ({...prev, printjob: null, printscontrinojob: null}))}
        />
    </div>);
}
