import React from 'react'

const Allarme = (props) => {
  let style = {
    true: {border: "transparent solid", borderBottomColor: "red" , textAlign: "center"},
    false: {border: "transparent", textAlign: "center"}}
  let si_no = {false: 'NO', true: 'SI'}
  return <div style={{textAlign: "center"}}><span style={style[props.stato]}>{props.children} - {si_no[props.stato]}</span></div>
}

const PlcInfo = (props) => {
  return (
    <div className="card mb-3">
      <div className={"card-header " + (props.errore ? "text-bg-danger" : "")}>
        <div className='row'>
        <span style={{fontSize: "1.5rem"}}  className='col'>Informazioni</span>
          <i style={{fontSize: "2rem"}} className={"col text-end pi pi-fw  " + (props.errore ? "pi-exclamation-triangle" : "pi-check")} />
        </div>
      </div>
      <div className="card-body">
        <h3 className="card-title">Stato del sistema</h3>
        <h6 className='card-subtitle text-body-secondary'>Ora del sistema: {Intl.DateTimeFormat(navigator.locales, {dateStyle:"medium", timeStyle:"short"}).format()}</h6>
          <Allarme stato={!props.I_SoftstarterON}>Softstarter non alimentato</Allarme>
          <Allarme stato={props.I_SoftstarterErrore}>Errore Softstarter</Allarme>
          <Allarme stato={props.I_PompaLavaggioProtezione}>Prot. Termica Pompa</Allarme>
          <Allarme stato={!props.I_LivelloMin}>Manca acqua nella vasca</Allarme>
      </div>
      <div className='card-footer text-body-secondary'>
        <h4>
          <span className={"badge " + (props.errore ? "bg-danger" : "bg-primary")}>{props.errore ? "ERROR in attesa dei dati" : "RUN"}</span>
        </h4>
      </div>
    </div>)
}
export default PlcInfo
