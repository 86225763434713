//theme
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primereact/resources/primereact.min.css";                                       
import 'primeicons/primeicons.css';

        
import './App.css';

import { createHashRouter, redirect, RouterProvider } from "react-router-dom";
import { locale, addLocale } from 'primereact/api';

import { UnApp } from './UnApp';
import { MainPageContainer } from "./MainPageContainer";
import { DDTQueryCommesse } from './components/DDT/DDTquerycommesse';

// si potrebbe poter togliere moment.js
// Qualche WIDGET ancora lo usa
import moment from 'moment'
import 'moment/locale/it';
import { Dashboard } from './components/Dashboard';
import DdtDaCommessa from './components/DDT/DdtDaCommessa';
import { DdtOggi } from './components/DDTOggi';
import { DdtExportAdHoc } from './components/DDT/DdtExportAdHoc';
import { DestinazioniRegistro } from './components/DESTINAZIONI/Registro';
import { Veicoli } from './components/VEICOLI/Veicoli';
import { CommesseRegistro } from './components/COMMESSE/Registro';
import { ErogAvvia } from './components/EROGATORI/ErogAvvia';
import { C2009Registro } from './components/C2009/Registro';
import { PistaLavata } from './components/PLC/PistaLavata';
import { route as C2009AddVoceRegRoute } from './components/C2009/AddVoceReg';

moment.locale('it')


let calendarlocale = {
  firstDayOfWeek: moment.localeData().firstDayOfWeek(),
  dayNames: moment.weekdays(),
  dayNamesShort: moment.weekdaysShort(),
  dayNamesMin: moment.weekdaysMin(),
  monthNames: moment.months(),
  monthNamesShort: moment.monthsShort(),
  today: 'oggi',
  clear: "azzera",
  dateFormat:"dd/mm/yy"
};

addLocale('it', calendarlocale)
locale('it')


const router = createHashRouter([
  {
    path: "/",
    element: <MainPageContainer />,
    children:[
      {
        path: '/',
        element: <Dashboard />
      },
      {
        path: '/ciao/:testo',
        element: <UnApp />,
      },
      {
        path: '/ddtquerycommesse',
        element: <DDTQueryCommesse />
      },
      {
        path: '/commesseregistro',
        element: <CommesseRegistro />
      },
      {
        path: '/ddt/:codBolla?',
        element: <DdtDaCommessa />,
        loader: ({params}) => {
          if (!params.codBolla)
            return redirect('/ddt/MM')
          return null
        }
      },
      {
        path: '/ddtoggi',
        element: <DdtOggi />,
      },
      {
        path: '/destinazioniregistro',
        element: <DestinazioniRegistro />
      },
      {
        path: '/ddtadhoc',
        element: <DdtExportAdHoc />
      },
      {
        path: '/veicoli',
        element: <Veicoli />
      },
      {
        path: '/erogavvia',
        element: <ErogAvvia />
      },
      {
        path: '/c2009registro',
        element: <C2009Registro />
      },
      C2009AddVoceRegRoute,
      {
        path: '/pistalavata',
        element: <PistaLavata />
      }
    ]
  },
]);

function App() {
    return (
        <RouterProvider router={router} />
     );
}

export default App;
