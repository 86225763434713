/****** Dropdown
 ***
 */

 /* I valori in uscita e entrata sono nella forma {recid, label} */
 

import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Riga, DiaRiga } from './Riga'

class MyDropdown extends Component {
    itemTemplate = (elm) => elm.labelext ? (
        <React.Fragment>
            <span>{elm.label}</span> <span style={{fontSize:'x-small'}}>{elm.labelext}</span>
        </React.Fragment>) : null

    render() {
        let props = {...this.props}
        if (!props.itemTemplate && props.options && props.options.length && props.options[0].labelext)
            props.itemTemplate = this.itemTemplate
        return (
            <Dropdown 
                style={{width: '100%'}}
                optionLabel="label"
                className={this.props.value && this.props.value.recid ? null : "p-error"}

                {...props} />
        )
    }
}

export const DiaRigaDropdown = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <MyDropdown  {...props} />
    </DiaRiga>)

export const RigaDropdown = (props) => (
    <Riga id={props.id} label={props.label} showif={props.showif}>
        <MyDropdown  {...props} />
    </Riga>)
