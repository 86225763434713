import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast'

import { DiaRiga } from '../WIDGETS2/Riga'
import { DiaRigaInputText, inputTextFloatRecid } from '../WIDGETS2/InputText'
import { DiaRigaDBAutocomplete } from '../WIDGETS/DBAutocomplete'
import { DiaRigaInputData, inputDataRecid } from '../WIDGETS2/InputData'
import { DiaRigaDropdown } from '../WIDGETS/Dropdown'
import { DialogCrudButton } from '../WIDGETS2/DialogCrudButton'

const ADD_COMMESSA_PRODOTTO = gql`
mutation ADD_COMMESSA_PRODOTTO($cod_artic: Int, $cod_commessa: String, $costo_unit: numeric, $data_fine: timestamp, $cod_pers: Int, $data_inizio: timestamp, $unita: String) {
    insert_commesse_prodotti_one(
        object: {cod_artic: $cod_artic, cod_commessa: $cod_commessa, costo_unit: $costo_unit, data_fine: $data_fine, data_inizio: $data_inizio, unita: $unita, cod_pers: $cod_pers}, 
        on_conflict: {constraint: commesse_prodotti_pkey, update_columns: [data_fine,costo_unit, unita, cod_artic]}) {
      cod_pers
      cod_commessa
    }
  }
  
`
 
const DEL_COMMESSA_PRODOTTO = gql`
mutation DEL_COMMESSA_PRODOTTO($cod_artic: Int!, $cod_commessa: String!, $costo_unit: numeric, $data_fine: timestamp, $cod_pers: Int!, $data_inizio: timestamp!, $unita: String) {
    delete_commesse_prodotti_by_pk(cod_artic: $cod_artic, cod_commessa: $cod_commessa, cod_pers: $cod_pers, data_inizio: $data_inizio) {
      cod_artic
      cod_commessa
      data_inizio
      cod_pers
    }
  }
`

const ELENCO_UNITA = ["T.", "h", "mc", "n", "Kg"].map((el) => ({label: el, recid: el}));

DialogSaveCommessaProdotto.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    newRecord: PropTypes.bool.isRequired, // flag add record
    presetForm: PropTypes.bool,
    initRecord: PropTypes.object,
}


const INIT_STATE = () => ({
    articolo: {label: '', recid: null},
    data_fine: {label: '_', recid: null},
    data_inizio: {label: '_', recid: null},
    unita: {label: ''},
    costo_unit: {label: "", recid: null},
    dirty:false,
  })

  
// impone ai dati provenienti da registro (rowselected) la "forma normale"
function initRecord2InitState(initRecord) {
    let {descrizion, cod_artic, cod_commessa, costo_unit, data_fine, data_inizio, unita, cod_pers, rag_soc} = initRecord;
    let ret_val = {
        articolo: {label: descrizion, recid: cod_artic},
        cod_commessa,
        data_fine: inputDataRecid(data_fine),
        data_inizio: inputDataRecid(data_inizio),
        unita: {label: unita, recid: unita},
        costo_unit: inputTextFloatRecid(costo_unit),
        cod_pers,
        rag_soc,
        dirty: false}
    return ret_val
}

export function DialogSaveCommessaProdotto(props) {
    const [record, setRecord] = useState(INIT_STATE());
    let growl;
    useEffect(resetState, [props.presetForm, props.initRecord, props.newRecord])

    function resetState() {
        let tmp = INIT_STATE();
        if (props.newRecord && !props.presetForm) {
            tmp.cod_pers = props.initRecord.cod_pers;
            tmp.rag_soc = props.initRecord.rag_soc;
            tmp.cod_commessa = props.initRecord.cod_commessa;
            setRecord(tmp)
        }
        else if (props.newRecord && props.presetForm) {
            tmp = initRecord2InitState(props.initRecord)
            tmp.articolo = {label: '', recid: null}
            tmp.costo_unit = inputTextFloatRecid('')
            setRecord(tmp);
        }
        else if (props.initRecord && props.initRecord.data_inizio) {
            tmp = {...props.initRecord}
            setRecord(initRecord2InitState(tmp));
        }
    }

    let datiOK = () => {
        return !!(
            record.articolo.recid
            && record.cod_commessa
            && record.data_fine.recid
            && record.data_fine.recid
            && record.unita.recid
            && record.costo_unit.recid > 0
            && record.cod_pers
        )
    }

    let getVariables = () => datiOK() ? ({
        cod_pers: record.cod_pers, 
        cod_commessa: record.cod_commessa,
        data_inizio: record.data_inizio.recid,
        cod_artic: record.articolo.recid,
        data_fine: record.data_fine.recid,
        unita: record.unita.recid,
        costo_unit: record.costo_unit.recid
    }) : {}

    let msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
            growl.show({
            severity: 'success', summary: 'Esito',
            detail: 'Corretto',
            life: 3000
            })
        }
        if (error) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito per errore dati:' + error, life: 6000
            })
        }
        if (graphQLErrors) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + saveresponse.message, life: 3000
            })
        }
    }

    let onMutationOK = (data) => {
        if (data) {
        msgSave({data: data})
        props.onHide()
        props.refetch()
        console.log('OK salvato')
        }
    }

    let onMutationError = (error) => {
        console.log('Errori apollo o eccezione in onMutationOK', error);
        if (error) {
        msgSave({error: error})
        }
    }

    let onSave = (data, error) => {
        let dati = getVariables()
        console.log('salva dati', dati);
        actionadd({variables: dati})
    }

    const actionadd = useMutation(ADD_COMMESSA_PRODOTTO, {onCompleted: onMutationOK, onError: onMutationError})[0]

    let onDelete = (data, error) => {
        let dati = getVariables()
        console.log('cancella dati', dati);
        actiondel({variables: dati})
    }

    const actiondel = useMutation(DEL_COMMESSA_PRODOTTO, {onCompleted: onMutationOK, onError: onMutationError})[0]

    let onHide = () => {
        resetState()
        props.onHide()
    }

    let setAttrib = (attr_name, value) => setRecord(
        (prev_record) => ({...prev_record, [attr_name]: value, dirty: true}))
    return (
        <>
            <Toast ref={el => growl = el}/>
            <Dialog header="Voce prodotto di commessa"
                visible={props.visible} 
                onHide={onHide}
                style={{width: '70vw'}}
                contentStyle={{minHeight:'30em'}}
                modal={true}>
                    <DiaRiga id='rag_soc' label="Cliente">{record.rag_soc}</DiaRiga>
                    <DiaRiga id='cod_commessa' label="Cod Commessa">{record.cod_commessa}</DiaRiga>
                    {props.newRecord 
                        ? <DiaRigaDBAutocomplete
                            id= "fld100"
                            label="Articolo"
                            graphql={gql`
                                query Query($qry: String = "") {
                                recordset: articoli(where: {descrizion: {_ilike: $qry}}, limit: 50, order_by: {descrizion: asc}) {
                                    recid: cod_artic
                                    label: descrizion
                                }
                            }
                            `}
                            defaultSuggestions={[{label:"nulla"}]}
                            value={record.articolo}
                            onChange={setAttrib.bind(this,"articolo")}
                            placeholder="articolo"/>
                        : <DiaRiga id='cod_commessa' label="Articolo">{record.articolo.label}</DiaRiga>}
                    {props.newRecord 
                        ?  <DiaRigaInputData 
                            label="Data inizio"
                            value={record.data_inizio}
                            onChange={setAttrib.bind(this, "data_inizio")} />
                        : <DiaRiga id="fld101datainizio" label="Data inizio">{record.data_inizio.label}</DiaRiga>}
                    <DiaRigaInputData 
                            label="Data fine"
                            value={record.data_fine}
                            onChange={setAttrib.bind(this, "data_fine")} />
                    <DiaRigaDropdown 
                            label="Unita"
                            options={ELENCO_UNITA}
                            value={record.unita}
                            onChange={(e) => {setAttrib("unita", e.value)}} />
                    <DiaRigaInputText
                            label="Costo unitario"
                            value={record.costo_unit}
                            required
                            type="number"
                            onChange={v => setAttrib("costo_unit", inputTextFloatRecid(v.label))}
                            validif={v => (parseFloat(v) > 0)}
                            /> 
                    <DialogCrudButton
                            dirty={record.dirty}
                            newRecord={props.newRecord}
                            onSave={onSave}
                            onDelete={props.newRecord ? null : onDelete}
                            onReset={resetState}
                            datiOk={datiOK()}/>
            </Dialog>
        </>
        )
}
