// Componente per sistema ExeDialog. Usa input per quantita
import React, {useState, useRef, useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';

const GQL_QUANTITA = gql`
query QuantitaUltimoRifornimento($up_matricola: String = "", $cnt_matricola: String = "") {
    costi2009_registro_aggregate(where: {up_matricola: {_eq: $up_matricola}, cnt_matricola: {_eq: $cnt_matricola}}) {
        aggregate {
            max {
                quantita
            }
        }
    }
}
`
export function Quantita(props) {
    const {currURLSearchParams, onFinish} = props;
    const [state, setState] = useState({quantita: ''})
    const inputRef = useRef();

    const { loading, error, data } = useQuery(GQL_QUANTITA,
        {variables: {
            up_matricola: currURLSearchParams.get('up_matricola'),
            cnt_matricola: currURLSearchParams.get('cnt_matricola')}});
            
    useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus();
    }, [data]);
    
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    const quantita = data.costi2009_registro_aggregate.aggregate.max.quantita;
    return (
        <div className="card-body">
            <h5 className="card-title">Seleziona una quantità (la quantità massima inserita è {quantita})</h5>
            <form onSubmit={(e) => {e.preventDefault(); onFinish({quantita: state.quantita})}}>
                <div className="mb-3">
                    <label htmlFor="quantitaInput" className="form-label">Quantità del rifornimento</label>
                    <input ref={inputRef} type="number" 
                        className="form-control" id="quantitaInput"
                        value={state.quantita} onChange={(e) => setState({...state, quantita: e.target.value})} />
                </div>
                <button className="btn btn-primary" disabled={!Boolean(state.quantita)} >Prosegui</button>
            </form>
        </div>
    )
}

Quantita.displayName = 'Quantita';