import React, { useState } from 'react'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import moment from 'moment'

/* Props:
  nome: titolo del Panel
  min: boolean stato del sensore minimo
  max: boolean stato del sensore massimo (=> min==true)
*/
export const PlcLivelloDiscreto = (props) => {
  let livelli_valore = [10, 20, 95]
  let livello_class = [
    "10%",
    "50%",
    "95%",
  ]
  let livello_etichetta = ['vuoto', 'Min', 'Max']
  let stato_livello = props.max ? 2 : props.min ? 1 : 0
  return (<>
    <div style={{height: "2rem"}} className="  mt-3 progress" role="progressbar" aria-label={props.nome || 'bacino'} aria-valuenow={livelli_valore[stato_livello]} aria-valuemin="0" aria-valuemax="100">
      <div className="progress-bar overflow-visible progress-bar-striped progress-bar-animated" style={{width: livello_class[stato_livello]}}>{props.nome || 'bacino'} {livello_etichetta[stato_livello]}</div>
    </div>
    </>)
}

// Props: titolo, key_cmd
// nome="Polmone"
// statoId="I_PompaScaricoPolmone"
// allarmeId="PompaScaricoPolmoneAllarme"
// immagine="immagine"
// oraFineCmdId = "PompaScaricoPolmoneCMDRemotoFine"
// tipoCmdId = "PompaScaricoPolmoneCMDRemoto"
// livMinId="I_PolmoneMin"
// livMaxId="I_PolmoneMax">

// 0: spento, 1: in funzione, 2: guasto termico
const indiceStato = (props) => (props.allarme ? 2 : props.stato ? 1 : 0)

const TaskBoxHeader = (props) => (
  <div className={"card-header " + ["text-bg-primay", "text-bg-success", "text-bg-danger"][indiceStato(props)]}>
    <div className='row'>
      <span style={{fontSize: "1.5rem"}}  className='col'>{props.nome}</span>
      <i style={{fontSize: "2rem"}} className={"col-text-end pi pi-fw " + ["pi-pause", "pi-caret-right", "pi-exclamation-triangle"][indiceStato(props)]} />
    </div>
  </div>

  );

export function PlcCmdRemoto(props) {
  const [state, setState] = useState({value:''});

  const handleChange = (event) => {
    if (event.target.value.length < 9)
      setState({value: event.target.value});
  }

  const handleButtonAvvia = (event) => {
    props.onSetStato(true, state.value)
    setState({value: ''})
  }

  const handleButtonBlocca = (event) => {
    props.onSetStato(false, state.value)
    setState({value: ''})
  }


  let cmd_remoto_attivo = props.oraFineCmd > props.orologio
  let secs_rimanenti = props.oraFineCmd - props.orologio
  return (
    <div className="p-12">
      <div className="card mb-3 ">
        <TaskBoxHeader {...props} />
        <div className="card-body">
          <div className="p-12">
            <div className="p-inputgroup">
              <InputText
                id={props.nome}
                value={state.value}
                keyfilter="int"
                placeholder={cmd_remoto_attivo ?
                    String(secs_rimanenti) 
                    + 'sec (fino ' 
                    + moment.unix(props.orologio)
                      .add(secs_rimanenti, 'seconds')
                      .format(secs_rimanenti <= 172800 ? '[alle] LTS [del] L': '[al] L LTS') 
                    + ')'
                    : "quanto secondi in manuale ?"}
                onChange={handleChange}
                type="number"/>
              <span className="p-inputgroup-addon">
                <Button
                  className={cmd_remoto_attivo && props.tipoCmd ? "p-button-success": "blue-grey-btn"}
                  onClick={handleButtonAvvia}
                  icon="pi pi-caret-right">
                </Button>
              </span>
              <span className="p-inputgroup-addon">
                <Button
                  className={cmd_remoto_attivo && !props.tipoCmd ? "p-button-danger": "blue-grey-btn"}
                  onClick={handleButtonBlocca}
                  icon="pi pi-pause">
                </Button>
              </span>
            </div>
          </div>
          {props.children}
        </div>
        <div className='card-footer text-body-secondary'>
            <h4><span className={"badge " + (["bg-primary", "bg-success", "bg-danger"][indiceStato(props)])}>{["SPENTO", "ACCESO", "ALLARME"][indiceStato(props)]}</span></h4>
        </div>
      </div>
    </div>)
}

export function PlcStatoRemoto(props) {
  return (
    <div className="p-12">
      <div className="card mb-3">
        <TaskBoxHeader {...props} />
        <div className="card-body">
          {props.children}
        </div>
        <div className='card-footer text-body-secondary'>
              <h4><span className={"badge " + (["bg-primary", "bg-success", "bg-danger"][indiceStato(props)])}>{["SPENTO", "ACCESO", "ALLARME"][indiceStato(props)]}</span></h4>
          </div>
      </div>
    </div>)
}
