import React, { useState } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog';
import { DiaRigaDBDropdown } from './WIDGETS/DBDropdown'


import { gql } from '@apollo/client';

export function OreAuxDialog(props) {
  const [state, setState] = useState({
    auxval: '', 
    articolo: {recid:null},
    data_present: false
  })

  return (
    <Dialog 
        header={"Aggiungi voci ausiliarie a " + props.cod_bolla + " " + props.num_bolla}
        visible={props.visible} onHide={props.onHide}
        modal
        footer={(
          <div>
            <Button 
              label="Aggiungi o aggiorna le voci" icon="pi pi-md-update" 
              onClick={(e) => props.onSave({cod_artic: state.articolo.recid, auxval: state.auxval})}
              disabled={!Boolean(state.articolo.recid)}/>
          </div>
        )}>
        <DiaRigaDBDropdown
                        id= "fld9"
                        label="Articolo servizio"
                        graphql={gql`
                        query articoliAux($cod_pers: Int, $cod_commessa: String) {
                          recordset: commesse_prodotti_trasporti_valide(
                              where: {
                                cod_commessa: {_eq: $cod_commessa},
                                classe: {_eq: "prodotto"},
                                cod_pers: {_eq: $cod_pers},
                                descrizion: {_ilike: "aux %"}}) {
                            recid: cod_artic
                            label: descrizion
                            labelext: unita
                          }
                        }`}
                        variables={{cod_pers: props.bolle?.cod_pers, cod_commessa: props.ddtData?.bolle_commesse_prodotti.cod_commessa}}
                        value={state.articolo}
                        onChange={(value) =>setState((prev) => ({...prev, articolo: value}))}
                        placeholder="articolo del servizio di consegna"
                        onDataPresent={(v) => setState((prev) => ({...prev, data_present: v}))}
        />
      <div className="row" hidden={! state.data_present} >
        <h5 className="d-flex align-items-end col">
          <label className='' htmlFor="itAux">Quantità:</label>
        </h5>
        <div className='col'>
          <InputText id="itAux" value={state.auxval} onChange={e => setState((prev) =>({...prev, auxval: e.target.value}))} type="number"/>
        </div>
      </div>
      <div className="row" hidden={state.data_present} >
        <div className='col'>
          Non ci sono articoli approvati
        </div>
      </div>
    </Dialog>
  )
}

function msgSaveAuxVal(response) {
  if (!response) return null;
  let {data, error, graphQLErrors, message} = response;
  if (typeof(response) === 'string') {
    return ({
      sticky: true,
      detail: 'errore: ' + response, 
      severity:'error'})

  }
  if (data) {
    return ({
      life: 15000,
      detail: 'quantità salvata', 
      severity:'success'})
  }
  if (error) {
    return ({
      sticky: true,
      detail: 'errore di salvataggio: ' + error.msg, 
      severity:'error'})
  }
  if (graphQLErrors) {
    return ({
      sticky: true,
      detail: 'errore generale: ' + message, 
      severity:'error'})
  }
}

function showAuxSalvateGrowl(printDDTGrowl, response) {
  const growl_opts = msgSaveAuxVal(response)
  printDDTGrowl.show(growl_opts)
}


const GQL_DATI_PER_PRODURRE_AUXVAL = gql`
      query dati_per_produrre_ore_trasporto($anno: Int!, $cod_bolla: bpchar!, $num_bolla: Int!, $data: timestamp!, $cod_pers: Int!, $cod_artic_servizio: Int!, $classe: String = "prodotto") {
        voci_servizio: bolle_voci(where: {anno: {_eq: $anno}, cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}, articolo: {descrizion: {_like: "aux %"}}}) {
          num_voce
          cod_artic
        }
        bolle_voci_aggregate(where: {anno: {_eq: $anno}, cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}}) {
          aggregate {
            max {
              num_voce
            }
          }
        }
        bolla: bolle_by_pk(anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla) {
          cod_pers
          bolle_vocis(where: {bolle_commesse_prodotti: {cod_commessa: {_gt: ""}}}) {
            bolle_commesse_prodotti {
              cod_commessa
              num_voce
            }
          }
        }
        commessa_prodotto_per_servizio: commesse_prodotti_trasporti_valide_data(where: {cod_pers: {_eq: $cod_pers}, data_fine: {_gte: $data}, classe: {_like: $classe}, cod_artic: {_eq: $cod_artic_servizio}}, order_by: [{cod_commessa: asc}, {classe: asc}, {descrizion: asc}], args: {data: $data}) {
          cod_commessa
          data_inizio
          cod_artic
          descrizion
          note
          unita
          classe
        }
      }
            
      `;
export async function saveAuxval(client, printDDTGrowl, state, aux_data, bolleoggi_refetch) {
  let {cod_artic: cod_artic_servizio, auxval} = aux_data;
  let {anno, cod_bolla, num_bolla, bolle: bolla_selezionata} = state.selectedDDT;
  let ddt_key = { anno, cod_bolla, num_bolla, auxval}
  let {cod_pers, data} = bolla_selezionata
  try {
    let dati = (await client.query({
      query: GQL_DATI_PER_PRODURRE_AUXVAL,
      variables: {...ddt_key, cod_pers, data, cod_artic_servizio}}
    ));
    const {voci_servizio, bolle_voci_aggregate} = dati.data
    const commessa_prodotto_per_servizio = dati.data.commessa_prodotto_per_servizio[0]
    if (commessa_prodotto_per_servizio == null)
      throw String(`Manca una commessa per il servizio ausiliario per il codice ${cod_artic_servizio}`)
    const {data_inizio, cod_commessa, unita} = commessa_prodotto_per_servizio
    const voce_corrente = voci_servizio.find(e => e.cod_artic === cod_artic_servizio)
    const num_voce = voce_corrente ? voce_corrente.num_voce : bolle_voci_aggregate.aggregate.max.num_voce +1
    if ((Number(auxval) !== 0) || (voci_servizio.length > 0)){
      let update_or_delete = auxval > 0 ?
        gql`
        mutation voce_trasporti_upsert($anno: Int!, $cod_artic_servizio: Int!, $cod_bolla: bpchar!, $lordo: numeric!, $num_bolla: Int!, $num_voce: smallint!, $cod_commessa: String!, $cod_pers: Int!, $data_inizio: timestamp!, $unita: bpchar = "h") {
          insert_bolle_voci_one(object: {anno: $anno, cod_bolla: $cod_bolla, cod_artic: $cod_artic_servizio, lordo: $lordo, num_bolla: $num_bolla, num_voce: $num_voce, tara: 0, unita: $unita}, on_conflict: {constraint: bolle_voci_pkey, update_columns: [cod_artic, lordo]}) {
            id
            unita
            lordo
            cod_artic
            articolo {
              descrizion
              gruppo
            }
          }
          insert_bolle_commesse_prodotti_one(object: {anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla, num_voce: $num_voce, cod_pers: $cod_pers, cod_commessa: $cod_commessa, cod_artic: $cod_artic_servizio, data_inizio: $data_inizio}, on_conflict: {constraint: bolle_voce, update_columns: [cod_commessa, cod_artic, data_inizio]}) {
            num_voce
          }
        }`
      : gql`
        mutation voce_trasporti_del($anno: Int!,  $cod_bolla: bpchar!, $num_bolla: Int!, $num_voce: smallint!) {
          delete_bolle_voci_by_pk(anno: $anno, cod_bolla: $cod_bolla, num_bolla: $num_bolla, num_voce: $num_voce) {
            id
            num_voce
          }
        }
      `
      let response = await client.mutate({
        mutation: update_or_delete,
        variables: {...ddt_key, num_voce, lordo: auxval, cod_artic_servizio, cod_pers, cod_commessa, data_inizio, unita}
      })

      await client.resetStore()
      await bolleoggi_refetch()
      showAuxSalvateGrowl(printDDTGrowl, response)
    }
  } catch(err) {
    console.log(err);
    showAuxSalvateGrowl(printDDTGrowl, err)
  }
}
