import { AUTH_AUTHTOKEN_KEY } from './loginproc';

function fileDownload(url) {
  const token = sessionStorage.getItem(AUTH_AUTHTOKEN_KEY);
  const options =  {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    }
  }
  let file_name;
  fetch(url, options)
    .then( res => {file_name = res.headers.get('Content-Disposition').match(/filename="([^"]+)"/)[1]; return res.blob()} )
    .then(blob => {
      let a = document.createElement("a") 
      let blobURL = URL.createObjectURL(blob)
      a.download = file_name
      a.href = blobURL
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  }

function saveTextToFile(text, file_name) {
  const blob = new Blob([text], {type:'text/plain', endings:'native'});
  let a = document.createElement("a") 
  let blobURL = URL.createObjectURL(blob)
  a.download = file_name
  a.href = blobURL
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export {fileDownload, saveTextToFile}