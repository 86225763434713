import {fileDownload} from '../../filedownload';
import React, { useContext, useState } from 'react';
import PrincipalsContext from '../../PrincipalsContext';


import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {ContextMenu} from 'primereact/contextmenu';

import { DialogSaveCommessa } from './DialogCommessa'
import { DialogSaveCommessaProdotto } from './DialogVociCommessaProdotto'
import { DialogSaveCommessaTrasporto } from './DialogVociCommessaTrasporto'


import { gql, useQuery } from '@apollo/client';

const FULLUSERS = ['dir', 'amminist'];

const FULL_QUERY_GQL = gql`
query registro_commesse(
    $limit: Int,
    $offset: Int,
    $flt_cod_commessa: String,
    $flt_data_fine_max: timestamp,
    $flt_data_fine_min: timestamp,
    $flt_data_inizio_max: timestamp,
    $flt_data_inizio_min: timestamp,
    $flt_descrizion: String,
    $flt_rag_soc: String,
    $flt_unita: String,
    $flt_data_contenuta: timestamp,
    $order_by: [commesse_prodotti_trasporti_order_by!] = [
      {cod_commessa: asc},
      {data_fine: asc},
      {costo_unit: asc},
      {data_inizio: asc},
      {descrizion: asc},
      {rag_soc: asc},
      {unita: asc}]) {
  aggregate: commessegroup_aggregate(args: {flt_cod_commessa: $flt_cod_commessa, flt_data_fine_max: $flt_data_fine_max, flt_data_fine_min: $flt_data_fine_min, flt_data_inizio_max: $flt_data_inizio_max, flt_data_inizio_min: $flt_data_inizio_min, flt_descrizion: $flt_descrizion, flt_rag_soc: $flt_rag_soc, flt_unita: $flt_unita, flt_data_contenuta: $flt_data_contenuta}) {
    aggregate {
      count
    }
  }
  tabella: commessegroup(order_by: $order_by, limit: $limit, offset: $offset, args: {flt_cod_commessa: $flt_cod_commessa, flt_data_fine_max: $flt_data_fine_max, flt_data_fine_min: $flt_data_fine_min, flt_data_inizio_max: $flt_data_inizio_max, flt_data_inizio_min: $flt_data_inizio_min, flt_descrizion: $flt_descrizion, flt_rag_soc: $flt_rag_soc, flt_unita: $flt_unita, flt_data_contenuta: $flt_data_contenuta}) {
    cod_pers
    cod_commessa
    note
    costo_unit
    data_fine
    data_inizio
    descrizion
    cod_artic
    rag_soc
    unita
    classe
  }
}
`

const QUERY_GQL = gql`
query registro_commesse(
    $limit: Int,
    $offset: Int,
    $flt_cod_commessa: String,
    $flt_data_fine_max: timestamp,
    $flt_data_fine_min: timestamp,
    $flt_data_inizio_max: timestamp,
    $flt_data_inizio_min: timestamp,
    $flt_descrizion: String,
    $flt_rag_soc: String,
    $flt_unita: String,
    $flt_data_contenuta: timestamp,
    $order_by: [commesse_prodotti_trasporti_order_by!] = [
      {cod_commessa: asc},
      {data_fine: asc},
      {data_inizio: asc},
      {descrizion: asc},
      {rag_soc: asc},
      {unita: asc}]) {
  aggregate: commessegroup_aggregate(args: {flt_cod_commessa: $flt_cod_commessa, flt_data_fine_max: $flt_data_fine_max, flt_data_fine_min: $flt_data_fine_min, flt_data_inizio_max: $flt_data_inizio_max, flt_data_inizio_min: $flt_data_inizio_min, flt_descrizion: $flt_descrizion, flt_rag_soc: $flt_rag_soc, flt_unita: $flt_unita, flt_data_contenuta: $flt_data_contenuta}) {
    aggregate {
      count
    }
  }
  tabella: commessegroup(order_by: $order_by, limit: $limit, offset: $offset, args: {flt_cod_commessa: $flt_cod_commessa, flt_data_fine_max: $flt_data_fine_max, flt_data_fine_min: $flt_data_fine_min, flt_data_inizio_max: $flt_data_inizio_max, flt_data_inizio_min: $flt_data_inizio_min, flt_descrizion: $flt_descrizion, flt_rag_soc: $flt_rag_soc, flt_unita: $flt_unita, flt_data_contenuta: $flt_data_contenuta}) {
    cod_pers
    cod_commessa
    note
    data_fine
    data_inizio
    descrizion
    cod_artic
    rag_soc
    unita
    classe
  }
}
`
const calendarOptions = {locale: "it", showIcon: true, showOnFocus: false};

/* Calendario filtro */
function RenderDateFilter(fldNamePrefix, setFilterSet, filterset, setPaginator) {
  const setOggi = (field, value) => {
    setFilterSet({...filterset, [field]:{value: value}})
    setPaginator(prev => ({...prev, first: 0}))
  }
  return (
        <>
          <Calendar placeholder="Data Iniziale" value={filterset[fldNamePrefix + "_min"].value} onChange={({value}) =>{setOggi(fldNamePrefix + "_min", value)}} {...calendarOptions} /><br />
          <Calendar placeholder="Data Finale" value={filterset[fldNamePrefix + "_max"].value} onChange={({value}) =>{setOggi(fldNamePrefix + "_max", value)}} {...calendarOptions} />
        </>)
}


function TableHeader(setPaginator, variables, cntSelezione, filterset, setFilterSet) {
  const setOggi = (field, value) => {
    setFilterSet({...filterset, [field]:{value: value}})
    setPaginator(prev => ({...prev, first: 0}))
  }
  return (
    <div className='row text-center'>
      <div className="col">
        <div className="row">
          <div className="col">
            <Calendar
              tooltip="Mostra le voci che hanno data inizio e fine comprendente il giorno selezionato. Mostra tutte le voci cancellando il campo"
              placeholder="Valide il giorno" value={filterset["data_contenuta"].value} onChange={({value}) =>{setOggi("data_contenuta", value)}} {...calendarOptions}/>
          </div>
        </div>
      </div>
      <h2 className="col">
          Registro delle voci di commessa
      </h2>
      <div className="col">
        {false ? <Button disabled={false && (cntSelezione < 1)} label="Scarica foglio Excel" onClick={() => fileDownload("/a/ddtxls?q=" + JSON.stringify(variables))}/> : null}
      </div>
    </div>
  )
}

function DataTypeTemplate(rowData, column) {
  return <><span>{rowData[column.field] ? Intl.DateTimeFormat(navigator.languages, {dateStyle: "short"}).format(new Date(rowData[column.field])) : '---'}</span></>
}


export function CommesseRegistro() {
  const {allowedRoles} = useContext(PrincipalsContext)
  const [ paginator, setPaginator ] = useState({ rows: 20, first: 0 })
  const [ filterset, setFilterSet ] = useState({
    rag_soc:{},cod_commessa:{},descrizion:{},unita:{},
    data_inizio_min:{}, data_inizio_max:{},
    data_fine_min:{}, data_fine_max:{}, data_contenuta:{}})
  const [ rowselection, setRowselection ] = useState({value: null})
  const [ multisort, setMultisort ] = useState({
    sortField:"data_fine",sortOrder:1,
    multiSortMeta:[]})
    // multiSortMeta:[{field:"rag_soc",order:1},{field:"cod_commessa",order:1},{field:"descrizion",order:1},{field:"data_inizio",order:1},{field:"data_fine",order:1}]})
  const [ dialog_save_commessa, setDialogSaveCommessa] = useState({visible: false, newRecord: false})
  const [ dialog_save_commessa_prodotto, setDialogSaveCommessaProdotto] = useState({visible: false, newRecord: false, presetForm: false})
  const [ dialog_save_commessa_trasporto, setDialogSaveCommessaTrasporto] = useState({visible: false, newRecord: false, presetForm: false})

  let full_user = allowedRoles && FULLUSERS.some((value) => allowedRoles.includes(value))

  let contextmenu =full_user ? [
    {
      label: "Commesse",
      icon: 'pi pi-credit-card',
      items: [
        {
          label: 'Nuova Commessa', 
          icon: 'pi pi-plus',
          command: (event) => setDialogSaveCommessa({visible: true, newRecord: true})
        },
        {
          label: 'Modifica Commessa', 
          icon: 'pi pi-file-edit',
          command: (event) => setDialogSaveCommessa({visible: true, newRecord: false})
        }
      ]
    },
    { 
      label: "Prodotti",
      icon: "pi pi-image",
      items: [
        {
          label: 'Nuova Voce Prodotto', 
          icon: 'pi pi-plus',
          command: (event) => setDialogSaveCommessaProdotto({visible: true, newRecord: true, presetForm: false})
        },
        {
          label: 'Nuova Voce Simile', 
          icon: 'pi pi-clone',
          command: (event) => setDialogSaveCommessaProdotto({visible: true, newRecord: true, presetForm: true}),
          disabled: !(rowselection.value && (rowselection.value.classe === 'prodotto'))
        },
        {
          label: 'Modifica Voce Prodotto', 
          icon: 'pi pi-file-edit',
          command: (event) => setDialogSaveCommessaProdotto({visible: true, newRecord: false, presetForm: false}),
          disabled: !(rowselection.value && (rowselection.value.classe === 'prodotto'))
        }
      ]
    },
    { 
      label: "Trasporti",
      icon: "pi pi-truck",
      items: [
        {
          label: 'Nuova Voce Trasporto', 
          icon: 'pi pi-plus',
          command: (event) => setDialogSaveCommessaTrasporto({visible: true, newRecord: true, presetForm: false})
        },
        {
          label: 'Nuova Voce Simile', 
          icon: 'pi pi-clone',
          command: (event) => setDialogSaveCommessaTrasporto({visible: true, newRecord: true, presetForm: true}),
          disabled: !(rowselection.value && (rowselection.value.classe === 'trasporto'))
        },
        {
          label: 'Modifica Voce Trasporto', 
          icon: 'pi pi-file-edit',
          command: (event) => setDialogSaveCommessaTrasporto({visible: true, newRecord: false, presetForm: false}),
          disabled: !(rowselection.value && (rowselection.value.classe === 'trasporto'))
        }
      ]
    }
  ] : [];
  
  let contextmenu_element;
  let variables = {
    offset: paginator.first,
    limit: paginator.rows,
    ...Object.fromEntries(Object.keys(filterset).map(k => ['flt_' + k, filterset[k].value]).filter(v => v ? true: false))
  }
  
  if (multisort.multiSortMeta.length)
    variables.order_by = [...multisort.multiSortMeta].map(v => ({[v.field]: ({"-1":'desc', "1":'asc'})[v.order]}))
  else
    variables.order_by = [{rag_soc: "asc"},{cod_commessa: "asc"},{descrizion: "asc"},{data_inizio: "asc"},{data_fine: "asc"}]
  
  const { loading, data, refetch } = useQuery(
    full_user ? FULL_QUERY_GQL : QUERY_GQL,
    { variables: variables, fetchPolicy: "cache-and-network" });

  let {aggregate: {aggregate: {count: cntSelezione}}, tabella} = data ? data : {aggregate: {aggregate:{count: 0}}, tabella: []}
  let admitSortable = true
  return (
      <div className="card shadow">
        <div className="card-body">
          <DialogSaveCommessa
            onHide={() => setDialogSaveCommessa({visible: false, newRecord: false})}
            initRecord={rowselection && rowselection.value}
            refetch={refetch}
            {...dialog_save_commessa}/>
          <DialogSaveCommessaProdotto
            onHide={() => setDialogSaveCommessaProdotto({visible: false, newRecord: false})} 
            initRecord={rowselection.value}
            refetch={refetch}
            {...dialog_save_commessa_prodotto}/>
          <DialogSaveCommessaTrasporto
            onHide={() => setDialogSaveCommessaTrasporto({visible: false, newRecord: false})} 
            initRecord={rowselection.value}
            refetch={refetch}
            {...dialog_save_commessa_trasporto}/>
          <ContextMenu model={contextmenu} ref={el => contextmenu_element = el}/>

          <DataTable
            tableStyle={{minHeight: "35em"}} 
            header={TableHeader(setPaginator, variables, cntSelezione, filterset, setFilterSet)}
            reorderableColumns

            value={tabella} lazy={true}
            emptyMessage="nessun dato"
            loading={loading}
            onFilter={(e) => {
              setFilterSet(e.filters)
              setPaginator({...paginator, first: 0})
            }}
            filters={filterset}
            filterDisplay='row'

            sortMode="multiple"
            removableSort={true}
            onSort={(e) => {
              setMultisort(e)
              setPaginator({...paginator, first: 0})
            }}
            multiSortMeta={multisort.multiSortMeta}

            selectionMode="single"
            selection={rowselection}
            onSelectionChange={setRowselection}

            contextMenu={contextmenu_element}
            contextMenuSelection={rowselection}
            onContextMenuSelectionChange={setRowselection}
            onContextMenu={e => contextmenu_element.show(e.originalEvent)}

            paginator={true}
            rows={paginator.rows > 10 ? paginator.rows : 10}
            first={paginator.first}
            totalRecords={cntSelezione} 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 20, 30]}
            paginatorRight={<div><Button label="Nuova commessa" icon="pi pi-md-create-new-folder" onClick={() => setDialogSaveCommessa({visible: true, newRecord: true})}/></div>}
            
            onPage={({rows, first}) => setPaginator({rows, first})}
          >
              <Column showFilterMenu={false} showClearButton={false} field="rag_soc" header="Cliente" filterPlaceholder="Cliente" filter={true} sortable={admitSortable}/>
              <Column showFilterMenu={false} showClearButton={false} field="cod_commessa" header="Codice Commessa" filterPlaceholder="Codice Commessa" filter={true} sortable={admitSortable}/>
              <Column showFilterMenu={false} showClearButton={false} field="note" header="Note"/>
              {full_user ? <Column showFilterMenu={false} showClearButton={false} field="costo_unit" header="Prezzo" filter={false} sortable={admitSortable}/> : null}
              <Column showFilterMenu={false} showClearButton={false} field="descrizion" header="Articolo" filterPlaceholder="Articolo" filter={true} sortable={admitSortable}/>
              <Column showFilterMenu={false} showClearButton={false} field="unita" header="Unita" filterPlaceholder="Unita" filter={true} sortable={admitSortable}/>
              <Column showFilterMenu={false} showClearButton={false} field="data_inizio" header="Data inizio" filterElement={RenderDateFilter("data_inizio", setFilterSet, filterset, setPaginator)} filter={true} sortable={admitSortable} body={DataTypeTemplate}/>
              <Column showFilterMenu={false} showClearButton={false} field="data_fine" header="Data fine" filterElement={RenderDateFilter("data_fine", setFilterSet, filterset, setPaginator)} filter={true} sortable={admitSortable} body={DataTypeTemplate}/>
          </DataTable>
        </div>
      </div>
  )
}



