import React, { useState } from 'react';


import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ContextMenu} from 'primereact/contextmenu';


import { gql, useQuery } from '@apollo/client';

import { DialogSave } from './DialogSave'

const QUERY_GQL = gql`
query destinazioni($flt_destinazione: String, $flt_id_dest: Int = -1, $order_by: [destinazioni_order_by!] = {destinazione: asc}, $limit: Int!, $offset: Int = 0) {
  aggregate: destinazioni_aggregate(where: {_or: [{destinazione: {_ilike: $flt_destinazione}}, {id_dest: {_eq: $flt_id_dest}}]}) {
    aggregate {
      count
    }
  }
  tabella: destinazioni(limit: $limit, offset: $offset, where: {_or: [{destinazione: {_ilike: $flt_destinazione}}, {id_dest: {_eq: $flt_id_dest}}]}, order_by: $order_by) {
    destinazione
    id_dest
  }
}
`

export function DestinazioniRegistro() {
  const [ paginator, setPaginator ] = useState({ rows: 20, first: 0 })
  const [ filterset, setFilterSet ] = useState({ destinazione:{}, id_dest:{}})
  const [ rowselection, setRowselection ] = useState({value: null})
  const [ multisort, setMultisort ] = useState({
    sortField:"destinazione",sortOrder:1,
    multiSortMeta:[]})
  const [ dialog_save_destinazione, setDialogSaveDestinazione] = useState({visible: false, newRecord: false})

  let contextmenu = [
    {
      label: "Destinazione",
      icon: 'pi pi-folder-open',
      items: [
        {
          label: 'Nuova Destinazione', 
          icon: 'pi pi-plus',
          command: (event) => setDialogSaveDestinazione({visible: true, newRecord: true})
        },
        {
          label: 'Modifica Destinazione', 
          icon: 'pi pi-file-edit',
          command: (event) => setDialogSaveDestinazione({visible: true, newRecord: false})
        }
      ]
    },
  ];

  let contextmenu_element;
  let variables = {
    offset: paginator.first,
    limit: paginator.rows,
    ...Object.fromEntries(Object.keys(filterset).map(k => ['flt_' + k, filterset[k].value || null]).filter(v => Boolean(v[1])))
  }

  if (variables.flt_id_dest)
    variables.flt_destinazione = ''
  else
    variables.flt_destinazione = variables.flt_destinazione ? variables.flt_destinazione + '%' : undefined

  if (multisort.multiSortMeta.length)
    variables.order_by = [...multisort.multiSortMeta].map(v => ({[v.field]: ({"-1":'desc', "1":'asc'})[v.order]}))
  else
    variables.order_by = [{destinazione: "asc"},{id_dest: "asc"}]
  
  const { loading, data, refetch } = useQuery(
    QUERY_GQL,
    { variables: variables, fetchPolicy: "cache-and-network" });

  let {aggregate: {aggregate: {count: cntSelezione}}, tabella} = data ? data : {aggregate: {aggregate:{count: 0}}, tabella: []}
  let admitSortable = true
  return (
    <div className="card shadow">
      <div className='card-body'>
          <h2 className='card-title'>Destinazioni</h2>
          <DialogSave
            onHide={() => setDialogSaveDestinazione({visible: false, newRecord: false})}
            initRecord={rowselection && rowselection.value}
            refetch={refetch}
            {...dialog_save_destinazione}/>
          <ContextMenu model={contextmenu} ref={el => contextmenu_element = el}/>

          <DataTable
            responsive="true" tableStyle={{minHeight: "35em"}}
            reorderableColumns

            size="small"
            value={tabella} lazy={true}
            emptyMessage="nessun dato"
            loading={loading}
            onFilter={(e) => {
              setFilterSet(e.filters)
              setPaginator({...paginator, first: 0})
            }}
            filters={filterset}
            filterDisplay='row'

            sortMode="multiple"
            removableSort={true}
            onSort={(e) => {
              setMultisort(e)
              setPaginator({...paginator, first: 0})
            }}
            multiSortMeta={multisort.multiSortMeta}

            paginator={true}
            rows={paginator.rows > 10 ? paginator.rows : 10}
            first={paginator.first}
            totalRecords={cntSelezione} 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 20, 30]}
            paginatorRight={<div><Button label="Nuova destinazione" icon="pi pi-md-create-new-folder" onClick={() => setDialogSaveDestinazione({visible: true, newRecord: true})}/></div>}
            onPage={(event) => {
              const {rows, first} = event
              setPaginator(event)
              refetch({variables: {first: first, rows: rows}})
            }}
      
            selectionMode="single"
            selection={rowselection}
            onSelectionChange={setRowselection}

            contextMenuSelection={rowselection}
            onContextMenuSelectionChange={setRowselection}
            onContextMenu={e => contextmenu_element.show(e.originalEvent)}
          >
              <Column showFilterMenu={false} showClearButton={false} field="destinazione" header="Destinazione" filterPlaceholder="Destinazione" filter={!filterset?.id_dest?.value} sortable={admitSortable}/>
              <Column showFilterMenu={false} showClearButton={false} field="id_dest" header="Codice Destinazione" filterPlaceholder="Codice Destinazione" filter={true} sortable={admitSortable}/>
          </DataTable>
        </div>
    </div>
  )
}
