/****** Dropdown con GraphQL
***
*/

/* I valori in uscita sono nella forma {recid, label} detta normalized_value*/
/* In entrata graphql che genera coppie {recid, label [, labelext [,...]] } */
/* props deve contenere value nella forma {recid, label} */

/* localmente salva il valore proveniente da recordset ma invia al container {recid, label} */
/* il componente è controlled, dunque il value in ingresso va rimappato ad un valore del recordset */

import React from 'react';
import { useQuery } from '@apollo/client'
import { DiaRigaDropdown, RigaDropdown } from './Dropdown'

function removeProps(props) {
    const {onDataPresent, ...newProps} = props
    return newProps
}

/* da una normalized_value ottiene il corrispondente valore di recordset */
const remapValueProp = (recordset, normalized_value) => {
    return (
        (recordset && recordset.find((elm) => elm.recid === normalized_value.recid))
        || {recid: null, label: null})
}

const buildProps = (props, data) => ({
        ...removeProps(props),
        value: remapValueProp(data.recordset, props.value),
        onChange: (e) => props.onChange({recid: e.value.recid, label: e.value.label}),
        options: data.recordset
    })


export function DiaRigaDBDropdown(props) {
    const { data } = useQuery(
        props.graphql, 
        {
            variables: props.variables, 
            onCompleted: (data) => (Boolean(props?.onDataPresent && props.onDataPresent(data?.recordset?.length > 0)))
        })
    if (data && data.recordset && data.recordset.length > 0) {
        return (
        <DiaRigaDropdown {...buildProps(props, data)}   />)
    }
    return null
}

export function RigaDBDropdown(props) {
    const { data } = useQuery(
        props.graphql, 
        {
            variables: props.variables, 
            onCompleted: (data) => (Boolean(props?.onDataPresent && props.onDataPresent(data?.recordset?.length > 0)))
        })
    if (data && data.recordset && data.recordset.length > 0) {
        return (
        <RigaDropdown {...buildProps(props, data)}   />)
    }
    return null
}
