/****** Autocomplete con sorgente GraphQL
 ***
 */

import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useQuery } from '@apollo/client';
import { Riga, DiaRiga } from './Riga'

// La query graphql deve contenere una variabile qry e rispondere un array denominato recordset 
const mapSuggestions = (loading, error, data, defaultSuggestions) => (
    // AutoComplete non ammette null e []
    loading ? [''] : 
    error ? [{label: 'errore di rete'}] :
    (data && data.recordset.length > 0) ? data.recordset:
    defaultSuggestions
)

function localOnBlur(value, suggestions, onChange, onBlur) {
    if (typeof(value) === "string"
        && value
        && suggestions 
        && (suggestions.length === 1) 
        && suggestions[0].label.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())) {
        onChange(suggestions[0])
    }
    if (onBlur) onBlur()

}

const itemTemplate = (elm) => (
    <React.Fragment>
        <span>{elm.label}</span> <span style={{fontSize:'x-small'}}>{elm.labelext}</span>
    </React.Fragment>)

function DBAutocomplete(props) {
    const _value = props.value.label || props.value
    const [qry, setQry] = useState(
        {query: _value})
    const { loading, error, data } = useQuery(
        props.graphql,
        {variables: { qry: qry.query + (qry.query === '' ? '': '%') }})
    const suggestions = mapSuggestions(loading, error, data, props.defaultSuggestions)
    
    return (
        <AutoComplete
            disabled={props.disabled}
            dropdown={props.dropdown}
            dropdownMode={"current"}
            value={_value}
            onChange={(e) => props.onChange(e.value)}
            onBlur={() => localOnBlur(_value, suggestions, props.onChange, props.onBlur, props)}
            onFocus={() => props.onFocus && props.onFocus(props.placeholder)}

            completeMethod={setQry}
            field="label"
            suggestions={suggestions}
            itemTemplate={itemTemplate}
            inputClassName={props.inputClassName}
            minLength={1}

            style={{width:"100%"}}
            pt={{
                input: {
                    root: {
                        style:{width:"100%"}
                    },
                }
            }}
        />
    )
}





export const DiaRigaDBAutocomplete = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <DBAutocomplete {...props} />
    </DiaRiga>
)

export const RigaDBAutocomplete = (props) => (
    <Riga id={props.id} label={props.label}  showif={props.showif}>
        <DBAutocomplete {...props} />
    </Riga>
)

