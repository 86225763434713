// Componente per sistema ExeDialog. Salva i dati con una mutation
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const GQL_MUTATION = gql`
mutation AddVoce($up_matricola: String!, $cnt_matricola: String!, $data: timestamptz!, $cfonte: String!, $ccosto: String!, $quantita: numeric!) {
    result: insert_costi2009_registro(objects: {up_matricola: $up_matricola, cnt_matricola: $cnt_matricola, data: $data, cfonte: $cfonte, ccosto: $ccosto, quantita: $quantita}) {
        affected_rows
    }
}
`
export function AddVoceSave(props) {
    const navigate = useNavigate()
    const {currURLSearchParams} = props;
    const [addVoce, { loading, error, data }] = useMutation(GQL_MUTATION);
    if (loading) return 'Loading...';
    if (error) return (
        <div className="card-body">
            <h5 className="card-title">Errore</h5>
            {error?.graphQLErrors[0].extensions?.code === "constraint-violation" ? "Dati già presenti per il contatore a quell'ora" : error.message}
        </div>
        )
    const save = async () => {
        try {
            await addVoce({
                variables: {
                    up_matricola: currURLSearchParams.get('up_matricola'), 
                    cnt_matricola: currURLSearchParams.get('cnt_matricola'),
                    data: currURLSearchParams.get('data'), 
                    cfonte: currURLSearchParams.get('cfonte'), 
                    ccosto: currURLSearchParams.get('ccosto'), 
                    quantita: currURLSearchParams.get('quantita')}
                })
            navigate('/c2009registro')
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="card-body">
            <h5 className="card-title">Dati da salvare (per modificare torna indietro alle pagine precedenti)</h5>
            <form onSubmit={ e => {e.preventDefault(); save()}}>
                <div className="mb-3">  
                    <label htmlFor="up_matricola" className="form-label">Unità produttiva</label>
                    <input type="text" readOnly className="form-control" id="up_matricola" value={currURLSearchParams.get('up_matricola')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="cnt_matricola" className="form-label">Contatore</label>
                    <input type="text" readOnly className="form-control" id="cnt_matricola" value={currURLSearchParams.get('cnt_matricola')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="data" className="form-label">Data</label>
                    <input type="text" readOnly className="form-control" id="data" value={(new Date(currURLSearchParams.get('data'))).toLocaleString()} />
                </div>
                <div className="mb-3">
                    <label htmlFor="cfonte" className="form-label">Centro di prelievo</label>
                    <input type="text" readOnly className="form-control" id="cfonte" value={currURLSearchParams.get('cfonte')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="ccosto" className="form-label">Centro di costo</label>
                    <input type="text" readOnly className="form-control" id="ccosto" value={currURLSearchParams.get('ccosto')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="quantita" className="form-label">Quantità</label>
                    <input type="text" readOnly className="form-control" id="quantita" value={currURLSearchParams.get('quantita')} />
                </div>
                <button className="btn btn-primary" disabled={Boolean(data?.result)}>{Boolean(data?.result) ? "Salvato" : "Salva"}</button>
            </form>
        </div>
    )
}

AddVoceSave.displayName = 'AddVoceSave';