/****** Il ritorno di Dialog
 *  next.js promuove un routing basato su cartelle 
 * e il passaggio di parametri  usando parte query di URL (search)
 * 
*/
import { useSearchParams } from "react-router-dom";

const End = () => <div>End. Tutti sono presenti</div>;

function queryUrlVariableAsComponentNameArray(queryUrlVariables) {
    return queryUrlVariables.map(queryUrlVariable => 
        queryUrlVariable
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(""));
}

function findMissingComponent(queryUrlVariables, reactComponentList) {
    const queryUrlVariableAsComponentNames = queryUrlVariableAsComponentNameArray(queryUrlVariables);
    const missingComponent = reactComponentList.find(reactComponent => !queryUrlVariableAsComponentNames.includes(reactComponent.displayName));
    return missingComponent;
}


function updateQueryVariables(componentList, currentComponent, currentQueryVariablesURLSearchParams, newQueryVariablesObj) {
    const currentIndex = componentList.findIndex(component => component.name === currentComponent.name);
    const invalidComponents = componentList.slice(currentIndex);
    const invalidComponentNames = invalidComponents.map(component => component.name);
    const currentQueryVariablesKeys = Array.from(currentQueryVariablesURLSearchParams.keys());
    const updatedQueryVariables = new URLSearchParams(currentQueryVariablesURLSearchParams);

    currentQueryVariablesKeys.forEach(variable => {
        const componentName = queryUrlVariableAsComponentNameArray([variable])[0];
        if (invalidComponentNames.includes(componentName) && componentList.some(component => component.name === componentName)) {
            updatedQueryVariables.delete(variable);
        }
    });

    Object.keys(newQueryVariablesObj).forEach(key => {
        updatedQueryVariables.set(key, newQueryVariablesObj[key]);
    });

    return updatedQueryVariables;
}

function changeLocation(setSearchParams, reactComponentList, MissingComponent, queryParams, queryVariables) {
    let updatedQueryVariables = updateQueryVariables(reactComponentList, MissingComponent, queryParams, queryVariables);
    setSearchParams(updatedQueryVariables)
}

export function ExeDialog({ reactComponentList }) {
    const [queryParams, setSearchParams] = useSearchParams();
    
    const queryUrlVariables = Array.from(queryParams.keys());
    const MissingComponent = findMissingComponent(queryUrlVariables, reactComponentList);
    return MissingComponent ? <MissingComponent currURLSearchParams={queryParams} onFinish={changeLocation.bind(this, setSearchParams, reactComponentList, MissingComponent, queryParams)} /> : <End />;
}