import React, { useEffect, useReducer } from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import { formReducerInit, formReducer } from './ddtRedux'
import { gql } from '@apollo/client'
import useQueryRedux from './ddtQueryRedux'
import useMutationRedux from './ddtMutationRedux'
import {Calendar} from 'primereact/calendar';
import { Riga } from '../WIDGETS/Riga'
import { RigaDropdown } from '../WIDGETS/Dropdown'
import { RigaDBAutocomplete } from '../WIDGETS/DBAutocomplete'
import { RigaInputText, WidgetInputText, inputTextFloatRecid } from '../WIDGETS2/InputText'
import { RigaCheckbox } from '../WIDGETS/Checkbox'
import {InputMask} from 'primereact/inputmask';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import {Dialog} from 'primereact/dialog';


/******* Componenti
 ***
 */

function CodBolla(props) {
  const navigate = useNavigate()
  const {codBollaList, codBolla} = props.redux.state
  return (
    <RigaDropdown
      label="Codice bolla"
      options={codBollaList} value={codBolla}
      onChange={(v) => navigate('../' + v.value.label, {relative:"path"})}
    />
  )
}

/*** in props: redux:{dispatch, state}, fldName */
function GenericTextField(props) {
  let {redux: {dispatch, state}, fldName, label, placeholder, validif, required, validOnVoidString, validOnZero, type} = props

  return <RigaInputText
    id={`fld${label}`}
    label={label}
    value={state[fldName]}
    validif={validif}
    validOnVoidString={validOnVoidString}
    validOnZero={validOnZero}
    required={required}
    type={type}
    onChange={(value) => dispatch({type: `set_fld`, fldName, value})}
    onFocus={() => dispatch({type: `set_currFocus`, fldName})}
    placeholder={placeholder}/>
}

function GenericQueryField(props){
  let {redux: {dispatch, state}, fldName, label, graphql, placeholder} = props
  return (
    <RigaDBAutocomplete
      id={`fld${label}`}
      inputClassName={state[fldName].recid ? "":"p-error"}
      label={label}
      graphql={graphql}
      defaultSuggestions={[{label:"nulla"}]}
      value={state[fldName].label}
      onChange={(value) => dispatch({type: `set_fld`, fldName, value: value.recid ? value : {label: value}})}
      onFocus={() => dispatch({type: `set_currFocus`, fldName})}
      placeholder={placeholder}/>
  )
}

function GenericInputMaskGroup(props) {
  let {redux: {dispatch, state}, fldName, label, mask, groupString, placeholder, showif} = props
  return (
  <Riga label={label} id={`fld${label}`} showif={showif}>
    <div className="p-inputgroup">
      <InputMask id={`fld${label}`} value={state[fldName].label} placeholder={placeholder}
        mask={mask}
        onChange={({value}) => dispatch({type: 'set_fld', fldName, value: {label: value, recid: value}})}/>
      <span className="p-inputgroup-addon">{groupString}</span>
    </div>
  </Riga>)
}


function WebCam(props) {
  // vuole target, showif
  let {redux: {state}} = props
  let { recid } = state.codBolla
  if (!( recid?.metadati?.camUrl))
    return null
  const { camUrl } = recid.metadati
  const camIndex = {
    cabina: 3,
    cassone: 1,
    targa: 2
  }[props.target];

  return props.showif ? (
    <div className='card-body'>
    <img alt="webcam" className='img-fluid'
        src={`https://app.ghiaia.net${camUrl}${camIndex}.jpg?size=3&quality=4&t=${state.bilanciaQueryTime}`}/>
     </div> ) : null
}


function CodPers(props) {
  let {redux, fldName, label, showif} = props
  return showif === false ? null : (
    <GenericQueryField redux={redux} fldName={fldName} label={label}
      graphql={gql`
        query codPers($qry: String = "") {
          recordset: anagrafe_rag_soc(where: {rag_soc: {_ilike: $qry}, pesa: {_eq: true}}, limit: 50, order_by: {rag_soc: asc}) {
              recid: cod_pers
              label: rag_soc
              id: cod_pers
          }
        }`}
      placeholder="anagrafica"/>
  )
}

function Targa(props) {
  let {redux} = props
  return (
    <GenericQueryField redux={redux} fldName={"targa"} label={"Targa"}
      graphql={gql`
        query veicolo($qry: String = "") {
          recordset: veicoli(where: {targa: {_ilike: $qry}}, limit: 50, order_by: {targa: asc}) {
              recid: targa
              label: targa
              tara
              mittente
              conducente: anagrafe {
                recid: cod_pers
                label: rag_soc
                id: cod_pers
              }
          }
        }`}
      placeholder="targa"/>
  )
}


function RigaFocus(props) {
  let {redux: {state, dispatch}, label, fldName} = props
  return  (
    <Riga label={label} id={`${label}rigafocus`}>
      <div
        onClick={() => dispatch({type: 'set_currFocus', fldName: fldName})}
        style={state[fldName].recid ? {} : {
          borderColor: "red",
          borderBottomStyle: "solid",
          borderBottomWidth: "thin"}}>
          {state[fldName].recid ? state[fldName].label : "non definito"}
      </div>
    </Riga>)
}


function Destinazione(props) {
  let {redux} = props
  return redux.state.trasportoCaricoNostro ? 
    (<Riga label="Destinazione da commessa">
      <div onClick={() => redux.dispatch({type: 'set_currFocus', fldName: 'id_dest'})}>
        {redux.state.id_dest.label}
      </div>
    </Riga>) : (
    <GenericQueryField redux={redux} fldName={"id_dest"}  label={"Destinazione libera"}
      graphql={gql`
        query destinazione($qry: String = "") {
          recordset: destinazioni(where: {destinazione: {_ilike: $qry}}, limit: 50, order_by: {destinazione: asc}) {
              recid: id_dest
              label: destinazione
          }
        }`}
      placeholder="destinazione"/>
  )
}

function Lordo(props) {
  let {redux: {dispatch, state}} = props
  const label = "Quantità lorda"
  const placeholder = label
  const fldName = "lordo"

  return (
  <Riga label={label} id={`fld${label}`} >
    <div className="p-inputgroup">
      <WidgetInputText
        id={`fld${label}`}
        label={label}
        value={state[fldName]}
        validif={(v) => inputTextFloatRecid(v).recid >= state.tara.recid}
        required
        type="number"
        onChange={(value) => dispatch({type: `set_lordo`, value})}
        onFocus={() => dispatch({type: `set_currFocus`, fldName})}
        placeholder={placeholder}/>
      <span className="p-inputgroup-addon">{state.unita.label}</span>
      <Button 
        icon="pi pi-caret-left" 
        onClick={() => {
          dispatch({
            type:'set_lordo_da_bilancia',
            value: null,
          })
        }} />

      <span>
        <Dropdown 
            value={state.currPeso}
            options={state.bilancia}
            dataKey="data"
            optionLabel="label"
            itemTemplate={(option) => (
              <div className="p-clearfix">
                {option.peso / 10}T <span style={{fontSize:'smaller'}}>{moment(option.data).format("HH:mm:ss")}</span>
              </div>)}
            onChange={e => {
              dispatch({
                type:'set_lordo_da_bilancia',
                value: e.value,
              })
            }}
            style={{width: '18vw'}}
            />
      </span>
    </div>
  </Riga>)
}

function AnagrafeAlt(props) {
  const { state, dispatch } = props.redux
  return <DataTable selectionMode="single" header="Destinatari recenti"
      value={state.ragSocAlt} selection={state.ragSoc}
      onRowClick={({data}) => dispatch({type: "set_fld_by_ragSocAlt", value: data})} >
      <Column field="label" header="Destinatario" sortable={true}/>
    </DataTable>
}

function VeicoliAlt(props) {
  const { state, dispatch } = props.redux
  return <DataTable selectionMode="single" header="Veicoli e conducenti recenti"
      value={state.veicoliAlt} selection={state.veicoliAltSelection}
      filterDisplay='row'
      onRowClick={
        ({data}) => dispatch({type: "set_fld_by_veicoliAlt", value: data})}>
      <Column showFilterMenu={false} showClearButton={true}field="targa" header="Targa" sortable={true} filter={true}/>
      <Column showFilterMenu={false} showClearButton={false}field="veicoli.tara" header="Tara" sortable={true}/>
      <Column showFilterMenu={false} showClearButton={false}field="anagconducente.label" header="Conducente" sortable={true}/>
      <Column showFilterMenu={false} showClearButton={false} field="anagvettore.label" header="Vettore" sortable={true}/>
      <Column showFilterMenu={false} showClearButton={false}field="vett_ns" header="Trasp nostro carico" body={
        (rowData, column) => rowData.veicoli.mittente || rowData.vett_ns ? 'SI': 'NO'} sortable={true}/>
      <Column showFilterMenu={false} showClearButton={false} field="cig" header="CIG" sortable={true}/>
      <Column showFilterMenu={false} showClearButton={false} field="cup" header="CUP" sortable={true}/>
    </DataTable>
}

function CommesseProdottiAlt(props) {
  const { state, dispatch } = props.redux
  return <DataTable selectionMode="single" header="Commesse Prodotti" 
      value={state.commesseProdottiAlt} selection={state.commesseProdottiAltSelection}
      filterDisplay='row'
      onRowClick={
        ({data}) => dispatch({type: "set_fld_by_commessaProdotti", value: data})}>
      <Column field="cod_commessa" header="Commessa" sortable={true}/>
      <Column field="data_inizio" header="valida dal" body={(rowData, column) => new Date(rowData.data_inizio).toLocaleDateString()} sortable={true}/>
      <Column field="descrizion" header="Articolo" sortable={true} filter={true} showFilterMenu={false} />
      <Column field="unita" header="Unita" sortable={true}/>
      <Column field="note" header="Note" sortable={true}/>
    </DataTable>
}

function CommesseTrasportiAlt(props) {
  const { state, dispatch } = props.redux
  return <DataTable selectionMode="single" header="Commesse Destinazioni" 
      value={state.commesseTrasportiAlt} selection={state.commesseTrasportiAltSelection}
      filterDisplay='row'
      onRowClick={
        ({data}) => dispatch({type: "set_fld_by_commessaTrasporti", value: data})}>
      <Column field="destinazione" header="Destinazione" sortable={true} filter={true} showFilterMenu={false}/>
      <Column field="destinazione_pattern" header="Schema destinazione" sortable={true}/>
      <Column field="id_dest" header="Codice destinazione" sortable={true}/>
    </DataTable>
}

function DestinazioniLibereAlt(props) {
  const { state, dispatch } = props.redux
  return <DataTable selectionMode="single" header="Destinazioni libere recenti" 
      value={state.destinazioniLibereAlt} selection={state.destinazioniLibereAltSelection}
      filterDisplay='row'
      onRowClick={
        ({data}) => dispatch({type: "set_fld_by_destinazioneLibera", value: data.destinazioni})}>
      <Column field="destinazioni.label" header="Destinazione" sortable={true} filter={true} showFilterMenu={false} />
      <Column field="destinazioni.recid" header="Codice destinazione" sortable={true}/>
    </DataTable>
}

function AltContainer(props) {
  return props.showif ? (
    <div className="card">
      {props.children}
    </div>) : null
}

/******* Main form
 ***
 */

function DdtForm(props) {
  let {redux, saveAction} = props
  let {state, dispatch} = redux
  let trasporto_carico_nostro_msg = state.trasportoCaricoNostro ? 'nostro' : 'del destinatario'

  return (
    <div className="card shadow">
        <div className="card-body">
          <h1 className="card-title">Documento di trasporto o consegna</h1>
          <CodBolla redux={redux} />

          <CodPers redux={redux} fldName='ragSoc' label="Cliente"/>
          <AltContainer showif={state.anagrafeAltVisible}>
            <AnagrafeAlt redux={redux} />
          </AltContainer>

          <Targa redux={redux} />
          <AltContainer showif={state.veicoliAltVisible}>
            <VeicoliAlt redux={redux} />
            <WebCam redux={redux} target="targa" showif={state.veicoliAltVisible}/>
            <WebCam redux={redux} target="cassone" showif={state.veicoliAltVisible}/>
          </AltContainer>

          <CodPers redux={redux} fldName='cod_vettore' label="Vettore" showif={!state.targa.mittente}/>
          <CodPers redux={redux} fldName='conducente' label="Conducente"/>
          
          <GenericTextField redux={redux} fldName='cig' label='CIG'/>
          <GenericTextField redux={redux} fldName='cup' label='CUP' />
          
          <RigaCheckbox value={state.vett_ns.label} label="Vettore a carico nostro" showif={state.cod_vettore.label !== 'NO vettore'}
            onChange={(value) => dispatch({type: 'set_fld', fldName: 'vett_ns', value})}/>
          <Riga label="Trasporto a carico " id="tmp01">
            {trasporto_carico_nostro_msg}
          </Riga>

          <Riga label="Commento">
            {state.ragSoc.recid && state.cod_vettore.recid && state.targa.recid && state.conducente.recid ?
              `Dato il cliente e dato che il trasporto è a carico ${trasporto_carico_nostro_msg}
              la selezione della destinazione sarà ${state.trasportoCaricoNostro ? 'definita dalla commessa': 'di libera scelta'}. L'articolo rimane stabilito dalla commessa`
            : null}
          </Riga>

          <AltContainer showif={state.commesseProdottiAltVisible}>
            <CommesseProdottiAlt redux={redux} />
          </AltContainer>
          <RigaFocus redux={redux} label="Commessa selezionata" fldName="cod_commessa" />
          <RigaFocus redux={redux} label="Articolo" fldName="cod_artic"/>

          <Destinazione redux={redux} />
          <AltContainer showif={state.commesseTrasportiAltVisible}>
              <CommesseTrasportiAlt redux={redux} />
          </AltContainer>
          <AltContainer showif={state.destinazioniLibereAltVisible}>
              <DestinazioniLibereAlt redux={redux} />
          </AltContainer>

          <Lordo redux={redux} />
          <GenericTextField redux={redux} fldName="tara" label="Tara" placeholder="Tara (verificare periodicamente le tare dei clienti per favore)"
            type="number" validOnZero />
          <Riga label="Netto" id="tmp02">
            {(state.lordo.recid - state.tara.recid).toFixed(2)}
          </Riga>
          <RigaDropdown label="Unità"
            options={state.elencoUnita} value={state.unita}
            onChange={({value}) => dispatch({type: 'set_fld', fldName: 'unita', value})}/>
          <GenericInputMaskGroup redux={redux} fldName='pesospec' label="Peso specifico" mask="9.9?99" groupString="T/mc"/>
          <GenericInputMaskGroup redux={redux} fldName='temperatura' showif={Boolean(state.codBolla.recid?.metadati.temperatura)} label="Temperatura" mask="99?9" groupString="°C" placeholder="Inserire la temperatura senza decimali" />
          <GenericInputMaskGroup redux={redux} fldName='num_silos' showif={Boolean(state.codBolla.recid?.metadati.num_silos)} label="Codice silos" mask="9" groupString="cod" placeholder="Inserire il codice silos" />
          <RigaDropdown label="Stampante"
            options={state.elencoStampante} value={state.stampante}
            onChange={({value}) => dispatch({type: 'set_fld', fldName: 'stampante', value})}/>
          <Riga label="Opz. di stampa" id="fakeid1">
                <div className="row">
                  <div className="col-4">
                    <Checkbox value={true} inputId="cbstampare" onChange={e => dispatch({type: 'set_fld', fldName: 'stampare', value: e.checked})} checked={state.stampare} />
                    <label htmlFor="cbstampare">Stampare</label>
                  </div>
                  <div className="col-6">
                    <Checkbox value={true} inputId="cbstamparescontrino" onChange={e => dispatch({type: 'set_fld', fldName: 'stampareScontrino', value: e.checked})} checked={state.stampareScontrino} />
                    <label htmlFor="cbstamparescontrino">Stampare scontrino</label>
                  </div>
                </div>
          </Riga>


          <Riga label="" id="btStampa">
            <Button
              style={{width:"100%"}}
              label={state.formCompleto ? "Invia": "form incompleto"}
              disabled={!state.formCompleto}
              className={state.formCompleto ? "" : "p-button-secondary"}
              onClick={saveAction} />
          </Riga>


          <div style={{marginBottom:'100px'}}>
          </div>
          <h2 className="p-grid p-justify-center">Valori opzionali per sovrascrivere DDT già emessi</h2>
          <h3 className="p-grid p-justify-center">usare con cautela</h3>

          <Riga label="Data" id="xData">
            <Calendar dateFormat="dd/mm/yy"
              showIcon={true}
              showOnFocus={true}
              showTime={true}
              placeholder="Data e ora gg/mm/yyyy hh:mm"
              value={state.data.label} onChange={(v) => dispatch({type:'set_fld', fldName: 'data', value: {label: v.value, recid: v.value}})}/>
          </Riga>
          <GenericTextField redux={redux} fldName="num_bolla" label="Num. Bolla"
            validOnVoidString
            placeholder="Num bolla da rifare" type="number" />

          <Dialog header="Salvataggio del DDT"
            visible={state.savingDialog} onHide={() => dispatch({type: 'close_save', value: 'OK'})}
            modal
            footer={(
                  <div>
                      <Button label="OK" icon="pi pi-md-check" onClick={() => dispatch({type: 'close_save', value: 'OK'})} />
                      <Button label="Ripeti" icon="pi pi-md-done-all" onClick={() => dispatch({type: 'close_save', value: "RIPETI"})} />
                  </div>
            )}>
            <div>
                <ul>
                  <li>{state.msgSave}</li>
                  {state.stampare && (<li>{state.msgPrint}</li>)}
                  {state.stampareScontrino && (<li>{state.msgPrintScontrino}</li>)}
                </ul>
            </div>
          </Dialog>
        </div>
    </div>
)}


/***** Determina per tutto il contenitore lo stato iniziale da cod_bolla e ddtsimile
 *** 
 */

export default function DdtDaCommessa() {
  const urlCodBolla = useParams().codBolla
  const navigate = useNavigate()
  const curr_location = useLocation();
  const {anno: url_anno, num_bolla: url_num_bolla, orig_cod_bolla: url_orig_cod_bolla} =
    curr_location.state?.editBollaKey || {anno: null, num_bolla: null, url_orig_cod_bolla: null};

  // Per avviare una ripetizione di bolla. (Ma forse potrei provare a abbandonare state e usare dei params invece di query string)
  // TODO: Deve però controllare che la commessa della bolla di rifetimento sia valida
  useEffect(() => {
      const search_params = new URLSearchParams(curr_location.search)
      if (search_params.has('anno') && search_params.has('num_bolla')) {
        navigate(``, {replace: true, state: {editBollaKey: {anno: search_params.get('anno'), num_bolla: search_params.get('num_bolla')}}})
      }
    },[curr_location, navigate, urlCodBolla])

  const [state, dispatch] = useReducer(formReducer, null, formReducerInit)
  const redux = {state, dispatch}
  useQueryRedux(redux)
  let saveAction = useMutationRedux(redux)

  useEffect(() => dispatch({type: "changedCodBolla", value: urlCodBolla}),
    [urlCodBolla, state.codBollaList]
  )


  // settato tutto. Ora eventuale init di ddt per ripeti bolla.
  // 20231002 ma potrei ora tornare al solo uso di url senza state
  useEffect(()=> {
    console.log('effetto', curr_location?.state);
    if (state.codBolla.label && url_anno && url_num_bolla /*&& (state.ddtSimileKey.url_anno == null)*/) {
      // hack per usare OB come modello di bolla per MB
      if (urlCodBolla === 'OB') {
        navigate(`../MB`, {
          relative: 'path',
          // replace: true, 
          state: {
            editBollaKey: {...curr_location.state.editBollaKey, orig_cod_bolla: 'OB'}
          }
        })
      } else {
        navigate('', {state:{}}) // cancello lo stato per non ripetere la creazione
        dispatch({
          type: 'set_fld', 
          fldName: 'ddtSimileKey',
          value: {urlCodBolla: curr_location.state.editBollaKey?.orig_cod_bolla || urlCodBolla, url_anno, url_num_bolla, setDdt: true}})
      }
  }}, [urlCodBolla, navigate, state.codBolla.label, url_anno, url_num_bolla, url_orig_cod_bolla, state.ddtSimileKey.url_anno, curr_location.state])

  if (state.cod_vettore_no_vettore.recid == null || state.codBollaList[0] == null)
    return <div>Attendi</div>
  return DdtForm({redux, saveAction})
}