import {fileDownload} from '../../filedownload';
import React, { useContext, useState } from 'react';
import PrincipalsContext from '../../PrincipalsContext';

import {InputText} from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button'

import { gql, useQuery } from '@apollo/client';

const FULLUSERS = ['dir', 'amminist', 'ufficiogare'];

const DDT_CNT_GROUPED_QUERY_GQL = gql(`
query cnt_ddtcommessegroup($args_cnt_filtrato: bollegroup_args!) {
  cnt_filtrato: bollegroup_aggregate(args: $args_cnt_filtrato) {
    aggregate {
      count
    }
  }
}
`);

const DDT_CNT_QUERY_GQL = gql`
query cnt_ddtcommessefiltrato($args_cnt_filtrato: bollefilter_args!) {
  cnt_filtrato: bollefilter_aggregate(args: $args_cnt_filtrato) {
    aggregate {
      count
    }
  }
}
`;

const QUERYFLD_MAP = 
{
  cig: {alluser: true, filter: true, sortable: true},
  cod_bolla: {alluser: true, filter: true, sortable: true},
  cod_commessa: {alluser: true, filter: true, sortable: true},
  conducente: {alluser: true, filter: true, sortable: true},
  costo_unit_prodotto: {alluser: false, filter: true, sortable: true},
  costo_unit_trasporto: {alluser: false, filter: true, sortable: true},
  cup: {alluser: true, filter: true, sortable: true},
  data: {alluser: true, filter: true, sortable: true},
  data_inizio_prodotto: {alluser: true, filter: true, sortable: true},
  data_inizio_trasporto: {alluser: true, filter: true, sortable: true},
  descrizion: {alluser: true, filter: true, sortable: true},
  destinazione: {alluser: true, filter: true, sortable: true},
  destinazione_pattern: {alluser: true, filter: true, sortable: true},
  gruppo: {alluser: true, filter: true, sortable: true},
  netto: {alluser: true, filter: true, sortable: true},
  num_bolla: {alluser: true, filter: true, sortable: true},
  num_voce: {alluser: true, filter: true, sortable: true},
  rag_soc: {alluser: true, filter: true, sortable: true},
  targa: {alluser: true, filter: true, sortable: true},
  unita: {alluser: true, filter: true, sortable: true},
  valore_prodotto: {alluser: false, filter: true, sortable: true},
  valore_trasporto: {alluser: false, filter: true, sortable: true},
  vettrag_soc: {alluser: true, filter: true, sortable: true},
}

/**** converte un oggetto Data in una stringa YYYYMMDD senza TZ
Trasforma le date in stringhe compatibili con Apollo

se end_day aggiunge la mezzanotte del giorno per rendere
la data utile come "giorno compreso" 
  
*/
function convert_date_graql_notz(data, end_day=false) {
  if (data && data?.getDate) 
    return (
      `${data.getFullYear()}` 
      +`${(data.getMonth() +1).toString().padStart(2,'0')}`
      +`${data.getDate().toString().padStart(2,'0')}`
      +`${end_day ? 'T23:59:59':''}`);
  return data
}

/* Come visualizzo la data e ora */
function DataTimeTypeTemplate(rowData, column) {
  let datatime = new Date(rowData[column.field])
  let data = Intl.DateTimeFormat(navigator.languages,{dateStyle:"short"}).format(datatime)
  let ora = Intl.DateTimeFormat(navigator.languages,{timeStyle:"short"}).format(datatime)
  return <><span>{rowData[column.field] ? data : '---'}</span><span style={{fontSize:'x-small'}}>{rowData[column.field] && ora}</span></>
}

/* Come visualizzo la data e ora */
function DataTypeTemplate(rowData, column) {
  let datatime = new Date(rowData[column.field])
  let data = Intl.DateTimeFormat(navigator.languages,{dateStyle:"short"}).format(datatime)
  return <><span>{rowData[column.field] ? data : '---'}</span></>
}

/* Come visualizzo il netto */
function NettoTemplate(rowData, column) {
  return <>{rowData[column.field].toFixed(2)}</>
}

/* Come visualizzo i valori in euro */
function inEuro(rowData, column) {
  let valore = rowData[column.field] ? 
    Intl.NumberFormat(navigator.locales, {style:"currency", currency:"EUR"}).format(rowData[column.field]) : '--'
  return <span>{valore}</span>
}



/* Calendario filtro */

function RenderDateFilter(setFilterSet, filterset, setPaginator) {
  const opzioni = {locale: "it", showIcon: true, showOnFocus: false}
  const setOggi = (field, value) => {
    setFilterSet({...filterset, [field]:{value: value}})
    setPaginator(prev => ({...prev, first: 0}))
  }

  return (<>
      <Calendar placeholder="Data Iniziale" value={filterset.data_min.value} onChange={({value}) =>{setOggi("data_min", value)}} {...opzioni} /><br />
      <Calendar placeholder="Data Finale" value={filterset.data_max.value} onChange={({value}) =>{setOggi("data_max", value)}} {...opzioni} />
      </>
  );
}


function RenderNumbollaFilter(setFilterSet, filterset, setPaginator) {
  const [ inputTextValues, setInputTextValues ] = useState({num_bolla_min: "", num_bolla_max: ""})
  const opzioni = {className: "p-column-filter", type: "number", min: 1}
  const setNum = (field, value) => {
    setInputTextValues(prev => ({...prev, [field]: value}))
    setFilterSet({...filterset, [field]:{value: value === "" ? null: value}})
    setPaginator(prev => ({...prev, first: 0}))
  }

  return (<>
      <InputText placeholder="N.Iniziale" value={inputTextValues.num_bolla_min.value} onChange={(e) => {setNum("num_bolla_min", e.target.value)}} {...opzioni} /><br/>
      <InputText placeholder="N. Finale" value={inputTextValues.num_bolla_max.value} onChange={({target}) => {setNum("num_bolla_max", target.value)}} {...opzioni} /><br/>
      </>
  );
}


function TableHeader(groupBy, setGroupBy, setPaginator, variables, cntSelezione, full_user) {
  const fieldsSelectItems = [
    {label: 'Cod. Bolla', value: 'groupby_cod_bolla'},
    {label: 'Articolo', value: 'groupby_descrizion'},
    {label: 'Destinatario', value: 'groupby_rag_soc'},
    {label: 'Commessa', value: 'groupby_cod_commessa'},
    {label: 'Gruppo merc.', value: 'groupby_gruppo'},
    {label: 'Mese', value: 'groupby_mese'},
    {label: 'Vettore', value: 'groupby_vettrag_soc'}
  ];

  return (
    <div className='container text-center'>
      <div className="row">
        <div className="col">
          <MultiSelect filter
            fixedPlaceholder={false}
            placeholder="Seleziona qui il raggruppamento"
            value={groupBy} options={fieldsSelectItems}
            onChange={({value}) => {
              setGroupBy(value)
              setPaginator(prev => ({...prev, first: 0})) 
            }} 
          />
        </div>
        <h1 className="col">
            Elenco Bolle
        </h1>
        <div className="col">
          <Button disabled={cntSelezione < 1} label="Scarica foglio Excel" onClick={() => fileDownload((full_user ? "/a/ddtxls?q=" : "/a/commonddtxls?q=") + JSON.stringify(variables))}/>
        </div>
      </div>
    </div>
  )
}

function DDTQueryTabella() {
  const {allowedRoles} = useContext(PrincipalsContext)
  let full_user = allowedRoles && FULLUSERS.some((value) => allowedRoles.includes(value))
  const [ paginator, setPaginator ] = useState({ rows: 20, first: 0 })
  const [ filterset, setFilterSet ] = useState(
    {
      cod_bolla: {},
      data_min:{value: new Date()}, 
      data_max:{},
      rag_soc:{},
      destinazione: {},
      vettrag_soc:{},
      unita:{},
      descrizion:{},
      cod_commessa: {},
      gruppo: {},
    })
  const [ multisort, setMultisort ] = useState({sortOrder:0, sortField: "", multiSortMeta: []})
  const [ groupBy, setGroupBy ] = useState([])
  let filters_args = Object.fromEntries(Object.keys(filterset).map(k => ['flt_' + k, filterset[k].value || null]).filter(v => v ? true: false))
  let group_by_args = Object.fromEntries(groupBy.map(k => [k, true]))
  let order_by_args = (multisort.multiSortMeta.length === 0) ? [{cod_bolla: 'asc'}, {data: 'asc'}] : multisort.multiSortMeta.map(k => ({[k.field]: k.order === 1 ? 'asc' : 'desc'}))

  const QUERYFLD_LIST = Object.keys(QUERYFLD_MAP).filter(
      (e) => full_user || QUERYFLD_MAP[e].alluser )

  const DDT_GROUPED_QUERY_GQL = gql`
  query ddtcommessegroup($args: bollegroup_args!, $rows: Int!, $first: Int!, $order_by: [bollegroup_rettype_order_by!]) {
    tabella: bollegroup(args: $args, limit: $rows, offset: $first, order_by: $order_by) {
      ${QUERYFLD_LIST}
    }
  }
  `
  const DDT_QUERY_GQL = gql`
  query ddtcommessefiltrato($args: bollefilter_args!, $rows: Int!, $first: Int!, $order_by: [bollegroup_rettype_order_by!]) {
    tabella: bollefilter(args: $args, limit: $rows, offset: $first, order_by: $order_by) {
      ${QUERYFLD_LIST}
    }
  }
  `

  filters_args.flt_data_min = convert_date_graql_notz(filters_args.flt_data_min)
  filters_args.flt_data_max = convert_date_graql_notz(filters_args.flt_data_max, true)
  
  let query_args = {...filters_args, ...group_by_args}

  let variables = {
    first: paginator.first,
    rows: paginator.rows,
    args: query_args,
    args_cnt_filtrato: query_args,
    order_by: order_by_args
  }

  let cnt_variables = {
    args: query_args,
    args_cnt_filtrato: query_args,
  }

  const {loading: cnt_loading, data: cnt_data} = useQuery(
    Object.keys(group_by_args).length === 0 ? DDT_CNT_QUERY_GQL : DDT_CNT_GROUPED_QUERY_GQL,
    { variables: cnt_variables });

  const { loading, data } = useQuery(
    Object.keys(group_by_args).length === 0 ? DDT_QUERY_GQL : DDT_GROUPED_QUERY_GQL,
    { variables: variables });
  
  let {tabella} = data ? data: {tabella: []}
  let {cnt_filtrato: {aggregate: {count: cntSelezione}}} = cnt_data ? cnt_data : {cnt_filtrato: {aggregate: {cntSelezione: 0}}} 
  // let admitSortable = groupBy.length < 1
  let admitSortable = true
  return (
  <div className='card shadow'>
    <div className='card-body'>
      <DataTable
        tableStyle={{minHeight: "35em"}}
        header={TableHeader(groupBy, setGroupBy, setPaginator, variables, cntSelezione, full_user)}

        size="small"
        value={tabella} lazy={true}
        emptyMessage="nessun dato"
        loading={cnt_loading || loading}
        onFilter={(e) => {
          setFilterSet(e.filters)
          setPaginator({...paginator, first: 0})
        }}
        filters={filterset}
        filterDisplay='row'

        sortMode="multiple"
        removableSort={true}
        onSort={(e) => {
          setMultisort(e)
          setPaginator({...paginator, first: 0})
        }}
        multiSortMeta={multisort.multiSortMeta}

        paginator={true}
        rows={paginator.rows > 10 ? paginator.rows : 10}
        first={paginator.first}
        totalRecords={cntSelezione}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 20, 30]}
        onPage={(event) => {
          const {rows, first} = event
          setPaginator({rows, first})
        }}
        >
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "8em"}} field="cod_bolla" filterPlaceholder="Cod. Bolla" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "8em"}} field="num_bolla" filterPlaceholder="Num. Bolla" filterElement={RenderNumbollaFilter(setFilterSet, filterset, setPaginator)}  filter={true} sortable={admitSortable }/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "11em"}} field="data" header="Data" body={DataTimeTypeTemplate} filterElement={RenderDateFilter(setFilterSet, filterset, setPaginator)} filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "12em"}} field="rag_soc" header="Destinatario" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "10em"}} field="destinazione" header="Destinazione" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} field="conducente" header="Conducente" sortable={admitSortable }/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "12em"}} field="vettrag_soc" header="Vettore" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} field="unita" header="Unita" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} field="netto" header="Netto" body={NettoTemplate} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "12em"}} field="descrizion" header="Articolo" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "10em"}} field="cod_commessa" header="Commessa" filter={true} sortable={admitSortable}/>
          <Column showFilterMenu={false} showClearButton={false} field="data_inizio_prodotto" header="Data comm. prodotto" body={DataTypeTemplate}/>
          <Column showFilterMenu={false} showClearButton={false} field="destinazione_pattern" header="Pattern dest."/>
          <Column showFilterMenu={false} showClearButton={false} field="data_inizio_trasporto" header="Data comm. trasporto" body={DataTypeTemplate} />
          {full_user ? <Column showFilterMenu={false} showClearButton={false} field="costo_unit_prodotto" header="Prezzo Prod." body={inEuro} /> : null}
          {full_user ? <Column showFilterMenu={false} showClearButton={false} field="costo_unit_trasporto" header="Prezzo Trasp." body={inEuro} /> : null}
          {full_user ? <Column showFilterMenu={false} showClearButton={false} field="valore_prodotto" style={{fontSize:'x-small'}} header="Valore Prod." body={inEuro} sortable={admitSortable}/> : null}
          {full_user ? <Column showFilterMenu={false} showClearButton={false} field="valore_trasporto" style={{fontSize:'x-small'}} header="Valore Trasp." body={inEuro} sortable={admitSortable} /> : null}
          {full_user ? <Column showFilterMenu={false} showClearButton={false} headerStyle={{"minWidth": "10em"}} field="gruppo" header="Gruppo merceologico" filter={true} sortable={admitSortable}/> : null}
      </DataTable>
    </div>
  </div>
  )
}


export const DDTQueryCommesse = DDTQueryTabella