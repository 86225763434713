// Componente per sistema ExeDialog. Usa DataTable per up_matricola e cnt_matricola
import React, {useEffect, useState, useRef} from 'react';
import { gql, useQuery } from '@apollo/client';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

const GQL_TABELLA = gql`
query tabella_contatori($up_matricola: String, $cnt_matricola: String, $offset: Int = 0, $limit: Int = 10, $order_by: [costi2009_contatore_order_by!] = {}) {
    tabella: costi2009_contatore(where: {up_matricola: {_ilike: $up_matricola}, cnt_matricola: {_ilike: $cnt_matricola}}, offset: $offset, limit: $limit, order_by: $order_by) {
        up_matricola
        up_descrizione: unit_prod {
            up_descrizione
        }
        unit_prod {
            up_matricola
            up_descrizione
        }
        cnt_matricola
        cnt_descrizione
        unita
        note
    }
    cnt: costi2009_contatore_aggregate(where: {up_matricola: {_ilike: $up_matricola}, cnt_matricola: {_ilike: $cnt_matricola}}) {
        aggregate {
            count
        }
    }
}
`

export function UpMatricola(props) {
    return <TabellaContatori {...props}/>
}

UpMatricola.displayName = 'UpMatricola';

export function CntMatricola(props) {
    return <TabellaContatori  {...props}/>
}

CntMatricola.displayName = 'CntMatricola';

// Funzione importante per la gestione del sort da riutilizzare o rendere globale
function mk_sort_key(datatablesortmeta) {
    let [parent, child] = datatablesortmeta.field.split('.')
    if (child) {
        return {[parent]: {[child]: datatablesortmeta.order === 1 ? 'asc' : 'desc'}}
    }
    else {
        return {[parent]: datatablesortmeta.order === 1 ? 'asc' : 'desc'}
    }
}

function TabellaContatori(props) {
    const input_filter = useRef(null)

    useEffect(() => {
        if (input_filter.current) {
            const filterInput = input_filter.current.getElement().querySelector('.p-column-filter input');
            if (filterInput) {
                filterInput.focus();
            }
        }
    }, []);

    const [table_state, setTableState] = useState(
        {
            "first": 0,
            "rows": 10,
            "sortField": null,
            "sortOrder": null,
            "multiSortMeta": 
                [
                    {
                        "field": "up_matricola",
                        "order": 1
                    },
                    {
                        "field": "cnt_matricola",
                        "order": 1
                    }
                ],
            "filters": {
                "up_matricola": {
                    "value": ""
                },
                "cnt_matricola": {
                    "value": ""
                }
            }
        })
    let order_by = (table_state.multiSortMeta.length === 0) ? [{up_matricola: 'asc', cnt_matricola: 'asc'}] : table_state.multiSortMeta.map(k => (mk_sort_key(k)))

    const { loading, error, data } = useQuery(GQL_TABELLA, {
        variables: { 
            up_matricola: table_state.filters.up_matricola.value + '%',
            cnt_matricola: table_state.filters.cnt_matricola.value + '%',
            offset: table_state.first, 
            limit: table_state.rows,
            order_by: order_by },
        });
    if (error) return <p>Error :(</p>;

    const tabella = data?.tabella || []
    const cnt = data?.cnt?.aggregate?.count || 0

    const table_header = <div className='row text-center'>
            <div className='col-12'>
                Seleziona una unità produttiva e un contatore cliccando la riga corrispondente
            </div>
        </div>

    return ( 
            <div className="card-body">
                <h1 className="card-title">Unità produttiva e contatore</h1>

                <DataTable
                    tableStyle={{minHeight: "35em"}}
                    size="small"
                    stripedRows
                    showGridlines 
                    header={table_header}

                    value={tabella} lazy={true}
                    emptyMessage="nessun dato"
                    loading={loading}

                    filters={table_state.filters}
                    filterDisplay='row'
                    onFilter={(e) => setTableState({...table_state, filters: e.filters, first: 0, page: 0})}


                    sortMode='multiple'
                    removableSort={true}
                    multiSortMeta={table_state.multiSortMeta}
                    onSort={(e) => setTableState({...table_state, multiSortMeta: e.multiSortMeta, first: 0, page: 0})}

                    paginator={true}
                    rows={table_state.rows > 10 ? table_state.rows : 10}
                    first={table_state.first}
                    totalRecords={cnt}
                    rowsPerPageOptions={[10,20,30]}
                    onPage={(e) => setTableState({...table_state, first: e.first, rows: e.rows, page: e.page})}

                    selectionMode="single"
                    onSelectionChange={({value}) => props.onFinish({up_matricola: value.up_matricola, cnt_matricola: value.cnt_matricola})}

                    ref={input_filter}
                >

                    <Column field="up_matricola" header="Unità Produttiva Matricola" showFilterMenu={false} showClearButton={false} filter sortable headerTooltip="puoi usare per la ricerca le regole LIKE di SQL"></Column>
                    <Column field="unit_prod.up_descrizione" header="Unità Produttiva Descrizione" sortable></Column>
                    <Column field="cnt_matricola" header="Contatore Matricola" filter showFilterMenu={false} showClearButton={false} sortable></Column>
                    <Column field="cnt_descrizione" header="Contatore Descrizione" sortable></Column>
                    <Column field="unita" header="Unita"></Column>
                    <Column field="note" header="Note"></Column>
                </DataTable>
            </div>)
}


