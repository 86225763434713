import { useContext } from 'react';
import {useNavigate, Link } from 'react-router-dom'
import {Menubar} from 'primereact/menubar'
import logo from './logovaccari.svg';
import {logoutGoogle } from './loginproc';
import PrincipalsContext from './PrincipalsContext';


export function MainMenubar(props) {
    const {allowedRoles} = useContext(PrincipalsContext)
    const rolesInAllowed = (roles_array) => roles_array.some((e) => allowedRoles.includes(e))
    const activateMenu = (roles_array, items_array) => rolesInAllowed(roles_array) ? items_array : [] // usare operatore spread ... davanti alla chiamata dentro l'array delle voci di menu
    const navigate = useNavigate()
    const items = [
        ...activateMenu(['ddt', 'dir', 'amminist'], 
        [
            {
                label: 'Bolle',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                    {
                        label: 'Crea Bolla',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => navigate('/ddt')
                    },
                    {
                        label: 'Crea Bolla BL',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => navigate('/ddt/BL')
                    },
                    {
                        label: 'Crea Bolla MV',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => navigate('/ddt/MV')
                    },
                    {
                        label: 'Crea Bolla MB',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => navigate('/ddt/MB')
                    },
                    {
                        label: "Bolla oggi",
                        icon: "pi pi-list",
                        command: () => navigate('/ddtoggi')
                    },
                    {
                        label: "Veicoli",
                        icon: "pi pi-id-card",
                        command: () => navigate('/veicoli')
                    },
                    {
                        label: "Destinazioni",
                        icon: "pi pi-map-marker",
                        command: () => navigate('/destinazioniregistro')
                    },
                    ...activateMenu(['dir', 'amminist'], [
                        {
                            label: "AdHoc",
                            icon: "pi pi-database",
                            command: () => navigate('/ddtadhoc')
                        }]),
                ]},
            {
                label: 'Commesse',
                icon: 'pi pi-fw pi-book',
                items: [
                        {
                            label: 'Analisi Bolle',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => navigate('/ddtquerycommesse')
                        },
                        ...activateMenu(['ddt', 'amminist', 'dir', 'ufficiogare'],
                        [{
                            label: "Commesse",
                            icon: "pi pi-database",
                            command: () => navigate('/commesseregistro')
                        }])
                    ]
            }]),
        ...activateMenu(['ddt', 'dir'],
            [
                {
                    label: 'Gasolio',
                    icon: 'pi pi-arrow-down-left',
                    items: [{
                        label: 'Rifornimento',
                        icon: 'pi pi-lock-open',
                        command: () => navigate('/erogavvia')
                    }]
                },
                {
                    label: 'Registri',
                    icon: 'pi pi-fw pi-book',
                    items: [{
                        label: "Registro costi",
                        icon: 'pi pi-fw pi-shopping-cart',
                        command: () => navigate('/c2009registro')
                    }]
                }
            ]),
        ...activateMenu(['impianti'],
            [
                {
                    label:'impianti',
                    icon: 'pi pi-bolt',
                    items: [
                        {
                            label: 'Pista lavata',
                            icon: 'pi pi-truck',
                            command: () => navigate('/pistalavata')
                        }
                    ]
                }
            ]),
        {
            label: 'Esci',
            icon: 'pi pi-fw pi-power-off',
            command: logoutGoogle,
        }
    ];

    const start = <Link to="/"><img alt="logo" src={logo} height="40" className="mr-2"></img></Link>;
    return  (
        <Menubar style={{borderRadius:'0px'}} model={items} start={start} />
    )
}
