/******* InputMask per data con validazione che trasmette label a recid
 ****
 */
/* Validazione sulla data con trasferimento in recid sempre obbligatoria */
/* value deve essere un oggetto {label, recid} */

import React, { Component } from 'react';
import { InputMask } from 'primereact/inputmask';
import { Riga, DiaRiga } from './Riga'

import moment from 'moment'

// Restituisce l'oggetto {label, recid} da una label che può essere ISO o "DD/MM/YYYY". O un obj Moment/Date
export function inputDataRecid(label) {
    let tmp = (
        moment(label, "DD/MM/YYYY", true).isValid()
        ? moment(label, "DD/MM/YYYY", true)
        : (moment(label).isValid() ? moment(label) : null))
    return (tmp 
        ? {recid: tmp.format("YYYY-MM-DD"), label: tmp.format("DD/MM/YYYY")} 
        : {recid: null, label: label || "_"})
}

class Widget extends Component {
    validData = (value) => (
        moment(this.props.value.label, "DD/MM/YYYY", true).isValid()
        && (!this.props.validif || this.props.validif(value)))

    onChange = (e) => {
        let {value} = e.target
        this.props.onChange(this.validData(value) ? inputDataRecid(value) : {label:value, recid: null})
    }

    render() {
        return (<InputMask
            className={this.validData(this.props.value.label) ? "" : "p-error"}
            mask="99/99/9999"
            {...this.props}
            onChange={this.onChange}
            value={this.props.value.label}
            />)

    }
}

export const DiaRigaInputData = (props) => (
    <DiaRiga id={props.id} label={props.label} showif={props.showif}>
        <Widget  {...props} />
    </DiaRiga>)

export const RigaInputData = (props) => (
    <Riga id={props.id} label={props.label} showif={props.showif}>
        <Widget  {...props} />
    </Riga>)
