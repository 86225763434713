import { gql, useQuery } from '@apollo/client';

export default function useQueryRedux(redux) {
  const { state, dispatch } = redux
  const codBolla = state.codBolla.label
  const codBollaPesa = state.codBolla?.recid?.metadati?.pesa

  useQuery(gql`
  query codPers($qry: String = "NO vettore") {
    recordset: anagrafe_rag_soc(where: {rag_soc: {_ilike: $qry}}, limit: 50, order_by: {rag_soc: asc}) {
        id: cod_pers
        recid: cod_pers
        label: rag_soc
    }
  }
    `,
    {
      onCompleted: (data) => dispatch({type: "set_fld", fldName: "cod_vettore_no_vettore", value: data.recordset[0]})
    })

  useQuery(gql`
  {
    codBollaList: ghiaia3_cod_bolla_metadati {
      label:cod_bolla
      metadati: ddt
    }
  }`,
  {
    onCompleted: ({codBollaList}) => dispatch({type: 'set_fld', fldName: 'codBollaList',
        value: codBollaList.map(
          (v) => ({
            recid: {label: v.label, ...v},
            label: v.label,
            labelext: v.metadati.descrizione
          }))
      }
    )
  })
  
  useQuery(gql`
  query bolla($url_anno: Int!, $urlCodBolla: bpchar!, $url_num_bolla: Int!) {
    bolle_by_pk(anno: $url_anno, cod_bolla: $urlCodBolla, num_bolla: $url_num_bolla) {
      anno
      cod_bolla
      num_bolla
      cig
      cup
      anagrafe {
        label: rag_soc
        recid: cod_pers
        id: cod_pers
      }
      anagvettore {
        recid: cod_pers
        label: rag_soc
        id: cod_pers
      }
      destinazioni {
        label: destinazione
        recid: id_dest
      }
      bolle_commesse_trasporti {
        commessa_trasporti_data_inizio: data_inizio
        destinazione_pattern
      }
      vett_ns
      veicoli {
        recid: targa
        label: targa
        mittente
      }
      bolle_vocis(where: {num_voce: {_eq: "1"}}) {
        tara
        unita
        pesospec
        temperatura
        articolo {
          recid: cod_artic
          label: descrizion
        }
        bolle_commesse_prodotti {
          cod_commessa
          data_inizio
        }
      }
      anagconducente {
        label: rag_soc
        recid: cod_pers
        id: cod_pers
      }
    }
  }
    `,
  {
    skip: !state.ddtSimileKey.setDdt,
    variables:{url_anno: state.ddtSimileKey.url_anno, urlCodBolla: state.ddtSimileKey.urlCodBolla, url_num_bolla: state.ddtSimileKey.url_num_bolla },
    onCompleted: ({bolle_by_pk}) => dispatch({type: 'ddt_simile', value: bolle_by_pk})
  })

  useQuery(gql`
  query ragsocalt($codBolla: String!) {
    recordset: ghiaia3_ragsocalt(args: {cod_bolla: $codBolla, freq: 3}, order_by: {rag_soc: asc}) {
      recid: cod_pers
      label: rag_soc
      id: cod_pers
    }
  }
    `,
    {
      skip: !codBolla,
      variables: {codBolla},
      onCompleted: (data) => dispatch({type: "set_fld", fldName: "ragSocAlt", value: data.recordset})
    })

  useQuery(gql`
  query ghaia3_veicolialt($codBolla: String!, $cod_pers: Int!) {
    recordset: ghiaia3_veicolialt(args: {cod_bolla: $codBolla, cod_pers: $cod_pers}) {
      targa
      cig
      cup
      vett_ns
      conducente
      anagconducente {
        label: rag_soc
        recid: cod_pers
        id: cod_pers
      }
      anagvettore {
        label: rag_soc
        recid: cod_pers
        id: cod_pers
      }
      veicoli {
        tara
        mittente
      }
    }
  }`,
  {
    skip: !state.ragSoc.recid,
    variables: {codBolla, cod_pers: state.ragSoc.recid},
    onCompleted: (data) => dispatch({type: "set_fld", fldName: "veicoliAlt", value: data.recordset})
  })
  
  useQuery(gql`
  query pesi($codBollaPesa: String = "", $codBolla: String = "") {
    pesiponte(limit: 10, where: {cod_bolla: {_eq: $codBollaPesa}}, order_by: {data: desc}) {
      data
      peso
    }
  }
  `,
  {
    skip: !codBollaPesa,
    variables: {codBollaPesa, codBolla},
    onCompleted: (data) => redux.dispatch({type: 'set_bilancia', value: data.pesiponte}),
    pollInterval: state.bilancia[0] && state.bilancia[0].peso ? 2000: 10000,
    notifyOnNetworkStatusChange: true // apollo-client bug #5531
  })

  useQuery(gql`
  query PrintStatus($cod_bolla: bpchar, $num_bolla: Int, $treg: timestamptz) {
    ddt_print_queue(where: {cod_bolla: {_eq: $cod_bolla}, num_bolla: {_eq: $num_bolla}, treg: {_eq: $treg}}) {
      esito_stampa
    }
  }`,
  {
    skip: !state.waitPrintStatus,
    variables: state.savedDDT,
    onCompleted: (data) => {
      let esito_stampa = data.ddt_print_queue[0].esito_stampa;
      if (esito_stampa) {
        redux.dispatch({type: 'set_fld', value: esito_stampa, fldName: 'msgPrint'});
        redux.dispatch({type: 'set_fld', fldName: 'waitPrintStatus', value: false})
      }
    },
    onError: (error) => {
      redux.dispatch({type: 'set_fld', value: error.message, fldName: 'msgPrint'});
      redux.dispatch({type: 'set_fld', fldName: 'waitPrintStatus', value: false})
    },
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true // apollo-client bug #5531
  })

  useQuery(gql`
  query commesse_prodotti_valide_data($oggi: timestamp, $cod_pers: Int!, $classe: String) {
    recordset: commesse_prodotti_trasporti_valide_data(where: {cod_pers: {_eq: $cod_pers}, data_fine: {_gte: $oggi}, classe: {_like: $classe}}, order_by: [{cod_commessa: asc}, {classe: asc}, {descrizion: asc}], args: {data: $oggi}) {
      cod_commessa
      data_inizio
      cod_artic
      descrizion
      note
      unita
      classe
    }
  }
  `,
  {
    skip: !state.commesseProdottiAltVisible,
    variables: {
      oggi: state.data.recid || new Date().toISOString().slice(0,10),
      cod_pers: state.ragSoc.recid,
      classe: "prodotto"
    },
    onCompleted: (data) => dispatch({type: "set_fld", fldName: "commesseProdottiAlt", value: data.recordset})
  })

  useQuery(gql`
  query destinazioni_da_commessa($oggi: timestamp!, $cod_commessa: String!, $cod_pers: Int!) {
    recordset: destinazioni_destinazioni_pattern_valide_data(where: {cod_commessa: {_eq: $cod_commessa}, data_fine: {_gte: $oggi}, cod_pers: {_eq: $cod_pers}}, args: {data: $oggi}) {
      destinazione
      id_dest
      destinazione_pattern
      commessa_trasporti_data_inizio: data_inizio
    }
  }
      `,
  {
    skip: !state.commesseTrasportiAltVisible && !state.cod_commessa.recid,
    variables: {
      oggi: state.data.recid || new Date().toISOString().slice(0,10),
      cod_pers: state.ragSoc.recid,
      cod_commessa: state.cod_commessa.recid,
    },
    onCompleted: (data) => dispatch({type: "set_fld", fldName: "commesseTrasportiAlt", value: data.recordset})
  })
  useQuery(gql`
  query destinazioniLibereAlt($cod_bolla: bpchar, $cod_pers: Int, $data: timestamp!) {
    bolle(distinct_on: [id_dest], where: {cod_pers: {_eq: $cod_pers}, cod_bolla: {_eq: $cod_bolla}, data:{_gte: $data}}) {
      destinazioni {
        label: destinazione
        recid: id_dest
      }
    }
  }
  `,
  {
    skip: !state.destinazioniLibereAltVisible,
    fetchPolicy: "network-only",
    variables:{
      data: (new Date(new Date() - (30*3600000*24))).toISOString().slice(0,10),
      cod_pers: state.ragSoc.recid,
      cod_bolla: codBolla
    },
    onCompleted: (data) => dispatch({type: "set_fld", fldName: "destinazioniLibereAlt", value: data.bolle})
  })
}
