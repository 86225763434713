// Componente per sistema ExeDialog. Usa datetime-local per data e ora
import React, {useState, useRef, useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';

const GQL_DATA = gql`
query DataUltimoRifornimento($up_matricola: String = "", $cnt_matricola: String = "") {
    costi2009_registro_aggregate(where: {up_matricola: {_eq: $up_matricola}, cnt_matricola: {_eq: $cnt_matricola}}) {
        aggregate {
            max {
                data
            }
        }
    }
}
`

export function Data(props) {
    const {currURLSearchParams, onFinish} = props;
    const [state, setState] = useState({data: ''})
    const inputRef = useRef();

    const { loading, error, data } = useQuery(GQL_DATA,
        {variables: {
            up_matricola: currURLSearchParams.get('up_matricola'),
            cnt_matricola: currURLSearchParams.get('cnt_matricola')}});
            
    useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus();
    }, [data]);
    
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    const date = new Date(data.costi2009_registro_aggregate.aggregate.max.data);
    const localeDateString = date.toLocaleString();
    return (
        <div className="card-body">
            <h5 className="card-title">Seleziona una data (l'ultima data inserita è {localeDateString})</h5>
            <form onSubmit={e => {e.preventDefault(); onFinish({data: state.data})}}>
                <div className="mb-3">
                    <label htmlFor="dateInput" className="form-label">Data del rifornimento</label>
                    <input ref={inputRef} type="datetime-local" 
                        className="form-control" id="dateInput"
                        value={state.data} onChange={(e) => setState({...state, data: e.target.value})} />
                </div>
                <button className="btn btn-primary" disabled={!Boolean(state.data)}>Prosegui</button>
            </form>
        </div>
    )
}

Data.displayName = 'Data';