// Produce una Date in orario Z ma con ora locale

// se applichi toISOString() alla data ritornata
// ottiene una ISO8601 con l'orario locale
// come fosse in Z. Da usare per query con date "naive" senza TZ

export function dataUTC_nTZ(data, end_day=false) {
    return Boolean(data?.getDate) ? 
        new Date( // data ha time zone. In postgres no
            Date.UTC(
                data.getFullYear(),
                data.getMonth(),
                data.getDate() + (end_day ? 1 : 0),
                data.getHours(),
                data.getMinutes(),
                data.getSeconds()))
        : null;
}

// Risponde una stringa contenente solo la parte "data". No ora e TZ
export function dataTruncDay_nTZ(data, end_day=false) {
    return dataUTC_nTZ(data, end_day)?.toISOString()?.slice(0,10)
}

/**** converte un oggetto Data in una stringa YYYYMMDD senza TZ
Trasforma le date in stringhe compatibili con Apollo

se end_day aggiunge la mezzanotte del giorno per rendere
la data utile come "giorno compreso" 

*/
export function convert_date_graql_notz(data, end_day=false) {
    if (data && data?.getDate) 
        return (
            `${data.getFullYear()}` 
            +`${(data.getMonth() +1).toString().padStart(2,'0')}`
            +`${data.getDate().toString().padStart(2,'0')}`
            +`${end_day ? 'T23:59:59':''}`);
    return data
}