import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast'
import { DiaRigaInputText } from '../WIDGETS2/InputText'
import { DiaRiga } from '../WIDGETS2/Riga'
import { DiaRigaDBAutocomplete } from '../WIDGETS/DBAutocomplete'
import { DialogCrudButton } from '../WIDGETS/DialogCrudButton'

const ADD_COMMESSA = gql`
mutation ADD_COMMESSA($note: String = "", $cod_pers: Int, $cod_commessa: String) {
    insert_commesse_one(object: {cod_commessa: $cod_commessa, cod_pers: $cod_pers, note: $note}, on_conflict: {constraint: commesse_pkey, update_columns: note}) {
        cod_pers
        cod_commessa
    }
}
`
 
const DEL_COMMESSA = gql`
mutation DEL_COMMESSA($cod_commessa: String!, $cod_pers: Int!, $note: String) {
    delete_commesse_by_pk(cod_pers: $cod_pers, cod_commessa: $cod_commessa) {
        cod_pers
        cod_commessa
    }
}
`

DialogSaveCommessa.propTypes = {
    visible: PropTypes.bool.isRequired,
    newRecord: PropTypes.bool.isRequired, // flag add record
    onHide: PropTypes.func.isRequired,
    initRecord: PropTypes.object
}


const INIT_STATE = {
    rag_soc: {label:'', recid: null},
    cod_commessa: {label:'', recid: null},
    note: {label:'', recid: null},
    dirty:false
}
  
function initRecord2InitState(initRecord) {
    let {cod_pers, rag_soc, cod_commessa, note} = initRecord;
    let ret_val = {
        rag_soc: {
            label: rag_soc, 
            recid: cod_pers}, 
        cod_commessa: {
            label: cod_commessa, 
            recid: cod_commessa}, 
        note: {
            label: note, 
            recid: note}, 
        dirty: false}
    return ret_val
}

export function DialogSaveCommessa(props) {
    const [record, setRecord] = useState(INIT_STATE);
    let growl;
    useEffect(resetState, [props.initRecord, props.newRecord])

    function resetState() {
        let tmp = {...INIT_STATE}
        if (props.newRecord) {
            setRecord(tmp)
        }
        else if (props.initRecord) 
            props.initRecord && setRecord(initRecord2InitState(props.initRecord));
    }

    let datiOK = () => {
        return !!(
            record.rag_soc.recid 
            && record.cod_commessa.recid
            && typeof(record.note.recid) === "string"
        )
    }

    let getVariables = () => datiOK() ? ({
        cod_pers: record.rag_soc.recid, 
        cod_commessa: record.cod_commessa.recid,
        note: record.note.recid
    }) : {}

    let msgSave = (saveresponse) => {
        if (!saveresponse) return null;
        let {data, error, graphQLErrors} = saveresponse;
        if (data) {
            growl.show({
            severity: 'success', summary: 'Esito',
            detail: 'Corretto',
            life: 3000
            })
        }
        if (error) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + error.msg, life: 3000
            })
        }
        if (graphQLErrors) {
            growl.show({
            severity: 'error', summary: 'Azione',
            detail: 'Comando fallito ' + saveresponse.message, life: 3000
            })
        }
    }

    function onMutationOK(data) {
        if (data) {
            msgSave({ data: data });
            props.onHide();
        }
    }

    function onMutationError(error) {
        console.log('Errori apollo o eccezione in onMutationOK');
        if (error) {
            msgSave({ error: error });
        }
    }

    function onSave(data, error) {
        let dati = getVariables();
        console.log('salva dati', dati);
        actionadd({ variables: dati });
    }

    const [actionadd] = useMutation(ADD_COMMESSA, {onCompleted: onMutationOK, onError: onMutationError, refetchQueries: ['registro_commesse']})

    function onDelete(data, error) {
        let dati = getVariables();
        console.log('cancella dati', dati);
        actiondel({ variables: dati });
    }

    const [actiondel] = useMutation(DEL_COMMESSA, {onCompleted: onMutationOK, onError: onMutationError, refetchQueries: ['registro_commesse'],
        // update(cache, {data: {delete_commesse_by_pk: {cod_pers, cod_commessa}}}) {
        //     Object.entries(cache.extract())
        //         // .filter(v => v[1].cod_pers === cod_pers && v[1].cod_commessa === cod_commessa)
        //         .filter(v => v[1].__typename === "commesse_prodotti_trasporti")
        //         // .map(v => cache.evict({id: v[0]}))
        //     // cache.gc()
        // }
    })

    function onHide() {
        resetState();
        props.onHide();
    }

    let setAttrib = (attr_name, value) => setRecord(
        (prev_record) => ({...prev_record, [attr_name]: value, dirty: true}))

    return (
        <>
            <Toast ref={el => growl = el}/>
            <Dialog header="Commessa"
                visible={props.visible} 
                onHide={onHide}
                style={{width: '70vw'}}
                contentStyle={{minHeight:'26em'}}
                modal={true}>
                <div className="p-grid form-group">
                    {props.newRecord 
                    ?(<><DiaRigaDBAutocomplete
                            id= "fld100"
                            label="Cliente"
                            graphql={gql`
                                query Query($qry: String = "") {
                                recordset: anagrafe_rag_soc(where: {rag_soc: {_ilike: $qry}, pesa: {_eq: true}}, limit: 50, order_by: {rag_soc: asc}) {
                                    recid: cod_pers
                                    label: rag_soc
                                }
                            }
                            `}
                            defaultSuggestions={[{label:"nulla"}]}
                            value={record.rag_soc}
                            onChange={setAttrib.bind(this,"rag_soc")}
                            placeholder="anagrafica cliente"/>
                    <DiaRigaInputText 
                            id="fld7"
                            label="Codice Commessa"
                            value={record.cod_commessa}
                            validif={value => value.length > 1}
                            onChange={setAttrib.bind(this,"cod_commessa")}/></>)
                    :(<><DiaRiga id='rag_soc' label="Cliente">{record.rag_soc.label}</DiaRiga>
                    <DiaRiga id='cod_commessa' label="Cod Commessa">{record.cod_commessa.label}</DiaRiga></>)}
                    <DiaRigaInputText 
                            id="fld8"
                            label="Note"
                            value={record.note}
                            validif={value => value.length < 201}
                            required={false}
                            onChange={setAttrib.bind(this,"note")}/>
                    <DialogCrudButton
                            dirty={record.dirty}
                            newRecord={props.newRecord}
                            onSave={onSave}
                            onDelete={props.newRecord ? null : onDelete}
                            onReset={resetState}
                            datiOk={datiOK()}/>

                </div>
            </Dialog>
        </>
        )
}

