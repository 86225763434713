import { useState } from "react";
import { useParams } from "react-router-dom"
import { Dropdown } from 'primereact/dropdown';

const options = [
    {
        "recid": {
            "label": "MM",
            "x": 1
        },
        "label": "MM",
        "labelext": "Maggiore"
    },
    {
        "recid": {
            "label": "MB",
            "x": 1
        },
        "label": "MB",
        "labelext": "Marini Maggiore"
    },
    {
        "recid": {
            "label": "MV",
            "x": 1
        },
        "label": "MV",
        "labelext": "Marano"
    }
]

export function UnApp(props) {
    const [state, setState] = useState({value: options[0].recid})
    const params = useParams()
    function onChange(event) {
        // il select cerca value con deep test uguaglianza
        setState({value:event.value})
        // il select cerca value con deep test uguaglianza. Questo funziona:
        // setState({value: {label:event.value.label, x: event.value.label}})
    }
    console.log(state);
    return <div className="card"><h1>{params.testo}</h1>
        <Dropdown options={options} value={state.value} optionLabel="label" optionValue="recid"
        onChange={onChange} />
    </div>
}