import logo from './logovaccari.svg';
import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client';

import PrincipalsContext from './PrincipalsContext';

import LoginGButton from './LoginGButton';
import {AUTH_IDTOKEN_USER_ID} from './loginproc';

import { Outlet } from "react-router-dom";

import { MainMenubar} from './MainMenubar'


function LoginCard(props) {
    const client = useApolloClient();
    const {loggedIn, setLoggedIn, setAllowedRoles} = props;
    const navigate = useNavigate()


    useEffect(() => { 
      window.addEventListener(
        'AUTH_NOLOGIN',
        () => {client.resetStore(); setLoggedIn(false); setAllowedRoles([]); navigate('/')});
    });
    function setLoggedInWithUserId() {
      setLoggedIn(sessionStorage.getItem(AUTH_IDTOKEN_USER_ID))
    };
  
    return (
    <div className={loggedIn ? "row" : "vh-100 d-flex justify-content-center align-items-center"}>
        <div className="card p-5" hidden={loggedIn}>
            <img className="card-img-top mt-3 mb-2" alt="logo" src={logo} height="60"></img>
            <div className="card-body mt-5">
                <div className="d-flex">
                <LoginGButton onSignIn={setLoggedInWithUserId} onAuth={setAllowedRoles}/> 
                </div>
            </div>
        </div>
    </div>)
}

export function MainPageContainer() {
    const [loggedIn, setLoggedIn] = useState(null);
    const [allowedRoles, setAllowedRoles] = useState([])
    console.log(loggedIn, allowedRoles);

    return  (
        <PrincipalsContext.Provider value={{loggedIn, allowedRoles}}>
            <div className='container-fluid'>
                <LoginCard 
                    setLoggedIn={setLoggedIn} loggedIn={loggedIn}
                    setAllowedRoles={setAllowedRoles} />
                {loggedIn ? 
                <>
                    <div id="main_menubar" className='row mb-3'><MainMenubar /></div>
                    <div id="main_outlet" className='row m-1'><Outlet /></div>
                    <div id="rigafooter" className='row mt-5'></div>
                </>
                : null}
            </div>
        </PrincipalsContext.Provider>
    )
  }
  