/******* Reducer e state
 ***
 */

function RECID(label, recid = null) {
  return ({ label, recid });
}

export const recFldList = [
"conducente",
"cig",
"cup",
"vett_ns",
"ragSoc",
"targa",
"cod_vettore",
"cod_artic",
"id_dest",
"tara",
"lordo",
"unita",
"pesospec",
"temperatura",
"num_silos",
"data",
"num_bolla",
"cod_commessa"
]

export const elencoStampante = ["PDF", "MM", "MB", "MV", "VE", "BV", "BL", "M", "CB"].map((e) => ({label: e, recid: e}));

function formReducerInit() {
  return {
    ddtSimileKey: {url_anno: null, setDdt: false},
    currFocus: null,
    codBolla: {label: ''},
    codBollaList: [],
    conducente: RECID(""),
    cig: RECID("NO", "NO"),
    cup: RECID("NO", "NO"),
    vett_ns: RECID(false, false),
    ragSoc: RECID(""),
    ragSocAlt: [RECID("")],
    targa: {...RECID(""), mittente: null},
    veicoliAlt: [],
    cod_vettore: RECID("NO vettore", 9939),
    cod_vettore_no_vettore: RECID(""),
    commesseProdottiAlt: [],
    commesseTrasportiAlt: [],
    cod_artic: RECID(""),
    cod_commessa: RECID(""),
    data_inizio: RECID(""),
    id_dest: RECID(""),
    destinazione_pattern: RECID(""),
    commessa_trasporti_data_inizio: RECID(""),
    tara: RECID(""),
    lordo: RECID(""),
    unita: RECID("T.", "T."),
    elencoUnita: ["T.", "mc", "n","h", "Kg"].map((el) => ({label: el, recid: el})),
    pesospec: RECID(""),
    temperatura: RECID("", 0),
    num_silos: RECID("0", 0),
    elencoStampante,
    stampante: RECID(""),
    stampare: true,
    stampareScontrino: false,
    bilancia: [{peso: 0, data: ""}],
    currPeso:{data:"",peso:0},
    setPesoLordo: true,
    bilanciaQueryTime: 0,
    anagrafeAltVisible: false,
    veicoliAltVisible: false,
    destinazioniLibereAltVisible: false,
    destinazioniLibereAlt: [],
    formCompleto: false,
    num_bolla: RECID(""),
    data: RECID(""),
    savingDialog: false,
    savedDDT: {cod_bolla: "", data: "", num_bolla: "", treg: "19990101"}, 
    waitPrintStatus: false, // true se eseguire query su ddt_print_queue per ottenere stato stampa
    msgSave: "-",
    msgPrint: "-",
    msgPrintScontrino: "-",
  }
}

function campoRichiesto(state, fldName) {
  let {temperatura, num_silos} = state.codBolla.recid.metadati
  return (
    !['pesospec', 'data', 'num_bolla'].includes(fldName)
    && !(['temperatura', 'num_silos'].includes(fldName) && !(temperatura || num_silos))
  )
}

function chkFormCompleto(state) {
  if (state.codBolla.recid == null) 
    return {formCompleto: false}
  return {formCompleto: recFldList.every(fldName => 
      !campoRichiesto(state, fldName) || (state[fldName].recid != null && state[fldName].recid !== ""))}
}

function changedCodBolla(state, urlCodBolla) {
  state.codBollaList.sort(
    ({recid: a}, {recid: b}) => 
      a.metadati.list_order !== b.metadati.list_order 
        ? a.metadati.list_order - b.metadati.list_order 
        : a.label > b.label 
          ? 1 
          : -1) // a.label sempre != b.label per costruzione
  let codBolla = 
    state.codBollaList.find((elm) => elm.label === urlCodBolla) 
    || {label: '', recid: {metadati: {}}};
      
  let { recid: {metadati: {stampante, stampare}} } = codBolla || {recid:{metadati:{stampante:null}}}
  let stampante_recid = {label: stampante, recid: stampante}
      
  return {
    ...formReducerInit(),
    codBollaList: state.codBollaList,
    cod_vettore_no_vettore: state.cod_vettore_no_vettore,
    stampante: stampante_recid,
    stampare,
    codBolla
  }
}

function setByVeicoliAlt(value) {
  let {cig, cup, vett_ns, targa, veicoli} = value;
  return ({
    conducente: RECID(value.anagconducente.label ,value.anagconducente.recid),
    cod_vettore: RECID(value.anagvettore.label ,value.anagvettore.recid),
    targa: {label: targa, recid: targa, mittente: veicoli.mittente},
    cig: {label: cig, recid: cig},
    cup: {label: cup, recid: cup},
    vett_ns: {label: vett_ns, recid: vett_ns},
    tara: {label: veicoli.tara, recid: veicoli.tara},
    currFocus: null
  })
}

function setByCommessaProdotti(value) {
  let {classe, descrizion, cod_artic, cod_commessa, data_inizio, unita} = value;
  return (classe === "prodotto" ? {
    commesseProdottiAltSelection: value,
    cod_artic: RECID(descrizion, cod_artic),
    cod_commessa: RECID(cod_commessa, cod_commessa),
    data_inizio: RECID(data_inizio, data_inizio),
    unita: RECID(unita, unita),
    currFocus: null
  } : {})
}

function setByCommessaTrasporti(value) {
  let {destinazione, id_dest, destinazione_pattern, commessa_trasporti_data_inizio} = value;
  return {commesseTrasportiAltSelection: value,
      id_dest: RECID(destinazione, id_dest),
      destinazione_pattern: RECID(destinazione_pattern, destinazione_pattern),
      commessa_trasporti_data_inizio: RECID(commessa_trasporti_data_inizio, commessa_trasporti_data_inizio),
      currFocus: null
    }
}

function setByDestinazioniLibereAlt(value) {
  return {
    destinazione_pattern: RECID(""),
    commessa_trasporti_data_inizio: RECID(""),
    destinazioniLibereAltSelection: value,
    id_dest: value,
    currFocus: null
  }
}



// gestione lordo da bilancia:
// fino a quando l'utente non agisce sul campo lordo tramite dropdown o input
// la query aggiorna il campo lordo
// Il campo bilanciaQueryTime funge da timer per Webcam
function setBilancia(state, value) {
  let pesolordo;
  let bilancia = (value.length === 0 ? [{data: "2000-01-00", peso: 0}] : value).map(
    (elm, index) => ({
      peso: elm.peso,
      label: elm.peso ? (elm.peso / 10).toString() : "0,0", // il peso è in quintali
      data: elm.data}))
  pesolordo = bilancia[0].peso / 10
  let lordo = pesolordo ? RECID(pesolordo, pesolordo) : RECID("")
  let bilanciaQueryTime = (new Date().getTime() / 3000).toFixed()
  return state.setPesoLordo ? 
    {bilanciaQueryTime, bilancia, currPeso: bilancia[0], lordo}
    : {bilanciaQueryTime, bilancia, currPeso: bilancia[0]}
}

function setLordoDaBilancia(state, value) {
  const currValue = value || state.currPeso
  const lordo = currValue.peso / 10
  
  return (
    {
      lordo: lordo ? RECID(lordo, lordo): RECID(""),
      unita: RECID('T.', 'T.'),
      currPeso: currValue,
      setPesoLordo: false
    }
  )
}

function setAltVisibility(state) {
  let anagrafeAltVisible = state.currFocus === 'ragSoc' || state.ragSoc.recid == null
  let veicoliAltVisible = state.ragSoc.recid && (state.currFocus === 'targa' || state.targa.recid == null)
  let commesseProdottiAltVisible = (
    (state.ragSoc.recid && state.cod_vettore.recid && state.targa.recid && state.conducente.recid 
      && (state.cod_artic.recid == null))
    || (state.currFocus === "cod_artic")
  )
  let commesseTrasportiAltVisible = (
    state.trasportoCaricoNostro && (
    (state.ragSoc.recid && state.cod_vettore.recid && state.targa.recid && state.conducente.recid 
      && state.cod_commessa.recid
      && (state.id_dest.recid == null))
    || (state.currFocus === "id_dest"))
  )
  let destinazioniLibereAltVisible = (
    !state.trasportoCaricoNostro && (
      (state.ragSoc.recid && state.cod_vettore.recid && state.targa.recid && state.conducente.recid 
        && state.cod_commessa.recid
        && (state.id_dest.recid == null))
      || (state.currFocus === "id_dest"))
  
  )
  return {
    anagrafeAltVisible, veicoliAltVisible, commesseProdottiAltVisible,
    commesseTrasportiAltVisible, destinazioniLibereAltVisible}
}

function setTrasportoCaricoNostro(state) {
  return {trasportoCaricoNostro: state.targa.mittente || state.vett_ns.recid}
}

// Dovrei controllare invece l'effettivo cambio di:
// ragSoc, trasportoCaricoNostro. 
function resetCodCommessa(prevState, newState, action) {
  return (
    (prevState.ragSoc.recid !== newState.ragSoc.recid)
    || (prevState.trasportoCaricoNostro !== newState.trasportoCaricoNostro)
    || (prevState.data.recid !== newState.data.recid)
      ? {cod_commessa: RECID(""), cod_artic: RECID(""), id_dest: RECID("")}
      : {}
  )
}

function setDdtSimile(state, value)
{
  const voce = value.bolle_vocis[0]
  const commessa = voce.bolle_commesse_prodotti || RECID("")
  const commessa_trasporti = value.bolle_commesse_trasporti || {commessa_trasporti_data_inizio: "", destinazione_pattern: ""}
  
  return {
    ...state,
    ddtSimileKey: {...state.ddtSimileKey, setDdt: false},
    conducente: value.anagconducente,
    cig: RECID(value.cig,value.cig),
    cup: RECID(value.cup, value.cup),
    vett_ns: RECID(value.vett_ns, value.vett_ns),
    ragSoc: value.anagrafe,
    targa: value.veicoli,
    cod_vettore: value.anagvettore,
    cod_artic: voce.articolo,
    cod_commessa: RECID(commessa.cod_commessa,commessa.cod_commessa),
    data_inizio: RECID(commessa.data_inizio, commessa.data_inizio),
    id_dest: value.destinazioni,
    destinazione_pattern: (commessa_trasporti.destinazione_pattern ? RECID(commessa_trasporti.destinazione_pattern, commessa_trasporti.destinazione_pattern) : RECID("")),
    commessa_trasporti_data_inizio: (commessa_trasporti.commessa_trasporti_data_inizio ? RECID(commessa_trasporti.commessa_trasporti_data_inizio, commessa_trasporti.commessa_trasporti_data_inizio) : RECID("")),
    tara: RECID(voce.tara,voce.tara),
    unita: RECID(voce.unita,voce.unita),
    pesospec: RECID(voce.pesospec,voce.pesospec),
    temperatura: RECID(voce.temperatura.toString() ,voce.temperatura)
  }
}

function formReducer(state, action) {
  const {value, fldName} = action
  console.log(action.type, fldName, value);
  let new_state;
  switch (action.type) {
    case 'changedCodBolla':
      new_state = changedCodBolla(state, value)
      break
    case 'ddt_simile':
      new_state = setDdtSimile(state, value)
      break
    case 'set_fld':
      new_state = {...state, [fldName]: value}
      switch (action.fldName) {
        case 'cod_vettore_no_vettore':
          new_state.cod_vettore = new_state.cod_vettore_no_vettore
          break
        case 'targa':
          if (value.recid) {
            new_state.tara = RECID(value.tara, value.tara)
            new_state.conducente = value.conducente
          }
          break
        case 'vett_ns':
          new_state.currFocus = fldName
          break
        default:
          break
      }
      break
    case 'set_currFocus':
      new_state = fldName ? {...state, currFocus: fldName} : state
      break
    case 'set_bilancia':
      new_state = {...state, ...setBilancia(state, value)}
      break
    case 'set_lordo_da_bilancia':
      new_state = {...state, ...setLordoDaBilancia(state, value)}
      break
    case 'set_lordo':
      new_state = {...state, lordo: value, setPesoLordo: false}
      break
    case 'set_fld_by_ragSocAlt':
      new_state = {...state, ragSoc: value, cod_commessa: RECID(""), currFocus: null}
      break
    case 'set_fld_by_veicoliAlt':
      new_state = {...state, veicoliAltSelection: value, ...setByVeicoliAlt(value)}
      break
    case 'set_fld_by_commessaProdotti':
      new_state = {...state, ...setByCommessaProdotti(value)}
      break
    case 'set_fld_by_commessaTrasporti':
      new_state = {...state, ...setByCommessaTrasporti(value)}
      break
    case 'set_fld_by_destinazioneLibera':
      new_state = {...state, ...setByDestinazioniLibereAlt(value)}
      break
    case 'start_save':
      new_state = {...state, savingDialog: true, 
        msgSave: "Attendi!!!!",
        msgPrint: state.stampare ? "Attendi!!!!" : "no stampa",
        msgPrintScontrino: state.stampareScontrino ? 
          "Attendi!!!!" : "no scontrino",
      }
      break
    case 'close_save':
      // se l'utente seleziona gli stessi Autocomplete 
      // gli Alt non vengono richiamati
      let riporto = (({ 
          codBolla, codBollaList, stampante, 
          ragSocAlt, veicoliAlt, commesseProdottiAlt, commesseTrasportiAlt,
          bilancia, currPeso, ddtSimileKey, cod_vettore_no_vettore
        }) => ({ 
          codBolla, codBollaList, stampante, 
          ragSocAlt, veicoliAlt, commesseProdottiAlt, commesseTrasportiAlt,
          bilancia, currPeso, ddtSimileKey, cod_vettore_no_vettore
        }))(state)
      new_state = state.savingDialog ?
        (value === 'OK') ?
          {
            ...formReducerInit(),
            ...riporto,
            savingDialog: false}
          : { // 'RIPETI'
              ...formReducerInit(),
              ...riporto,
              ragSoc: state.ragSoc,
              targa: {...RECID(""), mittente: state.targa.mittente},
              cod_vettore: state.cod_vettore,
              vett_ns: state.vett_ns,
              cod_commessa: state.cod_commessa,
              data_inizio: state.data_inizio,
              commessa_trasporti_data_inizio: state.commessa_trasporti_data_inizio,
              destinazione_pattern: state.destinazione_pattern,
              cod_artic: state.cod_artic,
              id_dest: state.id_dest,
              savingDialog: false}
        : {...state}
      break
    default:
      throw new Error();
  }
  new_state = { ...new_state, ...setTrasportoCaricoNostro(new_state)}
  new_state = (action.type === 'ddt_simile') 
    ? new_state
    : { ...new_state, ...resetCodCommessa(state, new_state, action)}
  new_state = { ...new_state, ...chkFormCompleto(new_state)}
  new_state = { ...new_state, ...setAltVisibility(new_state)}
  return new_state
}
  
export {formReducerInit, formReducer}