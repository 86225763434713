// Componente per sistema ExeDialog. Usa select per cfonte e ccosto
import React, {useState, useRef, useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';

// Elenca i vari casi di associazione di centro di costo e fonte di costo
export const GQL_FONTECOSTO = gql`
query ElencoCfonteCcosto($up_matricola: String = "", $cnt_matricola: String = "") {
    costi2009_registro(distinct_on: [cfonte, ccosto], order_by: [{cfonte: asc}, {ccosto: asc}], where: {up_matricola: {_eq: $up_matricola}, cnt_matricola: {_eq: $cnt_matricola}}) {
        cfonte
        ccosto
    }
}
`

const GQL_CENTRICOSTO = gql`
query ElencoCentriCosto {
    ccosti: costi2009_registro(order_by: {ccosto: asc}, distinct_on: ccosto) {
        label: ccosto
    }
    cfonti: costi2009_registro(order_by: {cfonte: asc}, distinct_on: cfonte) {
        label: cfonte
    }
}
`
export function Ccosto(props) {
    return <CentriCostoFonte {...props}/>
}

Ccosto.displayName = 'Ccosto';

export function Cfonte(props) {
    return <CentriCostoFonte {...props}/>
}

Cfonte.displayName = 'Cfonte';

function CentriCostoFonte(props) {
    const inputRef = useRef(null);
    const {currURLSearchParams, onFinish} = props;
    const [state, setState] = useState({cfonte:'', ccosto:''})

    const { loading, error, data } = useQuery(GQL_CENTRICOSTO);
    const { loading: loading2, error: error2, data: data2 } = useQuery(GQL_FONTECOSTO, 
        {variables: {
            up_matricola: currURLSearchParams.get('up_matricola'),
            cnt_matricola: currURLSearchParams.get('cnt_matricola')}});

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [data, data2]);
        
    if (loading || loading2) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    if (error2) return `Error! ${error2.message}`;
    const cfonti = data.cfonti.filter(costo => !['TR', 'M'].includes(costo.cfonte))
    const ccosti = data.ccosti.filter(costo => !['M'].includes(costo.ccosto))
    const fonti_costi = data2.costi2009_registro.filter(el => !['TR', 'M'].includes(el.cfonte));
    return (
        <div className="card-body">
            <h5 className="card-title">Seleziona una coppia storica di questo contatore oppure i valori distinti che trovi sotto</h5>
            <form onSubmit={e => {e.preventDefault(); onFinish({cfonte: state.cfonte, ccosto: state.ccosto})}} >
                <div className="mb-3">
                    <label ref={inputRef} htmlFor="fonte_costo" className="form-label">Centri in uso del contatore selezionato</label>
                    <select size="8" className="form-select" id="fonte_costo" value={state.cfonte + ' ' + state.ccosto}
                            onChange={(e) => setState({...state, cfonte: e.target.value.split(" ")[0], ccosto: e.target.value.split(" ")[1] || ""})}>
                        <option value="">Seleziona coppia storica</option>
                        {fonti_costi.map(el => <option key={el.cfonte + ' ' + el.ccosto} value={el.cfonte + ' ' + el.ccosto}>da {el.cfonte} per {el.ccosto}</option>)}
                    </select>
                </div>

                <hr />
                <div className="mb-3">
                    <label htmlFor="cfonte" className="form-label">Centro di prelievo</label>
                    <select className="form-select" id="cfonte" value={state.cfonte} onChange={(e) => setState({...state, cfonte: e.target.value})}>
                        <option value="">Seleziona un centro di prelievo</option>
                        {cfonti.map(el => <option key={el.label} value={el.cfonte}>{el.label}</option>)}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="ccosto" className="form-label">Centro di costo</label>
                    <select className="form-select" id="ccosto" value={state.ccosto} onChange={(e) => setState({...state, ccosto: e.target.value})}>
                        <option value="">Seleziona un centro di costo</option>
                        {ccosti.map(el => <option key={el.label} value={el.cfonte}>{el.label}</option>)}
                    </select>
                </div>
                <button className="btn btn-primary" disabled={!Boolean(state.cfonte && state.ccosto)} >Prosegui</button>

            </form>
        </div>
    )
}