import React, { useState, useEffect } from 'react';
import {startGoogleSignin, renderGoogleButton} from './loginproc'

function parseJwt (token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
  
	return JSON.parse(jsonPayload);
}

export default function LoginGButton(props) {
  const div_id = "googleButton";
  const [renderButton, setRenderButton] = useState(false)

  const on_nologin = () => setRenderButton(true);

  const on_event_google_ready = () => {
    renderGoogleButton(div_id);
    setRenderButton(true);
  };

  const on_id_token = (e) => {
    props.onSignIn(e.detail.idtoken);
    setRenderButton(false)
  }

  const on_auth_token = (e) => {
    let parsed_jwt = parseJwt(e.detail.auth_token);
	  let jwt_roles = parsed_jwt["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];
	  jwt_roles.unshift(parsed_jwt.userid)
    props.onAuth(jwt_roles);
    props.onSignIn(parsed_jwt.userid)
    setRenderButton(false)
  }

  useEffect(() => { 
      console.log('effect start google signin');
      window.addEventListener(
        'GOOGLE_READY',
        on_event_google_ready,
        {once: true}); // ma me lo ricarica al prossimo effect
      window.addEventListener(
        'AUTH_IDTOKEN',
        on_id_token);
      window.addEventListener(
        'AUTH_AUTHTOKEN',
        on_auth_token);
      window.addEventListener(
        'AUTH_NOLOGIN',
        on_nologin);
      startGoogleSignin();
      return function cleanup() {
        console.log('remove effect google signin');
        window.removeEventListener(
          'GOOGLE_READY',
          on_event_google_ready,
          {once: true}); // ma me lo ricarica al prossimo effect
        window.removeEventListener(
          'AUTH_IDTOKEN',
          on_id_token);
        window.removeEventListener(
          'AUTH_AUTHTOKEN',
          on_auth_token);
        window.removeEventListener(
          'AUTH_NOLOGIN',
          on_nologin);
        };
    });

  return (
    <div id='logingbutton'
      style={{
          display: renderButton ? "block":"none",
          minHeight:"3em",
          margin:"auto",
        }}>
      <div id={div_id}>Qui google mette il bottone</div> 
    </div>
    )
}

