import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';
import {Toast} from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { AddAbilitazioneDialog } from './AddAbilitazione';


import { gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Query, Mutation } from '@apollo/client/react/components'

import moment from 'moment'


const queryExpression = gql`
query ($cfonte: String, $upMatricola: String) {
  erogatoriabilitati(cfonte: $cfonte, upMatricola: $upMatricola first:100) {
    codAbilitazione
    upMatricola
    ccosto
    erogatore {
      cfonte
      cntMatricola
    }
    oraStart
    oraStop
    dataInizio
    dataFine
    unitaMax
    cntMatricolaContatoreAssociato
    controllo
    idPompa
  }
}
`

const avviaErogatore = gql`
mutation ($codAbilitazione: String, $quantitaCntAssociato: Int) {
  erogaAvvia(codAbilitazione: $codAbilitazione, quantitaCntAssociato: $quantitaCntAssociato) {
    esito
  }
}
`

class DialogErogatore extends Component {
  state = {valore: ''}

  msgSave = (saveresponse) => {
    if (!saveresponse) return null;
    let {data, error, graphQLErrors} = saveresponse;
    if (data) {
      this.growl.show({
        severity: 'success', summary: 'Avvio',
        detail: 'Comando inviato', life: 3000
      })
      this.setState({valore:''})
    }
    if (error) {
      this.growl.show({
        severity: 'error', summary: 'Avvio',
        detail: 'Comando fallito ' + error.msg, life: 3000
      })
    }
    if (graphQLErrors) {
      this.growl.show({
        severity: 'error', summary: 'Avvio',
        detail: 'Comando fallito ' + saveresponse.message, life: 3000
      })
    }
  }

  onSave = (mutateaction) => {
    mutateaction().then((response) => {
      this.msgSave(response)
      this.props.onHide(this.state.valore)
    }).catch((response) => {
      this.msgSave(response)
      this.props.onHide(this.state.valore)
    })
  }

  render = () => this.props.rowData ? (
    <React.Fragment>
    <Toast ref={(el) => this.growl = el}/>
    <Dialog header="Avvio dell'erogatore"
      visible={this.props.visible} 
      onHide={this.props.onHide}
      style={{width: '50vw'}}
      modal
      footer={(
        <div>
          <Mutation 
              mutation={avviaErogatore} 
              variables={
                { codAbilitazione:this.props.rowData.codAbilitazione,
                  quantitaCntAssociato: this.state.valore}}>

            {(action, {called, loading, data, error}) => !loading ? (
              <Button 
                label="AVVIA" 
                icon="pi pi-play" 
                onClick={() => this.onSave(action)} />
              ): null}
          </Mutation>
        </div>
        )}>
      <div className="row">
        <div className="col-12">
          Cod Attivazione:{this.props.rowData.codAbilitazione}
        </div>
        <div className="col-12">
          Tipo:{this.props.rowData.cntMatricolaContatoreAssociato}
        </div>
        <div className="col-12">
          <span >
            <label>Valore del contatore associato</label>
            <InputText 
              value={this.state.valore} 
              onChange={(e) => this.setState({valore: e.target.value})}/>
          </span>
        </div>
      </div>
    </Dialog>
    </React.Fragment>
  ): null
}

class Tabella extends Component {
  state = {
    dialogEroga: false,
    addAbilitazioneDialog: false
  }

  remap = (record) => record.map((v, i) => ({
      id: i,
      codAbilitazione: v.codAbilitazione,
      upMatricola: v.upMatricola,
      ccosto: v.ccosto,
      cfonte: v.erogatore.cfonte,
      cntMatricola: v.erogatore.cntMatricola,
      oraStart: v.oraStart,
      oraStop: v.oraStop,
      dataInizio: moment(v.dataInizio).format('L'),
      dataFine: moment(v.dataFine).format('L'),
      unitaMax: v.unitaMax,
      cntMatricolaContatoreAssociato: v.cntMatricolaContatoreAssociato,
      controllo: v.controllo,
      idPompa: v.idPompa}))

  actionTemplate = (rowData, column) => {
    return (<div>
        <Button 
          onClick={() => this.setState({dialogEroga: true, selectedRowData: rowData})}
          tooltip="Avvia il rifornimento"
          icon="pi pi-chevron-circle-right"/>
    </div>);
  }
  
  paginatorRight = () => (
    <Button
      icon="pi pi-plus-circle"
      onClick={() => this.setState({addAbilitazioneDialog: true})} />)

  render = () => (
    <React.Fragment>
    <DialogErogatore 
      visible={this.state.dialogEroga}
      rowData={this.state.selectedRowData}
      onHide={() => this.setState({dialogEroga:false})} />

    <AddAbilitazioneDialog
      refetch={this.props.refetch}
      visible={this.state.addAbilitazioneDialog} 
      onHide={() => this.setState({addAbilitazioneDialog:false})} />
      
    <DataTable 
        value={this.props.data ? this.remap(this.props.data) : []} 
        paginator={true}
        paginatorRight={this.paginatorRight()} 
        rows={15} 
        rowsPerPageOptions={[10, 15, 20]}
        size="small"
    >

      <Column body={this.actionTemplate} header="Avvio" style={{textAlign:'center', width: '8em'}}/>
      <Column field="codAbilitazione" header="cod Abilitazione"/>
      <Column field="upMatricola" header="Unità produttiva (macchina)"/>
      <Column field="ccosto" header="Centro costo"/>
      <Column field="cfonte" header="Centro di prelievo"/>
      <Column field="cntMatricola" header="Contatore associato alla macchina"/>
      <Column field="oraStart" header="dalle ore"/>
      <Column field="oraStop" header="alle"/>
      <Column field="dataInizio" header="dal giorno"/>
      <Column field="dataFine" header="al"/>
      <Column field="unitaMax" header="quantita massima"/>
      <Column field="cntMatricolaContatoreAssociato" header="Matricola del contatore associato all'erogatore"/>
      <Column field="controllo" header="controllo"/>
      <Column field="idPompa" header="Id pompa o pistola"/>
    </DataTable>
    </React.Fragment>)

}

class TabellaContainer extends Component {

  state = {cfonte: 'MM', upMatricola: ''};
  render() {
    return (
      <div className="card shadow">
        <div className="card-body">
          <Query 
              query={queryExpression} 
              variables={{cfonte: this.state.cfonte + "%", upMatricola: this.state.upMatricola + '%'}}>
            {({data, error, loading, refetch}) => {
              return (
                <div>
                  <h1>Elenco Attivazioni</h1>
                  <form className="row g-3 mb-3">
                    <div className="col">
                      <label className="form-label" htmlFor="cfonte">Distributore (centro di prelievo) selezionato:</label>
                        <input type="text"
                          className='form-control'
                          placeholder='centro di prelievo'
                          id="cfonte"
                          value={this.state.cfonte}
                          onChange={(e) => this.setState({cfonte: e.target.value})}
                          />
                    </div>
                    <div className="col">
                      <label className="form-label" htmlFor="upMatricola">Unità produttiva selezionata:</label>
                        <input type="text"
                          className='form-control'
                          placeholder='tutte'
                          id="upMatricola"
                          value={this.state.upMatricola}
                          onChange={(e) => this.setState({upMatricola: e.target.value})}
                          />
                    </div>
                  </form>
                      <Tabella data={data ? data.erogatoriabilitati: []} client={this.props.client} refetch={refetch} />
                </div>)}}
          </Query>
        </div>
      </div>);
  }
}


export const ErogAvvia = withApollo(TabellaContainer)
